import React, { useState } from "react";
import { useEffect } from 'react';
import ".././../public/assest/css/invest.css";
import DownloadApp from "../../elements/DownloadApp";
import { PieChart } from "react-minimal-pie-chart";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import {Bar} from 'react-chartjs-2'
import EnquireButton from "../../elements/EnquireButton";
import { URL } from "../../utility/Constant";
import { Helmet } from "react-helmet"; 

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
)

const data = {
  labels: ['1 Year'],
  datasets: [
    {
      label: 'Alphamoney',
      backgroundColor: '#004334',
      borderColor: '#004334',
      borderWidth: 1,
      data: [25],
      barPercentage: 0.9,
    },
    {
      label: 'Stocks',
      backgroundColor: '#38B99C',
      borderColor: '#38B99C',
      borderWidth: 1,
      data: [12],
      barPercentage: 0.9,
    },
    {
      label: 'Cash (Liquid Fund)',
      backgroundColor: '#1E9275',
      borderColor: '#1E9275',
      borderWidth: 1,
      data: [7],
      barPercentage: 0.9,
    },
    {
      label: 'Fixed Deposit',
      backgroundColor: '#38B99C',
      borderColor: '#38B99C',
      borderWidth: 1,
      data: [4],
      barPercentage: 0.9,
    },
  ],
};

const options = {
  responsive: true,
  scales: {
    x: {
      // display: false,
      grid: {
        display: false,
      },
    },
    y: {
      display: false,
      grid: {
        display: false,
      },
    },
  },
  plugins: {
    legend: {
      position: 'bottom',
      align: 'center',
      labels: {
        padding: 15,
        color: '#000',
        font: {
          size: 12,
          weight: 400,
          family: 'Poppins',
        },
        usePointStyle: true,
        pointStyle: 'square',
      },
    },
    tooltip: {
      enabled: true
    },
  },
};

let checkIn = true;
// const dataMock = [
//     { title: 'Two', value: 10, color: '#BFE4E3' },
//     { title: 'One', value: 15, color: '#004334' },
//   ];
function Investor() {
	  let [checkData , setcheckData] = useState(true)
    let [loanAmount , setloanAmount] = useState(35000);
    let [BLRepaymentAmount , setBLRepaymentAmount] = useState(35115.07);
    let [BLRepaymentInterestAmount , setBLRepaymentInterestAmount] = useState(115.07);
    let [PLRepaymentInterestAmount , setPLRepaymentInterestAmount] = useState(1953.7);
    let [EMI , setEMI] = useState(3695.37);
    let [tenure , setTenure] = useState(10);
    let [interest , setInterest] = useState(12);
	
  const goTopath = (path) => {
		window.open(path, '_blank');
	}
	
 useEffect(() => {
    document.body?.classList?.add('investor_page')
    return () => {
      document.body?.classList?.remove('investor_page')
    }
  }, []);
	
  window.addEventListener('scroll', function() {
      if (window.scrollY > 50) {
          document.querySelector('.bgColorInvestor')?.classList.add('sticky-visible');
      }
    
	  else {
        document.querySelector('.bgColorInvestor')?.classList.remove('sticky-visible');
        }
		
    });
	
	
	window.addEventListener('scroll', function() {
      if (window.scrollY > 1650) {
          document.querySelector('.powerUpeffect')?.classList.add('square-transition');
      } 
	  else {
        document.querySelector('.powerUpeffect')?.classList.remove('square-transition');
        }
		
    });
	
	 const handleChange = (e) => {
    if(checkIn){
        setcheckData(false)
        checkIn = false;
        setloanAmount(30000)
        setTenure(3)
        setInterest(12)
        calculateEmi(30000, 3, 12)
    }else {
        setcheckData(true)
        checkIn = true;
        setloanAmount(35000)
        setTenure(3)
        setInterest(12)
        calculateEmiPerDay(35000, 10, 12)
    }
  }
  let slideRange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if(checkIn){
        if(name === 'loanAmount'){
            setloanAmount(parseInt(value))
            calculateEmiPerDay(value, tenure, interest)
        }
        if(name === 'tenure'){
            setTenure(parseInt(value))
            calculateEmiPerDay(loanAmount, value, interest)
        }
        if(name === 'interest'){
            setInterest(parseInt(value))
            calculateEmiPerDay(loanAmount, tenure, value)
        }
    } else {
        if(name === 'loanAmount'){
            setloanAmount(parseInt(value))
            calculateEmi(value, tenure, interest)
        }
        if(name === 'tenure'){
            setTenure(parseInt(value))
            calculateEmi(loanAmount, value, interest)
        }
        if(name === 'interest'){
            setInterest(parseInt(value))
            calculateEmi(loanAmount, tenure, value)
        }
    }
   
}
const calculateEmiPerDay = (principal,tenure, interestrate) => {
    let totlaPayableInterest = (((parseInt(interestrate) / 365) * parseInt(principal))/100) * parseInt(tenure);
    let totalAmt = totlaPayableInterest + parseInt(principal)
    setBLRepaymentAmount(totalAmt.toFixed(2))
    setBLRepaymentInterestAmount(totlaPayableInterest.toFixed(2))
    return {totalInterest : totlaPayableInterest.toFixed(2), totalAmount: totalAmt.toFixed(2) };
}
const calculateEmi = (principal,tenure, interestrate) => {
    var interest = parseInt(interestrate) / 1200;
    var emi = Math.round(parseInt(principal) * interest / (1 - (Math.pow(1 / (1 + interest), parseInt(tenure)))) * 100) / 100;
    var totalPayable = Math.round((emi * parseInt(tenure)) * 100) / 100;
    var interestPayable = Math.round((totalPayable * 1 - parseInt(principal) * 1) * 100) / 100;
    setPLRepaymentInterestAmount(interestPayable)
    setEMI(emi)
    return {emi : emi, totalPayable : totalPayable, interestPayable : interestPayable, interestRate : interest };
}
	
  return (
    <React.Fragment>
      <Helmet>
        <title>ALPHAMONEY | The Future Of Peer To Peer Lending India</title>
        <meta name="description" content="ALPHAMONEY is an RBI-regulated NBFC and the future of peer-to-peer lending. With our innovative platform, you can invest and borrow money online with ease. Join us today and experience the power of P2P lending." />
        <meta name="keywords" content="Alphamoney Invest" />
      </Helmet>
    <section className="bgColorInvestor main_banner">
      <div className="container">
        <div className="row">
          <div className="col-lg-7 col-md-7">
            <div className="main_banner_head">
              <h1 className="banner_heading color_investor">
                Earn Upto <br />
                <span className="font-bold"> 25% Per Annum </span>
              </h1>
              <p className="banner_sub_heading mt-3 mb-4">
                Invest in P2P Lending to multiply your earnings exponentially.
                <br />
                Get consistent, risk-adjusted returns from credit-verified
                borrowers.
              </p>
              <div className="row">
                <div className="col-sm-7 checkbox_with_text_div">
                  <div className="checkbox_with_text_shadow banner_text">
                    <img alt="checkboxImg" src={`${URL.BASE_URL}Investor/checkbox.svg`} />
                    <span className="checkboxTxt">100% Digital Process</span>
                  </div>
                  <div className="checkbox_with_text banner_text">
                    <img alt="investment" src={`${URL.BASE_URL}Investor/checkbox.svg`} />
                    <span className="checkboxTxt">Regular Returns</span>
                  </div>
                  <div className="checkbox_with_text banner_text">
                    <img alt="investment" src={`${URL.BASE_URL}Investor/checkbox.svg`} />
                    <span className="checkboxTxt">Ultra Low Risk </span>
                  </div>
                </div>
              </div>
              <button
                type="button"
                onClick={() => goTopath("https://app.alphamoney.in")}
                className="mt-3 btn_brown_outline"
              >
                Invest Now
              </button>
              <div className="DesktopViewHeading">
                <p className="link_text">Looking for help to get started?</p>
                <a href="/contactus" className="link-dark getInTouch">
                  <b>Get in touch </b>
                  <img alt="rightArrow" src={`${URL.BASE_URL}Borrower/rightArrow.png`} className="img-fluid" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-5 relative">
            <div className="banner_image">
              <img className="boy_01 " alt="Banner App" src={`${URL.BASE_URL}Home+Page/homebanner-chart.png`} />
              <img className="banner_mob " alt="Banner App" src={`${URL.BASE_URL}Home+Page/homebanner-mob.png`} />
            </div>
          </div>
        </div>
        <div className="row brand_logo_div">
          <div className="col-md-12">
            <div className="partners_logos">
              <img className="img-fluid rbi_logo_invbr" alt="RBI" src={`${URL.BASE_URL}Home+Page/rbi_logo.gif`} />
              <img
                className="img-fluid partners_logo EquifaxLogo"
                alt="EquifaxLogo"
                src={`${URL.BASE_URL}Home+Page/EquifaxLogo.png`}
              />
              <img
                className="img-fluid partners_logo yesBankLogo"
                alt="yesBankLogo"
                src={`${URL.BASE_URL}Home+Page/yesbank_logo.svg`}
              />
              <img
                className="img-fluid partners_logo IDBILogo mt-1"
                alt="IDBILogo"
                src={`${URL.BASE_URL}Home+Page/idbi_logo.png`}
              />
              <img
                className="img-fluid partners_logo karzaLogo mt-1"
                alt="karzaLogo"
                src={`${URL.BASE_URL}Home+Page/karza_logo.svg`}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <EnquireButton />
    {/* second section  */}
    <section className="common_space">
      <div className="container">
        <div className="row align-center">
          <div className="col-xl-4 col-lg-4 col-md-12 col-sm-6 relative SardarGirl-hiddens">
            <img className="img-fluid" alt="SardarGirl" src={`${URL.BASE_URL}Investor/sardar_with_girl.png`} />
            <div className="sec_section_back_img">
              <div className="PA">
                <img className="img-fluid" alt="investment" src={`${URL.BASE_URL}Investor/pledged_amount.svg`} />
              </div>
              <div className="MI">
                <img className="img-fluid" alt="investment" src={`${URL.BASE_URL}Investor/Monthly-Earnings.svg`} />
              </div>
            </div>
          </div>
          <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12">
            <div className="right_box">
              <h2 className="section_heading_left">
                Make The Right{" "}
                <span className="section_heading_bold_investor">
                  <span style={{ fontWeight: "700" }}> Moves</span>
                </span>
                <br />
                With Your Money{" "}
              </h2>
              <div className="row">
                <div className="col-lg-6">
                  <div className="info_card">
                    <img className="img-fluid" alt="investment" src={`${URL.BASE_URL}Investor/ruppe_inside_circle.png`} />
                    <h4>Attractive Rates</h4>
                    <p>Say goodbye to traditional asset-classes like FDs and Mutual
                      Funds. Beat the inflation and get interest rates of upto 31%
                      annum.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 ">
                  <div className="info_card">
                    <img className="img-fluid" alt="Hand" src={`${URL.BASE_URL}Investor/hand.svg`} />
                    <h4>Regular returns</h4>
                    <p>Get consistent returns based on your preferences. Take advantage
                      of the power of compounding by reinvesting your returns.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info_card">
                    <img className="img-fluid" alt="Graph_low" src={`${URL.BASE_URL}Investor/graph_low.svg`} />
                    <h4>Low Volatility</h4>
                    <p>Digital lending is a non-market linked investment option. Quit
                      relying on volatile markets for your next paycheck.{" "}
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info_card">
                    <img className="img-fluid" alt="Graph" src={`${URL.BASE_URL}Investor/graph.svg`} />
                    <h4>Risk Mapping</h4>
                    <p>Choose borrowers based on your risk appetite with options for
                      high-risk and high-returns category.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 SardarGirl-hidden full-width">
            <div className="PA">
              <img className="img-fluid" alt="investment" src={`${URL.BASE_URL}Investor/pledged_amount.svg`} />
            </div>
            <img className="img-fluid" alt="investment" src={`${URL.BASE_URL}Investor/sardar_with_girl.png`} />
            <div className="MI">
              <img className="img-fluid" alt="investment" src={`${URL.BASE_URL}Investor/Monthly-Earnings.svg`} />
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* second section  */}
  
    {/* third section  */}
    <section className="common_space padding_space">
      <div className="container">
        <div className="row">
          <h2 className="section_heading">
            The Unrivalled{" "}
            <span className="section_heading_bold_investor">
            Power-Up
            </span>{" "}
            To Your <br />
            Financial Portfolio
          </h2>
          <div className="col-sm-3 trackOuterBox">
            <div className="box1">
              <p className="num_1 investNum_1BorderColor">1</p>
              <p className="text">
                Real-time <br />
                investment tracking
              </p>
            </div>
            <div className="box2">
              <p className="num_2 investNum_2BgColor">2</p>
              <p className="text">
                Risk-based <br />
                returns
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 text-center relative">
            <div className="box1 realTimeBox1 marronBgWithBorder">
              <p className="num_1 investNum_1BorderColor">1</p>
              <p className="text">
                Real-time loan & <br />
                EMI tracking
              </p>
            </div>
            <div className="box2 digitalProcessBox2 marronBgWithBorder">
              <p className="num_2 investNum_2BgColor">3</p>
              <p className="text">
                Attractive <br />
                interest rates{" "}
              </p>
            </div>
            <img className="img-fluid" alt="investment" src={`${URL.BASE_URL}Investor/inves_desktop.svg`} />
            <div className="col-sm-12 processProtocol">
              <div className="box3 interestBox3 marronBgWithBorder">
                <p className="num_3 investNum_2BgColor">2</p>
                <p className="text">
                  100% digital <br />
                  process
                </p>
              </div>
              <div className="box4 privacyBox4 marronBgWithBorder">
                <p className="num_4 investNum_1BorderColor">4</p>
                <p className="text">
                  Robust safety & <br />
                  privacy protocols
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-3 interestBox3Outer">
            <div className="box3">
              <p className="num_3 investNum_3BorderColor">3</p>
              <p className="text">
                Flexible tenure
                <br />
                options{" "}
              </p>
            </div>
            <div className="box4">
              <p className="num_4 investNum_4BgColor">4</p>
              <p className="text">
                Robust safety & <br />
                privacy protocols
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* third section  */}
  
    {/* forth section  */}
    <section className="common_space_large">
      <div className="container third_section_inves">
        <div className="row relative align-center">
          <div className="col-lg-8 col-md-8 col-sm-12">
            <div className="promotion_banner">
              <h2 className="promotion_banner_heading">
              Returns of My <span className="promotion_banner_heading_bold_investor">Investment</span>
              </h2>
              <p className="promotion_banner_sub_heading">After carefully evaluating various investment opportunities over the course of one year, it becomes abundantly clear that our chosen investment option offers the highest returns, making it a smart choice for investors of all backgrounds.</p>
              <button
                type="button"
                className="btn_green mt-3"
              >
                Invest Now
              </button>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6">
            <div className="bar_chart">
              <Bar options={options} data={data} height={400}/>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* forth section  */}
  
    {/* fifth section  */}
    <section className="common_space">
      <div className="container">
        <h2 className="section_heading">
          Our <span className="section_heading_bold_investor">Offerings</span>
        </h2>
        <div className="row offerings_row">
        <div className="col-md-4">
            <div className="loan_card">
              <div className="loan_details">
                <h3>Low Risk</h3>
                <p>get return up to</p>
                <h4>
                  <span>12% </span>p.a.
                </h4>
              </div>
              <ul className="loan_benifits">
                <li>
                  <img src={`${URL.BASE_URL}Investor/tick.svg`} alt="icon" /> Starting from ₹5000
                </li>
                <li>
                  <img src={`${URL.BASE_URL}Investor/tick.svg`} alt="icon" /> Tenure from 3-12 Months 
                </li>
                <li>
                  <img src={`${URL.BASE_URL}Investor/tick.svg`} alt="icon" /> Easy Repayment by EMIs
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-4">
            <div className="loan_card">
              <div className="loan_details">
                <h3>Moderate Risk</h3>
                <p>get return up to</p>
                <h4>
                  <span>18% </span>p.a.
                </h4>
              </div>
              <ul className="loan_benifits">
                <li>
                  <img src={`${URL.BASE_URL}Investor/tick.svg`} alt="icon" /> Investment Starting from ₹10,000
                </li>
                <li>
                  <img src={`${URL.BASE_URL}Investor/tick.svg`} alt="icon" /> Tenure from 3-365 Days 
                </li>
                <li>
                  <img src={`${URL.BASE_URL}Investor/tick.svg`} alt="icon" /> One-time Repay on Maturity
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-4">
            <div className="loan_card highlight_card">
              <h6>No Upfront Fee</h6>
              <div className="loan_details">
                <h3>High Risk</h3>
                <p>get return up to</p>
                <h4>
                  <span>25% </span>p.a.
                </h4>
              </div>
              <ul className="loan_benifits">
                <li>
                  <img src={`${URL.BASE_URL}Investor/tick.svg`} alt="icon" /> Starting from ₹5000
                </li>
                <li>
                  <img src={`${URL.BASE_URL}Investor/tick.svg`} alt="icon" /> Tenure from 7-60 Days 
                </li>
                <li>
                  <img src={`${URL.BASE_URL}Investor/tick.svg`} alt="icon" /> One-time Repay on Maturity
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* fifth section  */}
  
    {/* sixth section  */}
    <section className="bgColorInvestor calculator_section">
      <div className="container">
        <div className="row">
          <h2 className="section_heading">
            A Glimpse Of The <br />
            <span className="section_heading_bold_investor">Rewards</span> You’ll Reap.
          </h2>
          <div className="col-lg-6">
            <div>
              <span className={`sliderText ${!checkIn && "activeText"}`}>
                <strong> Personal Loan &nbsp;</strong>
                <label className="switch">
                  <input
                    type="checkbox"
                    name="check"
                    value={checkIn}
                    checked={checkIn}
                    onChange={(e) => handleChange(e)}
                  />
                  <span className="slider round"></span>
                </label>
              </span>
              <span className={`sliderText ${checkIn && "activeText"}`}>
                <strong> &nbsp; Bullet Loan </strong>
              </span>
            </div>
            <div className="mt-3">
              <div className="mt-4">
                <span className="textSize">Loan Amount</span>
                <div className="mt-2">
                  <input
                    className="inputAmount inputAmount2"
                    type={"text"}
                    value={loanAmount}
                    readOnly
                    disabled
                  />
                  <span className="spanWithInputInvest">₹</span>
                </div>
                <div className="mt-1">
                  <input
                    type="range"
                    className="rangeInput"
                    name="loanAmount"
                    value={loanAmount}
                    min={checkIn ? 10000 : 30000}
                    max={checkIn ? 50000 : 100000}
                    step={1000}
                    onChange={(e) => slideRange(e)}
                  />
                </div>
                
              </div>
              <div className="mt-4">
                <span className="textSize">Rate of Interest (Per Annum)</span>
                <div className="mt-2">
                  <input
                    className="inputAmount mt-2"
                    type={"text"}
                    value={interest}
                    readOnly
                    disabled
                  />
                  <span className="spanWithInputInvest">%</span>
                </div>
                <div className="mt-1">
                  <input
                    type="range"
                    className="rangeInput"
                    name="interest"
                    value={interest}
                    min={12}
                    max={36}
                    step={1}
                    onChange={(e) => slideRange(e)}
                  />
                </div>
              </div>
              <div className="mt-4">
                <span className="textSize">Loan Tenure</span>
                <div className="mt-2">
                  <input
                    className="inputAmount mt-2"
                    type={"text"}
                    value={tenure}
                    readOnly
                    disabled
                  />
                  <span className="spanWithInputInvest">{checkIn ? "Days" : "Months"}</span>
                </div>
                <div className="mt-1">
                  <input
                    type="range"
                    className="rangeInput"
                    name="tenure"
                    value={tenure}
                    min={checkIn ? 10 : 3}
                    max={checkIn ? 365 : 12}
                    step={1}
                    onChange={(e) => slideRange(e)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="row mobileRow">
              {/* <div className="col-12">
                <img className="img-fluid" src={InvestGraph} alt="InvestGraph" />
              </div> */}
              <div className="col-6">
                <span id="pAmountInvest"></span>
                <span className="loan_amt"> Principle Amount </span>
              </div>
              <div className="col-6">
                <span id="iAmountInvest"></span>
                <span className="loan_amt"> Interest Amount </span>
              </div>
            </div>
            <div className="pieChart">
              <PieChart
                data={[
                  {
                    value: checkIn
                      ? parseInt(BLRepaymentInterestAmount)
                      : parseInt(PLRepaymentInterestAmount),
                    color: "#5B1212",
                  },
                  {
                    value: checkIn
                      ? parseInt(BLRepaymentAmount)
                      : parseInt(loanAmount) + parseInt(PLRepaymentInterestAmount),
                    color: "#FFD1CE",
                  },
                ]}
                labelStyle={(index) => ({
                  fontSize: "5px",
                  fontFamily: "sans-serif",
                })}
                viewBoxSize={["150", "100"]}
                lineWidth={"16"}
                radius={42}
                animate={true}
                animationDuration={500}
                animationEasing="ease-out"
                //  labelPosition={112}
              />
            </div>
            <div className="row mt-4">
              <div className="col-md-6 col-8">
                <span className="fontSize16 colorGreen">
                  <strong>
                    {" "}
                    {checkIn ? "Estimate Return" : "Estimate EMI"} Amount{" "}
                  </strong>
                </span>
              </div>
              <div className="col-md-6 col-4">
                <span className="fontSize16">
                  <strong> ₹ {checkIn ? BLRepaymentAmount : EMI} </strong>
                </span>
              </div>
              <div className="col-md-6 col-8">
                <span className="fontSize16"> Principle Amount </span>
              </div>
              <div className="col-md-6 col-4">
                <span className="fontSize16">
                  {" "}
                  <strong> ₹ {loanAmount.toFixed(2)} </strong>
                </span>
              </div>
              <div className="col-md-6 col-8">
                <span className="fontSize16">Estimate Interest Amount </span>
              </div>
              <div className="col-md-6 col-4">
                <span className="fontSize16">
                  <strong>
                    {" "}
                    ₹{" "}
                    {checkIn
                      ? (BLRepaymentAmount - loanAmount).toFixed(2)
                      : PLRepaymentInterestAmount}{" "}
                  </strong>
                </span>
              </div>
              {!checkIn && (
                <>
                  <div className="col-md-6 col-8">
                    <span className="fontSize18">
                      {" "}
                      Total Earnings <br />
                      <span className="fontSize12">
                        (Principal + Interest)
                      </span>{" "}
                    </span>
                  </div>
                  <div className="col-md-6 col-4">
                    <span className="fontSize18">
                      <strong>
                        {" "}
                        ₹ {(loanAmount + PLRepaymentInterestAmount).toFixed(2)}{" "}
                      </strong>
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* sixth section  */}
  
    {/* seventh section  */}
    <section className="common_space">
      <div className="container">
        <div className="row seventh_section">
          <h2 className="section_heading_white">
            Invest Confidently By{" "}
            <span className="section_heading_white_bold">Mitigating Risks</span>{" "}
          </h2>
          <p className="sections_sub_heading mb-4">
            Offering risk-based lending options and a plethora of measures to
            minimise <br />
            threats to your investment.
          </p>
    
          <div className="col-sm-12 col-md-12 col-lg-3 invest_mobileOuter minusSpaceInBottom">
            <img className="img-fluid inv_mobile" alt="Mobile" src={`${URL.BASE_URL}Investor/invest_mobile.png`} />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-9 relative">
            <div className="container">
              <div className="container-shift-investor">
                <div className="risk_card">
                  <h4>Strict Credit-Risk Assessment</h4>
                  <p>Our proprietary credit-risk assesment algorithm analyses the repayment capacity of each borrower through several of data-points and criteria. This is followed by a manual, hand-picked verification.</p>
                </div>
                <div className="risk_card">
                  <h4>Hyper Portfolio Diversification</h4>
                  <p>With bite-sized investment diversification, your capital is spread across multiple credit-worthy entities, which minimises the risk of default.</p>
                </div>
                <div className="risk_card">
                  <h4>Critical third-party compliances</h4>
                  <p>Our escrow accounts are operated by a SEBI registered trustee - IDBI Bank. What’s more, we are a member of verified credit bureaus - reporting and collecting each borrower's loan behaviours.</p>
                </div>
                <div className="risk_card">
                  <h4>e-NACH Registration</h4>
                  <p>All borrowers at ALPHAMONEY sign an official loan agreement and have to get registered with the National Automated Clearing House (NACH) for automatic EMI repayment.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    
    {/* seventh section  */}
  
    {/* eight section  */}
    <section className="common_space_large">
    <div className="container">
      <h2 className="section_heading">
        As Easy As A <span className="section_heading_bold_investor">Breeze</span>.
      </h2>
      <div className="row">
          <div className="eight_sec_upLine">
            <img className="img-fluid " alt="down_line" src={`${URL.BASE_URL}Investor/dash_line_down.svg`} />
          </div>
          <div className="col-sm-4 col-md-4 col-lg-4 ">
            <div className="eight_sec_upLine eight_sec_upLineDeskn">
              <img className="img-fluid " alt="down_line" src={`${URL.BASE_URL}Investor/dash_line_down.svg`} />
            </div>
            <div className="inves_eight_sec_div registerBox1">
              <div className="row">
                <div className="col">
                  <p className="inves_eight_sec_01">01</p>
                </div>
                <div className="col eight_sec_img_div">
                  <img
                    className="img-fluid eight_sec_img"
                    alt="register_pink"
                    src={`${URL.BASE_URL}Investor/register-pink.svg`}
                  />
                </div>
              </div>
              <div className="col inves_eight_sec_div_text">
                <p className="inves_eight_sec_div_head">Register</p>
                <p className="fontSize14">
                  Sign-up and complete your KYC process. Load your wallet with
                  desired amount.
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-4 col-md-4 col-lg-4">
            <div className="inves_eight_sec_div registerBox1">
              <div className="row">
                <div className="col">
                  <p className="inves_eight_sec_01">02</p>
                </div>
                <div className="col eight_sec_img_div">
                  <img
                    className="img-fluid eight_sec_img"
                    alt="invest_pink"
                    src={`${URL.BASE_URL}Investor/invest-pink.svg`}
                  />
                </div>
              </div>
              <div className="col inves_eight_sec_div_text">
                <p className="inves_eight_sec_div_head">Invest</p>
                <p className="fontSize14">
                  Explore & select a suitable investment opportunity to pledge
                  funds.
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-4 col-md-4 col-lg-4 relative">
            <div className="eight_sec_downLine eight_sec_downLineMobile">
              <img className="img-fluid " alt="up_line" src={`${URL.BASE_URL}Investor/dash_line_up.svg`} />
            </div>
            <div className="inves_eight_sec_div registerBox1">
              <div className="row">
                <div className="col">
                  <p className="inves_eight_sec_01">03</p>
                </div>
                <div className="col eight_sec_img_div">
                  <img
                    className="img-fluid eight_sec_img"
                    alt="earn_pink"
                    src={`${URL.BASE_URL}Investor/earn-pink.svg`}
                  />
                </div>
              </div>
              <div className="col inves_eight_sec_div_text">
                <p className="inves_eight_sec_div_head">
                  <strong> Earn </strong>
                </p>
                <p className="fontSize14">
                  Accept terms and start earning. Re-invest earnings to earn more!
                </p>
              </div>
            </div>
          </div>
          <div className="eight_sec_downLine">
            <img className="img-fluid " alt="up_line" src={`${URL.BASE_URL}Investor/dash_line_up.svg`} />
          </div>
      </div>
    </div>
    </section>
    {/* eight section  */}
    <DownloadApp />
  </React.Fragment>
  
  );
}

export default Investor;
