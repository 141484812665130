import React from "react";
import brIcon from "../../public/assest/img/borrowerIcon.svg";
import inIcon from "../../public/assest/img/investorIcon.svg";
function Aggrements(props) {
  const gotoPage = (path) => {
    window.location.href = path;
  };
  return (
    <div className="policy_container">
      <div className="policy_header">
        <div className="container">
          <h2 className="policy_heading">
            <b>Agreements </b>
          </h2>
        </div>
      </div>
      <div className="container">
        <div className="policy_breadcrumb">
          <h3>
            <span><a href="/">Home</a> </span>
            <span> /</span>
            <span> Agreements</span>
          </h3>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="policy_card policy_card_red" onClick={() => gotoPage("/aggrements/loanaggrement")}>
              <img src={inIcon} alt="inIcon"/>
              <div>
                <h4>Loan</h4>
                <p>Agreement</p>
              </div>
            </div>
          </div>
            <div className="col-md-4">
              <div className="policy_card policy_card_green" onClick={() => gotoPage("aggrements/borrowaggrement")}>
                <img src={brIcon}  alt="brIcon"/>
                <div>
                  <h4>Borrower</h4>
                  <p>Registration Agreement</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="policy_card policy_card_red" onClick={() => gotoPage("/aggrements/lenderaggrement")}>
                <img src={inIcon} alt="inIcon"/>
                <div>
                  <h4>Lender</h4>
                  <p>Agreement</p>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  );
}

export default Aggrements;
