import React from "react";
import { useEffect } from 'react';
import ".././public/assest/css/invest.css";
import { URL } from "../utility/Constant";
import { Helmet } from "react-helmet"; 

function AboutUs() {
  const goTopath = (path) => {
		window.open(path, '_self');
	}
	// useEffect(() => {
  //   document.head.innerHTML+=`
  //   <meta name='description' content='ALPHAMONEY is a P2P Lending NBFC reshaping banking....'/>
  //   <meta name='keywords' content='
  //         AlphamoneyAboutUs 
  //   '/>
  //   `
  // }, []);
  useEffect(() => {
    document.body.classList.add('aboutus')
	setTimeout(() => {
    const box = document.getElementById("root");
     box.style.display = "block";
   }, 500);
   
   setTimeout(() => {
    const box = document.getElementById("show-3");
     box.style.display = "block";
   }, 500);
   return () => {
    document.body.classList.remove('aboutus')
  }
  }, []);
  
  
  return (
    <React.Fragment>
      <Helmet>
        <title>About Us | AlphaMoney</title>
        <meta name="description" content="At AlphaMoney, we believe in empowering individuals by creating a transparent, secure, and efficient financial ecosystem. Founded on the principles of trust and innovation, we are dedicated to bridging the gap between lenders and borrowers, offering an alternative to traditional banking that benefits everyone." />
        <meta name="keywords" content="P2P Lending India, Invest in P2P Lending, Online Investment Platform, Fintech Lending Solutions, RBI Registered NBFC, Secure Investment Options, Lending and Borrowing Online" />
      </Helmet>
      <div className="w-100">
        {/* second section  */}
        <div className="container">
            <div className="row">
              <div className="col-xl-12 p-5">
                <h1 className="second_section_heading_about">
                  <b> About </b> Us{" "}
                </h1>
                <p className="second_section_heading_mute">
                  We’re reshaping financial Futures{" "}
                </p>
              </div>
            </div>
        </div>
        {/* second section  */}
        {/* third section  */}
        <div className="third_section_about">
          <div className="container">
            <div className="row align-center">
              <div className="col-lg-3 hide-xs center-align">
                <img className="img-fluid" alt="p2p_landing" src={`${URL.BASE_URL}About+Us/Logo+Main.svg`} />
              </div>
              <div className="col-lg-9">
                <p className="third_section_about_heading">
                  ALPHAMONEY is a P2P Lending NBFC reshaping banking and financial services for the next generation. We are propelling financial growth across India - Enabling new-age consumers to break the glass ceiling. An RBI registered (CoR 14.03566) fintech, we are pioneering in providing financial solutions for the next generation of borrowers and investors across the credit spectrum. 
                </p>
                {/* <br/>
                <p className="third_section_about_heading">Established in 2019 as an off-shoot of Alpha Corp, India’s leading real estate firm which is backed by industry leaders like Morgan Stanley and Blackstone. </p> */}
              </div>
            </div>
          </div>
        </div>
        {/* third section  */}
        {/* forth section  */}
        <section className="common_space">
          <div className="container">
            <div className="row align-center">
              <div className="col-lg-6">
                <div className="third_section_about_text">
                  <h1 className="">Building <span> Extraordinary </span> <br/> Products</h1>
                    <p className="products_desc" >
                      ALPHAMONEY is a product-first company that is revolutionising financial services. We're tackling challenges in banking, lending, investing, payments and financial growth—all while maintaining a beautiful experience. 
                    </p>
                    <p className="products_desc">
                      Our products will cater to several major segments that include healthcare, education, agriculture & real estate - to name a few. We're building technologies that bring people together in the real world which can be both daunting and inspiring!
                    </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div id="main_section_img" className='about_image'>
                    <img className="img-fluid" alt="p2p_landing" src={`${URL.BASE_URL}About+Us/about-image-app.png`} />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* forth section  */}
        {/* fifth section  */}
        <section className="common_space">
          <div className='fifth_section_about'>
            <div className='container'>
              <div className="about_us_values">
                <h2> The <span> Values </span> that Define Us </h2>
              </div>
              <div className='row values_row'>
                <div className='col-sm-6'>
                  <div className="values_content">
                    <h4>PROFESSIONALISM</h4>
                    <p> At every step of the journey, we make sure that we conduct ourselves in a professional manner. Be it in the dealings with our partners or our relationship with our customers one can always expect the highest level of professionalism from us. </p>
                  </div>
                </div>
                <div className='col-sm-6'>
                  <div className="values_content">
                    <h4>SUSTAINABILITY</h4>
                    <p> It is our endeavour to transform the communities we serve into better habitats. We strive for balance between people, environment and business; keeping sustainability at the heart of all our projects</p>
                  </div>
                </div>
                <div className='col-sm-6'>
                  <div className="values_content">
                    <h4>INTEGRITY</h4>
                    <p> As an organisation we have always stood for honest and courteous dealings. We regard integrity as a core value and demand it of ourselves and of those we work with, sincerely and consistently. </p>
                  </div>
                </div>
                <div className='col-sm-6'>
                  <div className="values_content">
                    <h4>RESPECT FOR PEOPLE</h4>
                    <p> Respect is the bedrock of all our relationships. We value each of our customers, shareholders, partners and employees for the role they play in helping us achieve our vision. And we work relentlessly to strengthen this bond of mutual respect.</p>
                  </div>
                </div>
                <div className='col-sm-6'>
                  <div className="values_content">
                    <h4>MARKET DRIVEN & INNOVATIVE</h4>
                    <p> We monitor consumer and market trends to identify key challenges and strive to meet them continuously by virtue of our research tools, passion, innovation, creativity and entrepreneurship. </p>
                  </div>
                </div>
                <div className='col-sm-6'>
                  <div className="values_content">
                    <h4>TRANSPARENCY</h4>
                    <p> We thrive on a culture of ethics and uncompromising morals. At no point are we unclear or vague in the way we work, making it our priority to keep all concerned up to date with our plans and vision for the future.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* fifth section  */}
        {/* sixth section  */}
        <section className="common_space">
          <div className='container'>
            <div className='row'>
              <div className='col-md-12'>
                <div className="join_heading">
                  <h2>Join Us!</h2>
                  <p>If you are looking to work in a stimulating workplace on some of the biggest challenges in the banking and finance industry, we might have the perfect opportunity for you!</p>
                  <button type="button" onClick={() => goTopath('https://www.alphamoney.in/career')} className="btn_brown_outline">View Opportunities</button>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* sixth section  */}
      </div>
    </React.Fragment>
  );
}

export default AboutUs;
