import React from "react";
import { URL } from "../../utility/Constant";

function CreditAssesmentPolicy(props) {
  return (
    <div className="policy_container">
      <div className="policy_header">
        <div className="container">
          <h2 className="policy_heading">
            <b>Credit </b> Assessment Policy
          </h2>
        </div>
      </div>
      <div className="container">
        <div className="policy_breadcrumb">
          <h3>
            <span><a href="/">Home</a> </span>
            <span> /</span>
            <span> Credit Assessment Policy</span>
          </h3>
        </div>
        <div className="policy_body">
          <p>The purpose of credit assessment is to ascertain repayment capacity and credit worthiness of the prospective borrowers. The prospective borrowers are assessed on wide range of parameters including existing monthly obligations, credit bureau scores, educational qualifications, house ownership, work experience etc.</p>
          <p>Our credit assessment process involves mandatory KYC process, credit bureau check, repayment capacity check and proprietary algorithm AlphaScore rating check. </p>
        <div className="policy_points">
          <h5>1. KYC Process</h5>
          <p>
          Identity, address and Income verifications are done for each borrower on the basis of associated list of documents for the same. PAN and Aadhar digital verifications are done for each prospective customer using government portals. CrediTech Solutions Private Limited is now part of C-KYC initiative of GOI and the company credit assessment team can verify the authenticity of borrower information using the same. The company credit assessment team analyses bank statements of prospective borrowers to doubly verify income figures.
          </p>
        </div>
        <div className="policy_points">
          <h5>2. Credit Bureau Check</h5>
          <p>
          CrediTech Solution Private Limited credit assessment team has a compulsory mandate to check credit bureau score for each borrower before qualifying the borrower in any of its loan schemes. The company has agreements with most of the licensed credit bureaus to ensure auto-fetching of the credit data for each of borrowers on its platform. The company business team determines the minimum credit bureau scores required to qualify for individual loan schemes.
          </p>
        </div>
        <div className="policy_points">
          <h5>3. Repayment Capacity</h5>
          <p>
          Our credit assessment policy aims to ensure responsible credit disbursals to ensure that the borrowers are not overburdened and have capacity to payback. The credit assessment team has a compulsory mandate to check that the borrowers have a disposable income to repay the loan. In cases where the borrower is individual it must be ensured that the individual has 40% of their income left for sustenance after paying existing loan obligations and projected loan obligations. The company credit assessment team verifies the existing loan obligations using bank statement analysis.
          </p>
        </div>
        <div className="policy_points">
          <h5>4. AlphaScore Ratings</h5>
          <p>AlphaScore is a proprietary credit assessment algorithm built by CrediTech which holistically rates the customer on basis of various parameters including credit bureaus scores, educational qualifications, work experience, employment type etc.
          </p>
          <p>Based on the score achieved against each parameter for an individual borrower we can arrive at a cumulative AlphaScore for each individual borrower achieved against Total possible AlphaScore. The AlphaScore ratings are assigned to each borrower based on the percentage AlphaScore achieved.
          </p>
          <p>
            If rating indicates positive, then loan disbursement process is
            initiated in consultation with the borrower. If rating indicates
            review, then the analysis report is sent to the head of loan officer/
            credit officer for review and approval. If rating indicates negative,
            loan application of applicant is rejected.
          </p>
        <table className="policy_table">
            <thead>
                <tr>
                    <th>Percentage</th>
                    <th>Alpha Score</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>85% & above</td>
                    <td>A1</td>
                </tr>
                <tr>
                    <td>Above 80% and up to 85%</td>
                    <td>A2</td>
                </tr>
                <tr>
                    <td>Above 75% and up to 80%</td>
                    <td>A3</td>
                </tr>
                <tr>
                    <td>Above 70% and up to 75%</td>
                    <td>A4</td>
                </tr>
                <tr>
                    <td>Above 65% and up to 70%</td>
                    <td>A5</td>
                </tr>
                <tr>
                    <td>Above 60% and up to 65%</td>
                    <td>A6</td>
                </tr>
                <tr>
                    <td>Above 55% and up to 60%</td>
                    <td>A7</td>
                </tr>
                <tr>
                    <td>Above 50% and up to 55%</td>
                    <td>A8</td>
                </tr>
                <tr>
                    <td>Above 40% and up to 50%</td>
                    <td>A9</td>
                </tr>
                <tr>
                    <td>Above 30% and up to 40%</td>
                    <td>A10</td>
                </tr>
                <tr>
                    <td>Below 30%</td>
                    <td>Not eligible</td>
                </tr>
            </tbody>
        </table>
        </div>
        <div className="policy_points">
          <h5>Non-Discriminatory and Non-Judgemental </h5>
          <p>
          CrediTech Solution Private Limited does not employ any manual or algorithm based discriminatory rule(s) against any individual or group in its credit assessment process. CrediTech Solution Private Limited reserves the right to have a final say in its assessments done for each individual or entity based on its credit assessment policy and other internal business policies. The company does not claim to decide the upper credit limit deserved by any individual or entity, but its credit assessment team only determines the maximum amount that it can allow to be funded by its lenders for a borrower. 
          </p>
        </div>
        {/* <div className="policy_image">
          <img src={`${URL.BASE_URL}Career/Alphascore_computation.png`} alt="p2p_landing" />
        </div> */}
       </div>
      </div>
    </div>
  );
}

export default CreditAssesmentPolicy;
