import React from "react";
import Blogorupee from "../../public/assest/img/onlyrupees.png";
import Handrupees from "../.././public/assest/img/handrupees.png";
import Moneyicon from "../../public/assest/img/moneyicon.png";
import Doller from '../../public/assest/img/blogdooler.png'
import Paytm from '../../public/assest/img/blogpaytm.png'
import Sketch from '../../public/assest/img/blogsketch.png'
import Blogrupees from '../../public/assest/img/blogrupees.png'
function Blog(props) {
  return (
    <div className="lll">
      <div class="row blogonlyruppes">
      </div>
      <p className="blogmainheading1">Making India</p>
      <p className="blogmainheading2">FINANCIALLY SECURE</p>
      <br />
      <p className="latestheading">Latest Piece</p>
      <div class="container">
        <div class="row secondcolgap">
          <div class="col-sm-5">
            <img src={Handrupees} class=" handyrupess" alt="p2p_landing" />
          </div>
          <div class="col-sm-6 rightmarginblog">
            <img src={Moneyicon} class="moneyiconcls" alt="p2p_landing" />
            <br />
            <p className="moneyclasspara">
              Why Simply Saving Your
              <br />
              Money Is Not Enough
            </p>
            <p className="dateblog">
              August 3, 2022 <span>&nbsp;</span>| <span>&nbsp;</span>
              <span className="dateblog">By Sapex Rohila</span>
            </p>
            <p className="dateblog2">
              According to the World Bank, 23.99% are salaried
              <br/> employees in
              India and we all are aware of the
              <br/> saving nature of Indians.
              According to the World 
              <br/>Bank, 23.99% are salaried employees in
              India and
              <br/> we all are aware of the saving nature of Indians.
         <br/>     According to the World Bank.
            </p>
          </div>
        </div>
<br/>
<br/>
<br/>
        <div class="row secondcolgap">
          <div class="col-sm">
            <img src={Handrupees} class=" handyrupessrow3" alt="p2p_landing" />
            <div class="rightmarginblog">
            <img src={Moneyicon} class="moneyiconcls1" alt="p2p_landing" />
            <br />
            <p className="moneyclasspara1">
              Why Simply Saving Your
              <br/>
             &nbsp; Money Is Not Enough
              </p>
           
            <p className="dateblog1">
            July  29, 2022 <span>|</span>&nbsp;
              <span className="dateblog2">By Sapex Rohila</span>
            </p>
            
          </div>
          </div>
          
          <div class="col-sm">
            <img src={Doller} class="handyrupessrow3" alt="p2p_landing" />
              <div class="rightmarginblog">
            <img src={Moneyicon} class="moneyiconcls1" alt="p2p_landing" />
            <br />
            <p className="moneyclasspara1">
              Why Simply Saving Your
              <br/>
             &nbsp; Money Is Not Enough
              </p>
           
            <p className="dateblog1">
            July  29, 2022 <span>|</span>&nbsp;
              <span className="dateblog2">By Sapex Rohila</span>
            </p>
            
          </div>
          </div>
          <div class="col-sm">
            <img src={Paytm} class="handyrupessrow3" alt="p2p_landing" />
              <div class="rightmarginblog">
            <img src={Moneyicon} class="moneyiconcls1" alt="p2p_landing" />
            <br />
            <p className="moneyclasspara1">
              Why Simply Saving Your
              <br/>
             &nbsp; Money Is Not Enough
              </p>
           
            <p className="dateblog1">
            July  29, 2022 <span>|</span>&nbsp;
              <span className="dateblog2">By Sapex Rohila</span>
            </p>
            
          </div>
          </div>
        </div>
<br/>
        <div class="row secondcolgap">
          <div class="col-sm">
            <img src={Sketch} class="handyrupessrow3" alt="p2p_landing" />
              <div class="rightmarginblog">
            <img src={Moneyicon} class="moneyiconcls1" alt="p2p_landing" />
            <br />
            <p className="moneyclasspara1">
              Why Simply Saving Your
              <br/>
             &nbsp; Money Is Not Enough
              </p>
           
            <p className="dateblog1">
            July  29, 2022 <span>|</span>&nbsp;
              <span className="dateblog2">By Sapex Rohila</span>
            </p>
            
          </div>
          </div>
          <div class="col-sm">
            <img src={Blogrupees} class="handyrupessrow3" alt="p2p_landing" />
              <div class="rightmarginblog">
            <img src={Moneyicon} class="moneyiconcls1" alt="p2p_landing" />
            <br />
            <p className="moneyclasspara1">
              Why Simply Saving Your
              <br/>
             &nbsp; Money Is Not Enough
              </p>
           
            <p className="dateblog1">
            July  29, 2022 <span>|</span>&nbsp;
              <span className="dateblog2">By Sapex Rohila</span>
            </p>
            
          </div>
          </div>
          <div class="col-sm">
            <img src={Paytm} class="handyrupessrow3" alt="p2p_landing" />
              <div class="rightmarginblog">
            <img src={Moneyicon} class="moneyiconcls1" alt="p2p_landing" />
            <br />
            <p className="moneyclasspara1">
              Why Simply Saving Your
              <br/>
             &nbsp; Money Is Not Enough
              </p>
           
            <p className="dateblog1">
            July  29, 2022 <span>|</span>&nbsp;
              <span className="dateblog2">By Sapex Rohila</span>
            </p>
            
          </div>
          </div>
        </div>
<br/>
        <div class="row secondcolgap">
          <div class="col-sm">
            <img src={Handrupees} class=" handyrupessrow3" alt="p2p_landing" />
              <div class="rightmarginblog">
            <img src={Moneyicon} class="moneyiconcls1" alt="p2p_landing" />
            <br />
            <p className="moneyclasspara1">
              Why Simply Saving Your
              <br/>
             &nbsp; Money Is Not Enough
              </p>
           
            <p className="dateblog1">
            July  29, 2022 <span>|</span>&nbsp;
              <span className="dateblog2">By Sapex Rohila</span>
            </p>
            
          </div>
          </div>
          <div class="col-sm">
            <img src={Doller} class="handyrupessrow3" alt="p2p_landing" />
              <div class="rightmarginblog">
            <img src={Moneyicon} class="moneyiconcls1" alt="p2p_landing" />
            <br />
            <p className="moneyclasspara1">
              Why Simply Saving Your
              <br/>
             &nbsp; Money Is Not Enough
              </p>
           
            <p className="dateblog1">
            July  29, 2022 <span>|</span>&nbsp;
              <span className="dateblog2">By Sapex Rohila</span>
            </p>
            
          </div>
          </div>
          <div class="col-sm">
            <img src={Paytm} class="handyrupessrow3" alt="p2p_landing" />
              <div class="rightmarginblog">
            <img src={Moneyicon} class="moneyiconcls1" alt="p2p_landing" />
            <br />
            <p className="moneyclasspara1">
              Why Simply Saving Your
              <br/>
             &nbsp; Money Is Not Enough
              </p>
           
            <p className="dateblog1">
            July  29, 2022 <span>|</span>&nbsp;
              <span className="dateblog2">By Sapex Rohila</span>
            </p>
            
          </div>
          </div>
        </div>
<br/>
        <div class="row secondcolgap">
          <div class="col-sm">
            <img src={Sketch} class="handyrupessrow3" alt="p2p_landing" />
              <div class="rightmarginblog">
            <img src={Moneyicon} class="moneyiconcls1" alt="p2p_landing" />
            <br />
            <p className="moneyclasspara1">
              Why Simply Saving Your
              <br/>
             &nbsp; Money Is Not Enough
              </p>
           
            <p className="dateblog1">
            July  29, 2022 <span>|</span>&nbsp;
              <span className="dateblog2">By Sapex Rohila</span>
            </p>
            
          </div>
          </div>
          <div class="col-sm">
            <img src={Blogrupees} class="handyrupessrow3" alt="p2p_landing" />
              <div class="rightmarginblog">
            <img src={Moneyicon} class="moneyiconcls1" alt="p2p_landing" />
            <br />
            <p className="moneyclasspara1">
              Why Simply Saving Your
              <br/>
             &nbsp; Money Is Not Enough
              </p>
           
            <p className="dateblog1">
            July  29, 2022 <span>|</span>&nbsp;
              <span className="dateblog2">By Sapex Rohila</span>
            </p>
            
          </div>
          </div>
          <div class="col-sm">
            <img src={Paytm} class="handyrupessrow3" alt="p2p_landing" />
              <div class="rightmarginblog">
            <img src={Moneyicon} class="moneyiconcls1" alt="p2p_landing" />
            <br />
            <p className="moneyclasspara1">
              Why Simply Saving Your
              <br/>
             &nbsp; Money Is Not Enough
              </p>
           
            <p className="dateblog1">
            July  29, 2022 <span>|</span>&nbsp;
              <span className="dateblog2">By Sapex Rohila</span>
            </p>
            
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Blog;
