import React from "react";

function BorrowerRegisterationAggrement(props) {
  return (
    <div className="policy_container">
      <div className="policy_header">
        <div className="container">
          <h2 className="policy_heading">
            <b>Borrower </b> Registration Agreement
          </h2>
        </div>
      </div>
      <div className="container">
        <div className="policy_breadcrumb">
          <h3>
            <span>
              <a href="/">Home</a>{" "}
            </span>
            <span> /</span>
            <span>
              <a href="/sample-aggrements"> Agreement </a>
            </span>
            <span> /</span>
            <span> Borrower Registration Agreement</span>
          </h3>
        </div>
        <div className="policy_body">
          <p>
            This Borrower REGISTRATION AGREEMENT (hereinafter referred to as
            this “Agreement” and includes any exhibits attached hereto, which
            may be amended, supplemented or otherwise modified from time to
            time), dated this [day_date] day of [month_date],[year_date], and
            entered into;
            <br />
            BY AND BETWEEN:
            <br />
            CREDITECH SOLUTIONS PRIVATE LIMITED, a company incorporated under
            the Companies Act, 2013, having its registered office at G-14,
            Ground Floor, Between G C Block Anand Niketan, Opposite West End,
            New Delhi-110021, India and having email id- [support@Creditech.in]
            (hereinafter called “Creditech”), which expression shall, unless
            repugnant to the context or meaning thereof, be deemed to include
            its successors and permitted assigns) of the FIRST PART;
            <br />
            AND
            <br />
            Mr/Ms/Mrs: [Borrower_name],
            <br />
            Resident of: [Borrower_currentaddress]
            <br />
            Phone Number: +91 [Borrower_mobile]
            <br />
            E-mail Id: [Borrower_email]
            <br />
            (hereinafter called “Borrower" which expression shall, unless
            repugnant to the context or meaning thereof, be deemed to mean and
            include his / her / its / their respective heirs, administrators,
            executors, legal representatives) of the SECOND PART.
            <br />
            Creditech and the Borrower are hereinafter collectively referred to
            as the “Parties” and individually as the “Party”
            <br />
            WHEREAS
            <br />
            Creditech is an NBFC- peer to peer (“P2P”) Platform which acts as an
            intermediary providing the services of loan facilitation (including
            but not limited to profile verification, risk assessment, physical
            verification, legal documentation, loan monitoring and collections)
            via online medium or otherwise, and has created a marketplace where
            Borrowerers'requirements are listed and investors fund those loans
            and has been duly recognized by Reserve Bank of India (RBI) for
            undertaking the said business.
            <br />
            A Borrower who creates an account with Creditech at
            [www.Creditech.in] can find a suitable Borrowerer through the
            services of Creditech on such terms and conditions as may be
            provided in this Agreement. The Borrower who intends to offer loan
            through Creditech needs to create an account with Creditech by
            giving basic information including name, screen name, contact nos.
            date of birth, nationality, email, place of residence, chosen
            password, gender, marital status, employment and such other
            information sought for.
            <br />
            Further, the Borrower needs to provide additional personal details,
            viz, Father's name, Gross income whether employed or self-employed,
            PAN and such other information sought for.
            <br />
            On successful activation of online registration, the Borrower agrees
            to have executed this Agreement for availing Creditech's services.
            <br />
            NOW THEREFORE, in consideration of the mutual promises, covenants
            and conditions hereinafter set forth, the receipt and sufficiency of
            which is hereby acknowledged, the parties hereto agree as follows:
          </p>
          <div className="policy_points">
            <h5>DEFINITION AND INTERPRETATION:</h5>
          </div>
          <p>
            In this Agreement, unless the context otherwise requires:
            <br />
            “Act” means the Reserve Bank of India Act, 1934.
            <br />
            Borrowerer” means a Borrowerer whose verification is completed by
            Creditech.
            <br />
            “Borrower” means a Borrower whose verification is completed by
            Creditech.
            <br />
            “Listing(s)” is a loan request by a Borrowerer on the Creditech P2P
            Platform.
            <br />
            “Non-bank financial company - Peer to Peer Lending Platform”
            (“NBFC-P2P”) means a non-banking institution which carries on the
            business of a Peer to Peer Platform.
            <br />
            “Privacy Policy” refers to the privacy policy published on the P2P
            Platform/ website.
            <br />
            “RBI Directions” means the Non-Banking Financial Company – Peer to
            Peer Lending Platform (Reserve Bank) Directions, 2017.
            <br />
            “Risk Grade” means the risk category assigned to the Eligible
            Borrowerer by Creditech’s in-house underwriting team.
            <br />
            “Terms of Use” means the terms and conditions published on the P2P
            Platform/ website. References to Clauses, Recitals are to clauses
            and recitals, to this Agreement.
            <br />
            Any reference to a statutory provision shall include such provision
            as is from time to time modified or re-enacted or consolidated so
            far as such modification or re-enactment or consolidation applies or
            is capable of applying to any transactions entered into hereunder.
            The plural includes the singular and references to any gender
            include references to all other genders.
            <br />
            Headings in this Agreement are for convenience of reference only and
            shall not be used to construe or interpret this Agreement.
            <br />
            Lists of examples shall be non-exhaustive and words such as
            "including" and "in particular" shall not be construed as limiting a
            wider class of things.
          </p>
          <div className="policy_points">
            <h5>COMMENCEMENT:</h5>
            <p>
              This Agreement shall come into effect from the date of execution
              of this Agreement.
            </p>
          </div>
          <div className="policy_points">
            <h5>SCOPE OF SERVICE:</h5>
            <p>
              The Parties agree that the Borrower shall register with Creditech
              in accordance with the policy issued by Creditech as amended from
              time to time in accordance with the provisions of law and
              guidelines issued under the Act.
              <br />
              The Parties agree that the Borrower has associated with Creditech
              to avail its services for the purpose of earning returns by
              investing in high return loans availed by credit-worthy
              Borrowerers through peer-to-peer lending.
              <br />
              The Parties agree that it shall be upon the sole discretion of
              Creditech, whether or not, to register a Borrower on its P2P
              Platform in accordance with its policy and registration /
              verification process, and Creditech's decision in this respect
              shall be final and binding and the Borrower shall not raise any
              contention / dispute in respect thereof.
              <br />
              All investments made under this Agreement shall be made by the
              Borrower by transferring the committed funds into the
              Creditech-Borrowers funding escrow account in accordance with the
              fund transfer mechanism contained in Clause 9 of the RBI
              Directions.
            </p>
          </div>
          <div className="policy_points">
            <h5>REGISTRATION:</h5>
            <p>
              Borrower agrees to register with Creditech for providing loans to
              eligible listed Borrowerers. Post online registration, Borrowers
              shall be allowed to create listings (“Listings”) on Creditech P2P
              Platform and can also view the other listed Borrower/ Borrowerer
              listings. Borrower's listing will be displayed and can make offers
              or accept requests only on successful verification. The
              verification process has been more particularly described in
              Clause 5 of this Agreement. Those Borrowers whose online
              registration is completed should submit the documents sought for
              verification by Creditech during the visit of Creditech personnel
              to the Borrower or upload online as the case may be and the same
              shall be completed within 3-5 working days from the date of
              registration on the P2P Platform.
              <br />
              Borrower hereby agrees to receive SMS transaction alerts posted by
              Creditech on any new registrations, loan closures, repayments,
              offers on loans, request from Borrowerers etc. Registered Borrower
              is availing the services on its own will and there will be no
              financial obligation of Creditech in case of legal disputes. The
              Borrowers are obliged to notify Creditech, of any change in mobile
              phone number or any other contact information provided on the P2P
              Platform pursuant to this Agreement or such other agreements
              entered for the purpose of lending and Borrowering. The registered
              members are allowed to opt out from receiving SMS transaction
              alerts by notifying Creditech in writing.
              <br />
              Borrower hereby allows Creditech to access, collect and display
              any public information available in the domain of Facebook
              /LinkedIn or any such social networking sites, on their P2P
              Platform. The registered Borrowers can however restrict either
              access, collection or display of information by notifying the same
              to Creditech in writing.
            </p>
          </div>
          <div className="policy_points">
            <h5>VERIFICATION AND AUTHORIZATION TO OBTAIN CREDIT REPORT</h5>
            <p>
              On successful completion of execution of this Agreement, the
              Borrowers are subject to verification. Verification process
              includes physical verification to check the correctness of data
              provided for online registration by the Borrower with proofs.
              Borrowers should provide the following list of documents at the
              time of physical verification by the Creditech team.
              <br />
              <b>Identify Proof:</b> Aadhaar card, PAN card, passport, driving
              license, ration card or such other document which are issued by
              government agency.
              <br />
              <b>Address Proofs:</b> Aadhaar card, Passport, driving license,
              ration card or such other documents which are issued by any
              government agency.
              <br />
              <b>Additional Proofs:</b>
              Employment contract, last 3 (three) months’ salary slips, details
              of existing loans whether secured or unsecured, six months’ bank
              statements and such other information / documents as required by
              Creditech. The exhaustive list can be seen on the website and also
              will be communicated through email.
              <br />
              Borrowerer hereby authorises Creditech to obtain credit report
              from Credit Information Bureau (India) Limited or such other
              organisation which provides such facilities not only for the first
              time on registration but at any time during his/her association
              with Creditech. The credit report shall be used for any purpose
              that is authorized by applicable law in connection with extension
              of credit transaction involving Borrowerer, including but not
              limited to :
              <br />
              To know the financial strength;
              <br />
              To understand the credit worthiness;
              <br />
              To make an informed lending decisions in an objective manner;
              <br />
              To evaluate the loan amount eligibility;
              <br />
              To enable quick loan approvals;
              <br />
              To regularly monitor the credit history; and
              <br />
              To assign ratings.
              <br />
              Creditech has the right to reject any Borrowerer application if
              any information provided by Borrowerer is inaccurate or incorrect.
              <br />
              Information on the above credit report will be displayed on
              Creditech’s P2P Platform along with Listing which will be
              accessible by the Borrower.
              <br />
              In case of any changes in the personal information of the
              Borrowerer provided after posting a listing, Borrowerer must
              either
              <br />
              Promptly notify Creditech of the change, or withdraw the Listing.
              <br />
              In the event of not notifying, Creditech at its sole discretion
              may take necessary action including removing the Listing.
              <br />
              The Borrowerer hereby agrees that documents sought by Creditech at
              the time of registration or anytime thereafter become part of the
              official record of Creditech and will not be returned. However on
              closure of the user account, Creditech will ensure that the same
              is removed or destroyed.
            </p>
          </div>
          <div className="policy_points">
            <h5>LISTINGS</h5>
            <p>
              Upon successful verification of the Borrowerer and once the loan
              application has been approved, subject to the agreement of the
              Borrowerer of the terms contained herein, the Listing shall be
              displayed on the P2P Platform and accordingly, he may receive
              offers or make requests for the funding of his loan.
              <br />
              The Listing shall comprise of the following credentials to be
              filled by the Borrowerer:
              <br />
              Purpose of Borrowering;
              <br />
              Amount required;
              <br />
              Tenure of loan;
              <br />
              Affordable Monthly EMI; and
              <br />
              Any other information such as but not limited to personal details,
              education and employment details, financial details, etc.
              <br />
              The above information shall be made public to all visitors on the
              P2P Platform. Once Creditech completes verification of the details
              and documents, the Listing shall be active. Then interested
              Borrowers may commit to fund all or a portion of the loan with
              specific requirements covering interest, term of repayment etc.
              Alternatively, the Borrowerer may make a request to the Borrowers
              to avail/request a loan. The Listing will be frozen once the
              Listing receives commitments equal to or exceeding the minimum
              amount required for the loan, unless the same is withdrawn prior
              to expiration of the Listing Period or additional Listing Period
              as the case may be. The Borrowerer shall not accept or reject
              individual offers for partial loan amounts. The Borrowerer may
              accept or reject offers only for the entire loan amount.
              <br />
              The loan application or the Listing, may be rejected on account of
              several factors like the Borrowerer’s ability to pay back,
              financial track records, CIBIL score, employment status, the ratio
              of your requested loan amount to the Borrowerer’s net income and
              expenses. Further, the loan application or Listing may be rejected
              in case of any previous failure to meet financial commitments,
              inconsistencies between the information provided and that
              recovered from background checks and credit history issues
              including prior defaults or insolvencies. Based on the aforesaid
              parameters, it shall be at the sole discretion of Creditech
              Creditech, whether or not to approve a loan application or
              Listing, and the Creditech’s decision in this respect shall be
              final and binding and the Borrowerer shall not raise any
              contention / dispute in respect thereof. Listing shall be open for
              a period of [listing_period] days (“Listing Period”). However, the
              Borrowerer can opt to close or withdraw the loan transaction
              before the expiry of the Listing Period. When sufficient
              commitments are not received for a Listing during the period
              mentioned above, Listing may be extended for additional
              [add_listing_period] days only for one time as per the conditions
              mentioned in this Agreement (“Additional Listing Period”). Listing
              once posted (like loan amount, purpose, why lend to me, other
              information etc.) cannot be modified; only certain sections such
              as photos/ videos may be modified. However, the same may be
              withdrawn subject to meeting the conditions as set forth in Clause
              6.8 and new Listing may be posted subject to Clause 6.10.
              <br />
              Any person who visits the P2P Platform shall be able to view any
              Listing which shows the loan amount, interest rate willing to pay,
              loan tenure, purpose of Borrowering, and other details such as
              city of residence, education qualification, marital status, assets
              owned etc., and other information provided to Creditech. However,
              credit reports from third party agencies will only be viewable by
              the Borrowers.
              <br />
              The Borrowerer may withdraw his Listing after
              [applicationcancel_period] days prior to the expiration of the
              Listing Period or Additional Listing Period, as the case may be or
              prior to the disbursement of loan. After the expiration of Listing
              Period or Additional Listing Period, as the case may be, the
              Borrowerer shall not have any right to withdraw his Listing, and
              if a loan is made to the Borrowerer, he shall not have any right
              to rescind the loan. In the event that the Borrowerer does not
              accept the loan, Creditech may block his application for all
              future loans through the P2P Platform.
              <br />
              No Borrowerer shall make additional Listing during the subsistence
              of an already existing Listing.
              <br />
              If a Borrowerer withdraws his Listing during the Listing Period or
              Additional Listing Period as the case may be, he may post a new
              Listing:
              <br />
              Within 90 (ninety) continuous days containing the updated
              information: In such case, the Borrowerer need not go through the
              verification process. However, any change in address, employment,
              contact details etc. will require the Borrowerer to go through the
              verification process again.
              <br />
              After 90 (ninety) continuous days containing the updated
              information: In such case, the Borrowerer shall be subjected to
              the verification process again. Creditech reserves the right, in
              its sole discretion, to limit the number of re-listing of loans a
              Borrowerer can post or attempt to post on its P2P Platform.
              <br />
              In the event of expiry of the Listing Period or Additional Listing
              Period of the Listing, and if such Listing has not been funded
              sufficiently, the Borrowerer may post a new Listing.
              <br />
              The Borrowerer may post a Listing for a minimum amount of Rs.
              [min_listingamount] (Rupees [min_listingamount_word] ) and a
              maximum amount of Rs. [max_listingamount] (Rupees
              [max_listingamount_word]). The loan amount shall be in multiples
              of Rs. 10,000 and the maximum loan amount for any Borrowerer shall
              be determined by Creditech. In limited instances, the maximum loan
              amount may differ due to constraints based on other variables.
              Creditech may modify the aforesaid limits from time to time at its
              discretion. The Borrowerer acknowledges that he shall not avail
              more than one loan through Creditech.
            </p>
          </div>
          <div className="policy_points">
            <h5>
              MATCHING OF BORROWERS, COMMITMENTS, LOAN FUNDING AND LOAN TENURE
            </h5>
            <p>
              On posting a Listing, the Borrowerer may receive offers from one
              or many Borrowers.
              <br />
              Creditech will publish the maximum loan amount eligibility for
              each Borrowerer depending on certain parameters including but not
              limited to his income and existing debt.
              <br />
              Creditech shall begin the disbursal process on the occurrence of
              the below mentioned incidents, whereby, Creditech will liaison
              between the Borrower and the Borrowerer to execute the requisite
              documents required to enable the transaction. On completion of the
              requisite legal formalities / documentation between the Borrowerer
              and the Borrower including but not limited to signed Electronic
              Clearing System (“ECS”) form, post-dated cheques, National
              Automated Clearing House Mandate, executed Loan Agreements, etc.,
              loan proceeds are disbursed within a period 3-5 working days from
              the Borrower’s escrow account to the Borrowerer’s designated bank
              account:
              <br />
              7.3.1 The Listing receives commitments upto a maximum of 100%
              before expiry of the Listing period and Additional Listing Period.
              <br />
              7.3.2 The Listing receives commitments equal to or exceeding the
              minimum amount required for the disbursement
              ([minthresh_disburse]% of the listed amount) before expiry of the
              Listing period and Additional Listing Period.
              <br />
              7.3.3 Prior Acceptance of the Borrowerer at the time of loan
              application to disburse the loan if the listing receives
              commitments less than the minimum amount required for the
              disbursement ([minthresh_disburse]% of the listed amount) and more
              than minimum amount required for the cancellation of listing
              ([thresh_cancel]% of the listed amount) at the expiry of Listing
              Period or Additional Listing Period.
              <br />
              Creditech accepts the best offers available against the Listing
              based on the lowest interest rate quoted by the Borrowers based on
              Creditech’s built-in system intelligence.
              <br />
              If the Borrowerer fails to abide by his commitment towards
              acceptance of the loan, his name will be delisted and he shall not
              be allowed to carry out any transaction on the P2P Platform in
              future.
              <br />
              A Listing may expire if the listing receives commitments equal to
              or less than the minimum amount required for the cancellation of
              listing ([thresh_cancel]% of the listed amount) at the expiry of
              Listing Period or Additional Listing Period.
              <br />
              Creditech may delay depositing the funds of a loan if it foresees
              and/or discovers any discrepancy in the accuracy of the
              information provided by the Borrowerer or Borrowers.
              <br />
              Creditech reserves the right to verify the accuracy of all
              information provided by Borrowerers, in connection with the
              Listing and loans through any of the available modes including by
              making a tele-call before, during or after the posting of a
              Listing, or before or after the funding of a loan. In case of any
              suspected fraud or inaccuracies in the information provided to it,
              Creditech reserves its right to cancel the funding at any point of
              time before disbursal. In the event a new Listing is found under
              the Borrowerer’s account on the P2P Platform prior to the funding
              of an existing Listing, Creditech may cancel the funding.
              <br />
              Borrowerers can Borrower loans for a maximum of 36 months.
              However, Creditech reserves the right to amend the tenure of loans
              from time to time. The loan tenure may be amended at any time
              before expiry of the Listing Period and until the loan amount has
              not been fully funded.
            </p>
          </div>
          <div className="policy_points">
            <h5>VIRTUAL WALLET</h5>
            <p>
              The Borrowerer may opt for a virtual wallet where he may park his
              money for the purpose of repayment of the loan amount to any
              Borrower. Money may be added in the virtual wallet by depositing
              funds in the Creditech – Borrowerer's escrow account.
            </p>
          </div>
          <div className="policy_points">
            <h5>FEE AND CHARGES</h5>
            <p>
              Creditech shall charge a registration fee, loan processing fee,
              collection fee, late payment fee and other charges, as the case
              may be, as specified on the P2P Platform or Loan Agreement. The
              same may be revised from time to time and the latest fee schedule
              shall be available on the P2P Platform.
            </p>
          </div>
          <div className="policy_points">
            <h5>LOAN REPAYMENTS</h5>
            <p>
              Loans repayments for each loan type may vary and may be revised
              from time to time. The latest repayment structures shall be
              available on the P2P Platform at any point in time.
              <br />
              The loan repayment for a bullet loan shall be a single frequency
              payment including the principal amount and accrued interest for
              the number of days the loan has been availed for.
              <br />
              The loan repayment for a personal loan shall be through equated
              monthly instalments (“EMI”) and the same shall be devised using
              Principal Reducing Balance Method.
              <br />
              10.3.1 An EMI is a fixed payment amount made by a Borrowerer to a
              Borrower at a specified date each calendar month. EMIs are used to
              pay off both interest and principal each month so that over a
              period of time, the loan is paid off in full.
              <br />
              10.3.2 The EMI schedule shall entail the following terms: The EMI
              repayment date which shall be the same day in the next month as
              the day on which the loan has been disbursed.
              <br />
              10.3.3 Every month EMI amount may or may not be auto-deducted from
              the Borrowerer’s bank account to the repayment escrow account. The
              Borrowerer may make repayments to the escrow account via direct
              debit, post-dated cheques, NEFT, RTGS, IMPS, UPI, demand draft or
              any other mode of payment as mentioned in the loan agreement.
            </p>
          </div>
          <div className="policy_points">
            <h5>Late fees</h5>
            <p>
              In case of delay in payment of equated monthly instalment or
              bullet repayment, the loan availed by the Borrowerer shall be
              considered delinquent and failed payment and late fees may be
              assessed. In the event of delay in payments, a late fee shall be
              levied based on the following criteria:
              <br />
              (i) The Borrowerer shall pay all EMIs and bullet repayment on the
              repayment date as mentioned in the loan agreement and as made
              available on the Borrowerer’s dashboard on the P2P Platform. In
              case the due EMI or repayment is not paid, a late payment penalty
              will be charged to the Borrowerer by Creditech at
              [penal_interest]% p.a. accrued on a daily basis.
              <br />
              Each month, the monthly loan payment may or may not automatically
              be withdrawn from the Borrowerer’s bank account. If the Borrowerer
              receives an email from Creditech that his payment has failed, it
              implies that his account has insufficient funds or that the bank
              account on file at Creditech is out of date or incorrect.
              <br />
              In either case, the Borrowerer will need to pay a failed payment
              fee and can fix the problem with no further penalty except by
              making a manual payment within 15 (fisteen) days past the due
              date.
              <br />
              In the event the Borrowerer’s bank account information is out of
              date, the Borrowerer shall add a new bank account first. In case
              there is any default in the payment, Creditech shall follow the
              delinquency schedule. The interest shall continue to accrue at all
              time, on the outstanding amount of the loan.
              <br />
              In the event Creditech ties up with any bank in the near future,
              the Borrowerer agrees to open a bank account with the Creditech
              designated bank through which all the loan transactions will be
              processed. In such a case, the Borrowerer agrees to make loan
              payments through ECS by maintaining an EMI Account. Till that
              period, Borrowerer agrees to provide post-dated cheques for the
              EMI amount for the total repayment amount at the time of loan
              disbursement.
              <br />
              The above conditions are subject to change at the sole discretion
              of Creditech and any such changes shall be available on the P2P
              Platform. These changes shall be effective for on-going loans as
              well as new loans.
              <br />
              The Borrowerer may prepay the loan subject to the payment of the
              foreclosure charges:
              <br />
              Only on the EMI due date and full outstanding amount.
              <br />
              The above conditions are subject to change at the sole discretion
              of Creditech and any such changes shall be made available on the
              P2P Platform. These changes shall be effective for all on-going
              loans as well as new loans.
              <br />
              Interest
              <br />
              Personalized interest rates are suggested to the Borrowerer as per
              his Risk Grade by an automated credit appraisal system that uses
              the personal and financial information provided by the Borrowerer
              to risk-assess and understand the strength of his credit profile.
              The interest rate thus assigned may range from [min_interest]% to
              [max_interest]%. Accordingly, the interest rate on the loan
              availed by the Borrowerer shall be communicated through the loan
              agreement.
              <br />
              The rate of interest applicable to the loan is compounded monthly
              and rests on the outstanding balance, namely the balance of loan
              and unpaid interest and costs, charges and expenses outstanding at
              the end of the month.
              <br />
              Upon repayment of the entire loan amount, interest thereon and any
              other fee and charges due to the Borrower or Creditech, the
              Borrowerer and the co-applicant / guarantor, shall stand
              discharged of all their obligations under this Agreement.
              Accordingly, once the total loan amount has been repaid in full
              along with all the dues, a loan closure certificate will be issued
              to the Borrowerer.
              <br />
              The Borrowerer hereby agrees and acknowledges that in the event
              there is a co-applicant or a co-guarantor along with the
              Borrowerer in respect of the loan availed through Creditech, under
              the Loan Agreement, executed between the Borrowerer and Borrower:
              <br />
              The co-applicant or the co-guarantor, as the case may be, shall be
              severally liable for repayment of the loan amount, interest and
              other fees and charges payable to the Borrower or Creditech by the
              Borrowerer; and
              <br />
              Creditech shall be entitled and at liberty to initiate legal
              proceedings against such co-applicant / co-guarantor on its behalf
              or on behalf of the Borrowers, as the case may be.
            </p>
          </div>
          <div className="policy_points">
            <h5>COLLECTION AND REPORTING OF DELINQUENT LOANS</h5>
            <p>
              Creditech shall proactively start communicating with the
              Borrowerer 5 (five) days prior to a repayment being due to ensure
              repayments are made on time.
              <br />
              In the event, the cheque is dishonoured or the EMI remains unpaid
              for 5 (five) days after the repayment is due, Creditech shall make
              an attempt to contact the delinquent Borrowerer via email (first
              written notice), SMS or phone.
              <br />
              If the ECS or cheque is dishonoured then Creditech shall attempt
              to contact the Borrowerer to inform and collect the EMI amount and
              charges, as applicable, proactively.
              <br />
              If the EMI is not received within 5-30 days after the repayment
              due date, further attempts will be made by the collections team to
              contact the Borrowerer and arrange for immediate repayment. Notice
              of adverse credit may also be reported to credit bureaus at this
              point.
              <br />
              Creditech shall issue to the Borrowerer a ‘Due Letter’ on
              completion of 45 (forty-five) days from the date the repayment is
              due and shall issue a ‘Recall Letter’ on completion of 75
              (seventy-five) days from the date repayment is due.
              <br />
              If no attempt at resolution is made in the next 30-90 days, the
              case is then handed to Creditech’s concerned team. However, at
              this point the aim is still to retrieve repayment, and the
              collections team will continue to work with the delinquent
              Borrowerer to reach an amicable solution.
              <br />
              If no attempt at reparation is made post 90 (ninety) days from
              when the repayment is due, the loan will move into a
              ‘Non-Performing Asset (“NPA”)’ state, and the case will handed
              over to an outsourced recovery team. If applicable, Creditech will
              also begin legal proceedings on behalf of the Borrowers in
              accordance with the terms of this Agreement. At this stage, the
              Borrowerer shall be classified as a defaulter.
              <br />
              To secure the interest of the Borrower, or if Creditech deems it
              so necessary, it may initiate legal proceedings any time against
              the Borrowerer for the purpose of repayment without necessarily
              adhering to the process stated in the extant clause.
              <br />
              The Parties agree that the process of collection and reporting of
              delinquent loans may be changed / altered / modified any time by
              Creditech without prior notice to the Borrowerer.
            </p>
          </div>
          <div className="policy_points">
            <h5>OBLIGATIONS</h5>
            <p>
              <span className="sub_points">Company Obligations:</span>
              <br />
              After freezing the Listing either in the Listing period or
              Additional Listing Period, Creditech shall liaison between the
              Borrowers and the Borrowerer to execute the required legal
              documents which will bind both the parties till the clearance of
              all the payments.
              <br />
              Creditech shall be responsible for the actions of its service
              providers including recovery agents and the confidentiality of
              information pertaining to the Borrowerer that is available with
              the service providers.
              <br />
              Creditech shall become a member of all Credit Information
              Companies (“CICs”) and submit data (including historical data) to
              them.
              <br />
              Creditech shall disclose to the Borrowerer, details about the
              Borrower(s) including proposed amount, interest rate offered but
              excluding personal identity and contact details of the
              Borrower(s).
              <br />
              Creditech agrees and acknowledges to:
              <br />
              (i) Keep the credit information (relating to Borrowerer
              transactions on the platform) maintained by it, updated regularly
              on a monthly basis or at such shorter intervals as may be mutually
              agreed upon between Creditech and the CICs; and
              <br />
              (ii) Take all such steps which may be necessary to ensure that the
              credit information furnished by it is up to date, accurate and
              complete;
              <br />
              Creditech agrees and acknowledges to:
              <br />
              (i) Undertake due diligence of the participants i.e. the
              Borrowerers and Borrowers;
              <br />
              (ii) Undertake credit assessment and risk profiling of the
              Borrowerers and disclose the same to their prospective Borrowers;
              <br />
              (iii) Require and obtain prior and explicit consent of the
              participant to access its credit information;
              <br />
              (iv) Undertake documentation of loan agreements and other related
              documents;
              <br />
              (v) Provide assistance in disbursement and repayments of loan
              amount; and
              <br />
              (vi) Render services for recovery of loans originated on the
              platform.
              <br />
              Creditech shall ensure that the staff are adequately trained to
              deal with the Borrowerer in an appropriate manner and shall not
              resort to harassment viz; persistently bothering the Borrowerers
              at odd hours, use of coercion for recovery of loans, etc.
              <br />
              Creditech shall ensure that any information relating to the
              Borrowerer, received by it, is not disclosed to any third party,
              without the consent of the Borrowerer.
              <br />
              Creditech provides for a Board approved policy to address
              grievances / complaints of the Borrowerer. Complaints shall be
              handled / disposed of by Creditech within such time and in such
              manner as provided for in its Board approved policy, but in any
              case not beyond a period of one month from the date of receipt of
              the grievance / complaint.
              <br />
              Creditech shall ensure that the technology driving the P2P
              Platform is scalable to handle growth in its business. Adequate
              safeguards shall be built in its IT systems to ensure that it is
              protected against unauthorized access, alteration, destruction,
              disclosure or dissemination of records and data.
              <br />
              Creditech provides for a Board approved business continuity plan
              in place for safekeeping of information and documents and
              servicing of loans for full tenure in case of closure of the
              platform.
              <br />
              Creditech shall immediately notify RBI in the event of any breach
              of security and leakage of confidential customer related
              information. In these eventualities, Creditech would be liable to
              the Borrowerer for any damages.
              <br />
              <br />
              <span className="sub_points">Borrowerer Obligations:</span>
              <br />
              To provide accurate and true information that is sought by
              Creditech for validating documents for the purpose of
              registration, verification, listing, disbursal, collection,
              repayment, recoveries, and all other acts specified under this
              Agreement.
              <br />
              To provide certified true copies of the documents sought for in
              respect of the aforesaid purposes.
              <br />
              To comply with the Terms of Use for the P2P Platform, Privacy
              Policy and any other rules or policies on the P2P Platform or any
              terms and conditions set forth under this Agreement or any other
              agreement, any of which may be amended from time to time by
              Creditech at its sole discretion.
              <br />
              To repay the entire loan amount along with interest due thereon
              and any other fee and charges payable to the Borrowers or
              Creditech within the time specified under this Agreement or any
              other agreement, without any default or delay.
              <br />
              To cooperate with Creditech with the information / documents
              sought from time-to-time for the purposes mentioned in the
              Agreement.
              <br />
              To provide a certificate to Creditech certifying that the
              aggregate loans taken by him at any point of time (including the
              one sought to be availed through Creditech), across all NBFC-P2Ps
              does not exceed Rs. 10,00,000 (Rupees [Ten Lakhs only]) or such
              other amount as may be prescribed from time to time under the RBI
              Directions or such other law, rules, regulations or guidelines
              that may be issued in this regard, from time to time.
              <br />
              The Borrowerer agrees that, in connection with any Listing,
              Borrower commitments, loans or other transactions involving or
              potentially involving Creditech, he shall not:
              <br />
              (i) Make any false, misleading or deceptive statement(s) or omit
              any material fact;
              <br />
              (ii) Misrepresent his identity, or describe, present or portray
              himself as a person other than himself;
              <br />
              (iii) Give to or receive from, or offer or agree to give to or
              receive from, any Borrower or other person connected to Creditech
              any fee, bonus, additional interest, kickback or thing of value of
              any kind, including in exchange for such person’s commitment,
              recommendation, or offer or agreement to recommend or make a
              commitment with respect to the Listing;
              <br />
              (iv) Represent himself to any person as a director, officer or
              employee of Creditech;
              <br />
              (v) Post any content on the website of Creditech, which may be
              abusive, harmful or defamatory to Creditech and / or to other
              members on the site, whether textual or pictorial; and
              <br />
              (vi) To either accept or reject the loan offer prior to expiration
              of Listing period or Additional Listing Period, as the case may
              be.
            </p>
          </div>
          <div className="policy_points">
            <h5>REPRESENTATIONS AND WARRANTIES</h5>
            <p>
              Creditech and the Borrowerer represent and warrant that they have
              not withheld or misrepresented any material information which
              could have an adverse effect on any act, transaction or any other
              aspect contemplated under this Agreement. Each Party to the
              Agreement makes the following representations and warranties with
              respect to itself, and confirms that they are, true, correct and
              valid: It has full power and authority to enter into, deliver and
              perform the terms and provisions of this Agreement and, in
              particular, to exercise its rights, perform the obligations
              expressed to be assumed by and make the representations and
              warranties made by it hereunder; Its obligations under this
              Agreement are legal and valid, binding on it and enforceable
              against it in accordance with the terms hereof; and The parties to
              the Agreement warrant and represent to have the legal competence
              and capacity to execute and perform this Agreement.
              <br />
              Creditech represents and warrants that:
              <br />
              shall act as an intermediary providing an online marketplace or
              platform to the participants involved in peer to peer lending and
              shall not raise deposits as defined by or under Section 45I(bb) of
              the Act or the Companies Act, 2013;
              <br />
              Not lend on its own;
              <br />
              Not provide or arrange any credit enhancement or credit guarantee;
              <br />
              Not facilitate or permit any secured lending linked to its
              platform; i.e. only clean loans will be permitted;
              <br />
              Not hold, on its own balance sheet, funds received from Borrowers
              for lending, or funds received from Borrowerers for servicing
              loans etc.;
              <br />
              Not cross sell any product except for loan specific insurance
              products;
              <br />
              Not permit international flow of funds;
              <br />
              Ensure adherence to legal requirements applicable to the
              participants as prescribed under relevant laws; and
              <br />
              Store and process all data relating to its activities and
              participants on hardware located within India.
            </p>
          </div>
          <div className="policy_points">
            <h5>RESTRICTIONS ON USE</h5>
            <p>
              Borrowerer shall represent him as himself and as no other third
              party.
              <br />
              Borrowerer is not authorized or permitted to use or avail the
              services of Creditech to make requests for someone other than
              himself.
              <br />
              All the transactions carried out by the Borrowerer shall be solely
              by and for himself and from his bank account.
            </p>
          </div>
          <div className="policy_points">
            <h5>NO GUARANTEE</h5>
            <p>
              Creditech does not guarantee that the Listing will be matched with
              any Borrower commitments, or that the Borrowerer will receive a
              loan as a result of posting a Listing.
            </p>
          </div>
          <div className="policy_points">
            <h5>TERMINATION</h5>
            <p>
              <span className="sub_points">
                This Agreement may be terminated:
              </span>
              <br />
              By mutual written agreement of the Parties;
              <br />
              If any laws or regulations or any amendments thereto makes the
              transaction or the Agreement illegal, or is otherwise prohibited
              by law or by an order, decree or judgement of any court or
              governmental body having competent jurisdiction;
              <br />
              <br />
              <span className="sub_points">Termination by Creditech:</span>
              <br />
              Creditech may, at its sole discretion, with or without cause,
              terminate this Agreement and registration with it, at any time, by
              giving notice to the Borrowerer.
              <br />
              In case of non-compliance of the Borrowerer’s obligations
              mentioned under Clause 12.2 of this Agreement, involvement of the
              Borrowerer in any prohibited or illegal purpose or the
              Borrowerer’s failure to abide by the terms and conditions of this
              Agreement or the terms and conditions of Creditech or any law of
              the land, or upon such determination in connection with
              registration / loan offer, Creditech may, at its sole discretion,
              immediately and without notice, take one or more of the following
              acts:
              <br />
              (i) terminate or suspend the Listing for requested loan on its P2P
              Platform;
              <br />
              (ii) terminate this Agreement and registration with Creditech; and
              <br />
              (iii) Upon termination of this Agreement and registration with
              Creditech, all loans disbursed and originated from Creditech will
              still hold good.
              <br />
              Creditech may, by giving notice to the Borrowerer, terminate this
              Agreement if there has been a material misrepresentation or breach
              of a representation and warranty or breach of covenant on the part
              of the Borrowerer.
              <br />
              Creditech may, by giving notice to the Borrowerer, terminate this
              Agreement if any event or circumstance occurs which, in the
              opinion of Creditech, could have a material adverse effect on the
              fulfilment of the obligations of the Borrowerer under this
              Agreement.
              <br />
              In the event of any failure to fund the loan after commitment, the
              Borrowerer shall not be eligible to browse any further Listings
              and his access to the P2P Platform shall be restricted.
              <br />
              <br />
              <span className="sub_points">Termination by Borrowerer:</span>
              <br />
              A Borrowerer is entitled to terminate this Agreement, at any time,
              after giving a notice to Creditech, provided that:
              <br />
              No outstanding amount/ dues in Borrowerer’s Account;
              <br />
              No active Listing on the P2P Platform.
              <br />
              A Borrowerer may terminate this Agreement if the Borrowerer has an
              active Listing, provided that the Borrowerer, subject to the terms
              and condition contained in this Agreement, withdraws the Listing
              from the P2P Platform.
              <br />
              Consequences of Termination
              <br />
              Upon termination of this Agreement and registration with
              Creditech, any Listing which is not funded shall be cancelled and
              shall be removed from the P2P Platform forthwith. The Borrowerer’s
              obligations specified in this Agreement shall subsist for the
              loans funded prior to the effective date of termination of this
              Agreement and registration with Creditech.
            </p>
          </div>
          <div className="policy_points">
            <h5>RIGHT TO MODIFY TERMS</h5>
            <p>
              For the reasons specified in Clause 17.2, Creditech reserves the
              right to modify the terms of this Agreement and / or its Terms of
              Use and / or its Privacy Policy and / or any other agreement
              involving the Borrowerer. Such changes shall not apply
              retrospectively.
              <br />
              Creditech will be at liberty to modify the terms, from time to
              time, without consent of the Borrowerer, due to any of the
              following reasons:
              <br />
              To make the terms clearer;
              <br />
              To make changes to its products and services, to introduce new
              products or services or to withdraw products or services that
              Creditech no longer offers;
              <br />
              To change its existing services owing to the changes and
              innovation in technology, systems, processes, business procedures
              and / or policies;
              <br />
              To adapt to changes in law, regulation, industry guidance or a
              decision of a competent court or any regulation formed by the RBI
              from time to time.
              <br />
              Modifications stated in this clause shall be communicated to the
              Borrowerer and also made available on the P2P Platform.
            </p>
          </div>
          <div className="policy_points">
            <h5>INDEMNIFICATION</h5>
            <p>
              Each Party shall, at all times, at present and thereafter, defend,
              indemnify and hold harmless the other Party, its employees,
              officers, directors, agents, representatives from and against any
              and all liabilities, suits, claims, actions, proceedings, losses,
              damages, judgments and costs, of any nature whatsoever (including
              Attorney’s fees) caused due to non-compliance of the terms and
              conditions of this Agreement, breach of representation and
              warranties, beach of covenants, or non-compliance of any
              applicable laws and regulations, breach of the confidentiality
              obligations and infringement of third-party intellectual property
              rights, damage to any third party’s property or body. This clause
              shall survive the expiry or termination of this Agreement.
            </p>
          </div>
          <div className="policy_points">
            <h5>FORCE MAJEURE</h5>
            <p>
              No Party shall be liable to the other if, and to the extent, that
              the performance or delay in performance of any of its obligations
              under this Agreement is prevented, restricted, delayed or
              interfered with, due to circumstances beyond the reasonable
              control of such Party, including but not limited to, government
              legislations, fires, floods, explosions, epidemics, accidents,
              acts of God, wars, riots, strikes, lockouts, or other concerted
              acts of workmen, acts of Government and / or shortages of
              materials, hacking, unanticipated technological or natural
              interference or intrusion, loss or damage to satellites, loss of
              satellite linkage or any other data communications linkage, loss
              of connectivity or any other irresistible force or compulsion.
              <br />
              The Party claiming an event of force majeure shall promptly notify
              the other Party in writing and provide full particulars of the
              cause or event and the date of first occurrence thereof, as soon
              as possible after the event and also keep the other Party informed
              of any further developments. The Party so affected shall use its
              best efforts to remove the cause of non-performance, and shall
              resume performance hereunder with the utmost responsibility when
              such cause is removed.
            </p>
          </div>
          <div className="policy_points">
            <h5>GOVERNING LAW AND JURISDICTION</h5>
            <p>
              This Agreement shall be governed by and construed in accordance
              with the laws of India and, courts of [Delhi], India shall have
              exclusive jurisdiction over all matters arising pursuant to this
              Agreement.
            </p>
          </div>
          <div className="policy_points">
            <h5>NOTICES</h5>
            <p>
              All notices under this Agreement shall be written in English and
              shall be sent by hand or by courier or by facsimile or by
              electronic mail to the applicable Party at the address / contact
              details indicated as in the description of Parties or to such
              other address as any Party shall designate by written notice to
              the other Party and shall be deemed to be given only when
              delivered by personal delivery or by courier or by electronic mail
              at the above mentioned address.
            </p>
          </div>
          <div className="policy_points">
            <h5>AMENDMENT AND ASSIGNMENT</h5>
            <p>
              Any amendments to this Agreement shall be legally valid and
              binding on each of the Parties, if made in writing and signed by
              each of the Parties. No Party shall be entitled to transfer or
              assign any rights or obligations under this Agreement to third
              parties without informing the other Party and obtaining the prior
              written approval of such other Party.
            </p>
          </div>
          <div className="policy_points">
            <h5>WAIVER</h5>
            <p>
              The failure of any Party to insist, in one or more instances, upon
              strict performance of the obligations of this Agreement, or to
              exercise any rights contained herein, shall not be construed as
              waiver, or relinquishment for the future, of such obligation or
              right, which shall remain and continue in full force and effect.
              No waiver of any breach of any provision of this Agreement shall
              be effective or binding unless made in writing and signed by the
              Party purporting to give the same and, unless otherwise provided
              in the written waiver, shall be limited to the specific breach
              waived.
            </p>
          </div>
          <div className="policy_points">
            <h5>SEVERABILITY</h5>
            <p>
              A provision contained in this Agreement is enforceable
              independently of each of the others and its validity will not in
              any way be affected by the invalidity or unenforceability of any
              other provision hereof. If any provision of this Agreement is held
              to be illegal, invalid or unenforceable under any applicable law
              from time to time: (a) such provision will be fully severable from
              this Agreement; (b) this Agreement will be construed and enforced
              as if such illegal, invalid or unenforceable provision had never
              comprised a part hereof; and (c) the remaining provisions of this
              Agreement will remain in full force and effect and will not be
              affected by the illegal, invalid or unenforceable provision or by
              its severance here from.
            </p>
          </div>
          <div className="policy_points">
            <h5>ENTIRE AGREEMENT</h5>
            <p>
              This Agreement contains the whole agreement and understanding
              between the Parties with regard to the matters dealt with in this
              Agreement and unless provided otherwise supersedes any prior
              agreement, understanding, arrangement or promises, whether written
              or oral or whether subsisting or expired, relating to the subject
              matter of this Agreement The Parties expressly acknowledge that,
              in relation to the subject matter of this Agreement, each of them
              assumes no obligations of any kind whatsoever other than as
              expressly set forth in this Agreement.
            </p>
          </div>
          <div className="policy_points">
            <h5>COUNTERPARTS</h5>
            <p>
              This Agreement may be executed simultaneously in more than one
              counterpart each of which shall be deemed to be an original but
              all of which shall constitute the same instrument.
              <br />
              IN WITNESS WHEREOF, the Parties hereto executed these presents on
              the day, month and year first above mentioned.
            </p>
          </div>
          <p>
            Note: This agreement executed on behalf of Creditech Solutions
            Private Limited is system generated by the platform and does not
            require any seal/signature in original.
          </p>
        </div>
      </div>
    </div>
  );
}

export default BorrowerRegisterationAggrement;
