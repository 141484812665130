import React, { useState } from "react";

const FaqB = () => {
  const [activeTab, setActiveTab] = useState("");

  const openFAQ = (faqName) => {
    setActiveTab(faqName === activeTab ? null : faqName);
  };

  return (
    <div className="container">
      <div className="tab">
        <div className="faqOuterBox borrowBox">
          <button className={`tablinks ${activeTab === "Faq-1" ? "active" : ""}`} onClick={() => openFAQ("Faq-1")}>
            Why do you require documents?
          </button>
          <div id="Faq-1" className={`tabcontent ${activeTab === "Faq-1" ? "active" : ""}`}>
            <p className="faqcontent">
              Basic KYC documents are required as per the regulatory guidelines
              for availing loans under peer to peer lending. The documents are
              also required to identity-verify, credit-check and risk-assess a
              borrower before registration. Apart from the credit score, we use
              various other parameters like ability, stability, past performance
              and intention of the borrowers, which are evaluated on the basis
              of these documents. The documents provided like salary slips, bank
              statements, ITR etc. help in taking a collective and transparent
              decision ensuring a fair field for the borrower.
            </p>
          </div>
        </div>
        <div className="faqOuterBox borrowBox">
          <button className={`tablinks ${activeTab === "Faq-2" ? "active" : ""}`} onClick={() => openFAQ("Faq-2")}>
            Why should I pay a registration fee?
          </button>
          <div id="Faq-2" className={`tabcontent ${activeTab === "Faq-2" ? "active" : ""}`}>
            <p className="faqcontent">
              The registration fee is towards the considerable time, effort and
              resources spent by our credit evaluation mechanism to carefully
              assess each borrower profile. The fee is non-refundable and
              payable at the time of registration only by the borrower at the
              Alpha Money portal.
            </p>
          </div>
        </div>
        <div className="faqOuterBox borrowBox">
          <button className={`tablinks ${activeTab === "Faq-3" ? "active" : ""}`} onClick={() => openFAQ("Faq-3")}>
            Why do you charge a processing fee?
          </button>
          <div id="Faq-3" className={`tabcontent ${activeTab === "Faq-3" ? "active" : ""}`}>
            <p className="faqcontent">
            Processing fee is charged towards the loan file processing
              charges, underwriting effort and platform charges etc.
            </p>
          </div>
        </div>
        <div className="faqOuterBox borrowBox">
          <button className={`tablinks ${activeTab === "Faq-4" ? "active" : ""}`} onClick={() => openFAQ("Faq-4")}>
            How much can I borrow?
          </button>
          <div id="Faq-4" className={`tabcontent ${activeTab === "Faq-4" ? "active" : ""}`}>
            <p className="faqcontent">
              At Alpha Money, loan disbursal is dependent on loan requirements.
              For personal loan requirements, the amount can vary from Rs.
              30,000/- to Rs. 1,00,000/-. For bullet loans, a borrower can apply
              for a loan from Rs. 10,000/- to Rs. 50,000/-.
            </p>
          </div>
        </div>
        <div className="faqOuterBox borrowBox">
          <button className={`tablinks ${activeTab === "Faq-5" ? "active" : ""}`} onClick={() => openFAQ("Faq-5")}>
            What interest rates can I expect to pay?
          </button>
          <div id="Faq-5" className={`tabcontent ${activeTab === "Faq-5" ? "active" : ""}`}>
            <p className="faqcontent">
              Interest rates are suggested to each borrower by a combination of
              a manual and automated credit appraisal system that uses the
              personal and financial information provided by borrowers across
              various data points to risk-asses the borrowers and understand the
              strength of their credit profile. The interest rate thus assigned
              ranges from 10% p.a. to 16% p.a. in case of a personal loan and
              25% p.a. to 31% p.a. in case of a bullet loan.
            </p>
          </div>
        </div>
        <div className="faqOuterBox borrowBox">
          <button className={`tablinks ${activeTab === "Faq-6" ? "active" : ""}`} onClick={() => openFAQ("Faq-6")}>
            On what parameters does Alpha Money verify my profile and check my
              creditworthiness?
          </button>
          <div id="Faq-6" className={`tabcontent ${activeTab === "Faq-6" ? "active" : ""}`}>
            <p className="faqcontent">
              We use a highly-developed, technology-driven process of
              verification across several data points based on the personal and
              financial information and documents provided by the borrower. Each
              borrower is identity-checked, credit-checked and risk-assessed by
              our experienced team. The intention, stability and ability of
              borrowers is evaluated and understood.
            </p>
          </div>
        </div>
        <div className="faqOuterBox borrowBox">
          <button className={`tablinks ${activeTab === "Faq-7" ? "active" : ""}`} onClick={() => openFAQ("Faq-7")}>
            How much time does it generally take before a loan is listed?
          </button>
          <div id="Faq-7" className={`tabcontent ${activeTab === "Faq-7" ? "active" : ""}`}>
            <p className="faqcontent">
              Once a borrower Signs Up, provides basic information, pays the
              non-refundable filing fee and uploads the required documents, the
              risk assessment team takes 48-72 (office) hours to evaluate and
              verify the borrower; on the basis of which the credit appraisal
              mechanism sets the parameters for the loan – loan amount, rate of
              interest and loan tenure. Once the borrower accepts the same, the
              loan application is made live on the portal. Thus, your loan
              requirement will be available on the website for lenders to fund
              between 48-72 hours from your first log-in on the website.
            </p>
          </div>
        </div>
        <div className="faqOuterBox borrowBox">
          <button className={`tablinks ${activeTab === "Faq-8" ? "active" : ""}`} onClick={() => openFAQ("Faq-8")}>
          How much time does it take to find a lender? How long can my loan
              request be available for lenders to fund?
          </button>
          <div id="Faq-8" className={`tabcontent ${activeTab === "Faq-8" ? "active" : ""}`}>
            <p className="faqcontent">
              All loans are listed on the platform for 3 days which may, under
              special circumstances, be extended. This is known as a listing
              period. However, the funding of the loan cannot be predicted or
              controlled as it is dependent upon attracting sufficient lenders.
              You can monitor lenders' interest anytime by logging into your
              account. The loan application will expire automatically after the
              listing period is complete.
            </p>
          </div>
        </div>
        <div className="faqOuterBox borrowBox">
          <button className={`tablinks ${activeTab === "Faq-9" ? "active" : ""}`} onClick={() => openFAQ("Faq-9")}>
            How long is the disbursal process?
          </button>
          <div id="Faq-9" className={`tabcontent ${activeTab === "Faq-9" ? "active" : ""}`}>
            <p className="faqcontent">
              The disbursal process starts only after all the stakeholders have
              signed the loan agreement. By using technology-driven facilities
              available only on Alpha Money you can reduce this time to less
              than 24 hours. Post this process, funds are transferred from the
              lender’s wallet to the borrower’s bank account within 12-24 hours.
              Thus, the entire disbursal process takes 24-48 hours. It is
              transparent and driven by the borrower and lender interest with
              Alpha Money acting only as a facilitator.
            </p>
          </div>
        </div>
        <div className="faqOuterBox borrowBox">
          <button className={`tablinks ${activeTab === "Faq-10" ? "active" : ""}`} onClick={() => openFAQ("Faq-10")}>
            Why was my loan application declined?
          </button>
          <div id="Faq-10" className={`tabcontent ${activeTab === "Faq-10" ? "active" : ""}`}>
            <p className="faqcontent">
              Alpha Money holds the right to decline a listing in certain cases
              such as:
              <br />
              1. The loan application didn’t attract any investor during the
              live period.
              <br />
              2. The Borrower is unable to provide the complete documentation
              required for risk assessment.
              <br />
              3. The borrower’s creditworthiness and intention/stability and
              ability to take and repay the loan is not satisfactory. Any
              deficiency on these parameters or in terms of past loan
              performance of the borrower will lead to the cancellation of the
              listing of the borrower.
              <br />
              4. At any stage of a loan application, Alpha Money finds that
              misleading information was deliberately provided by the borrower
              with intentions to cheat the Alpha Money system, its Lenders,
              founders, stakeholders, partners or employees or to infringe or
              steal the intellectual property of Alpha Money.
            </p>
          </div>
        </div>
        <div className="faqOuterBox borrowBox">
          <button className={`tablinks ${activeTab === "Faq-11" ? "active" : ""}`} onClick={() => openFAQ("Faq-11")}>
           What happens in case of delayed or non-payment of EMI?
          </button>
          <div id="Faq-11" className={`tabcontent ${activeTab === "Faq-11" ? "active" : ""}`}>
            <p className="faqcontent">
              Borrowers are morally and legally obliged to pay all dues in a
              timely manner. In case the EMI is not paid on the scheduled time,
              penal interest (36% p.a.) is payable as per Alpha Money policy for
              each instance of delayed repayment. Alpha Money may also charge a
              non-refundable overdue charge per delayed payment which will be
              adjusted against repayment before adjustment of the delayed EMI
              amount. So, Alpha Money encourages all borrowers to make timely
              payments to avoid penalty charges. In the worst-case scenario, if
              Alpha Money has to issue a legal notice to a defaulting borrower
              on behalf of the lenders then a non-refundable charge of Rs. 500/-
              per legal notice will be payable by the borrower to Alpha Money.
            </p>
          </div>
        </div>
        <div className="faqOuterBox borrowBox">
          <button className={`tablinks ${activeTab === "Faq-12" ? "active" : ""}`} onClick={() => openFAQ("Faq-12")}>
          How are repayments calculated?
          </button>
          <div id="Faq-12" className={`tabcontent ${activeTab === "Faq-12" ? "active" : ""}`}>
            <p className="faqcontent">
               Repayments of Bullet Loans is calculated as a sum of the principal
              loan amount and accrued daily interest till maturity. Personal
              Loans will be repaid in Equated Monthly Instalments over the
              tenure selected by the borrower.
            </p>
          </div>
        </div>
        <div className="faqOuterBox borrowBox">
          <button className={`tablinks ${activeTab === "Faq-13" ? "active" : ""}`} onClick={() => openFAQ("Faq-13")}>
            Will Alpha Money provide me with my credit score?
          </button>
          <div id="Faq-13" className={`tabcontent ${activeTab === "Faq-13" ? "active" : ""}`}>
            <p className="faqcontent">
              Alpha Money does not provide you with your credit score. However,
              you will have access to the Alpha-Score computed by Alpha Money
              based on our proprietary scoring model.
            </p>
          </div>
        </div>
        <div className="faqOuterBox borrowBox">
          <button className={`tablinks ${activeTab === "Faq-14" ? "active" : ""}`} onClick={() => openFAQ("Faq-14")}>
            What information can be viewed about me?
          </button>
          <div id="Faq-14" className={`tabcontent ${activeTab === "Faq-14" ? "active" : ""}`}>
            <p className="faqcontent">
              Once your listing has been approved by Alpha Money, your loan
              application can be viewed by all registered Lenders including your
              unique virtual ID and Alpha-Score. In your loan listing, the loan
              amount, loan tenure, rate of interest is visible to the investor.
              However, this information will not be visible to general visitors
              to the website. For more information, please view our Privacy
              Policy.
            </p>
          </div>
        </div>
        <div className="faqOuterBox borrowBox">
          <button className={`tablinks ${activeTab === "Faq-15" ? "active" : ""}`} onClick={() => openFAQ("Faq-15")}>
            Will my address and phone number be available online on the
              website?
          </button>
          <div id="Faq-15" className={`tabcontent ${activeTab === "Faq-15" ? "active" : ""}`}>
            <p className="faqcontent">
              We do not share anyone’s address or phone number with any other
              party on the platform. For more information, please view our
              Privacy Policy.
            </p>
          </div>
        </div>
        <div className="faqOuterBox borrowBox">
          <button className={`tablinks ${activeTab === "Faq-16" ? "active" : ""}`} onClick={() => openFAQ("Faq-16")}>
            Can I change the loan amount I want to borrow after applying?
          </button>
          <div id="Faq-16" className={`tabcontent ${activeTab === "Faq-16" ? "active" : ""}`}>
            <p className="faqcontent">
              No, to avoid confusion and maintain lender interest, we do not
              allow the borrower to make changes after the loan application has
              been made live on the website. To increase the loan amount, you
              can create a fresh loan request for the difference.
            </p>
          </div>
        </div>
        <div className="faqOuterBox borrowBox">
          <button className={`tablinks ${activeTab === "Faq-17" ? "active" : ""}`} onClick={() => openFAQ("Faq-17")}>
            Can my loan request be terminated or reviewed at any stage?
          </button>
          <div id="Faq-17" className={`tabcontent ${activeTab === "Faq-17" ? "active" : ""}`}>
            <p className="faqcontent">
              Yes, if at any stage the information furnished by you or the
              verification(s) initiated by us are found to be incomplete,
              fraudulent or unverifiable then the funding request is terminated
              and consequently, the invest button on the profile may be
              deactivated. No fee paid will be refunded.
            </p>
          </div>
        </div>
        <div className="faqOuterBox borrowBox">
          <button className={`tablinks ${activeTab === "Faq-18" ? "active" : ""}`} onClick={() => openFAQ("Faq-18")}>
            How do I cancel my loan application?
          </button>
          <div id="Faq-18" className={`tabcontent ${activeTab === "Faq-18" ? "active" : ""}`}>
            <p className="faqcontent">
              Please write to support@alphamoney.in requesting the cancellation of
              your loan application. Loan cancellation may only happen prior to
              the disbursal of funds only if your request is approved.
            </p>
          </div>
        </div>
        <div className="faqOuterBox borrowBox">
          <button className={`tablinks ${activeTab === "Faq-19" ? "active" : ""}`} onClick={() => openFAQ("Faq-19")}>
            How do I change my bank details or set up a new direct debit?
          </button>
          <div id="Faq-19" className={`tabcontent ${activeTab === "Faq-19" ? "active" : ""}`}>
            <p className="faqcontent">
              Please write to support@alphamoney.in stating both your old and new
              bank details.
            </p>
          </div>
        </div>
        <div className="faqOuterBox borrowBox">
          <button className={`tablinks ${activeTab === "Faq-20" ? "active" : ""}`} onClick={() => openFAQ("Faq-20")}>
            Can I be both a Lender and a Borrower?
          </button>
          <div id="Faq-20" className={`tabcontent ${activeTab === "Faq-20" ? "active" : ""}`}>
            <p className="faqcontent">
              No,you can either be a Lender or a Borrower at a given time.
            </p>
          </div>
        </div>
        <div className="faqOuterBox borrowBox">
          <button className={`tablinks ${activeTab === "Faq-21" ? "active" : ""}`} onClick={() => openFAQ("Faq-21")}>
             In case of a personal loan, can I choose my monthly repayment
              date?
          </button>
          <div id="Faq-21" className={`tabcontent ${activeTab === "Faq-21" ? "active" : ""}`}>
            <p className="faqcontent">
              No, if the disbursement happens before the 20th of a month, the
              repayment date will be on the 3rd of the next month and if it
              happens after the 20th, the repayment date will be the 3rd of the
              month after that. For example, if a disbursement happens on 12th
              January 2022, then the repayment date will be 3rd February 2022,
              and if the disbursement happens on 23rd January 2022, the
              repayment date will be 3rd March 2022.
            </p>
          </div>
        </div>
        <div className="faqOuterBox borrowBox">
          <button className={`tablinks ${activeTab === "Faq-22" ? "active" : ""}`} onClick={() => openFAQ("Faq-22")}>
            How is my rate of interest calculated?
          </button>
          <div id="Faq-22" className={`tabcontent ${activeTab === "Faq-22" ? "active" : ""}`}>
            <p className="faqcontent">
              The interest rate is based upon your Alpha Score which takes into
              account all details submitted by you at the time of registration
              and loan application. This is a proprietary scoring model of Alpha
              Money that takes into account your credit score, employment
              details, loan request, repayment history etc.
            </p>
          </div>
        </div>
        <div className="faqOuterBox borrowBox">
          <button className={`tablinks ${activeTab === "Faq-23" ? "active" : ""}`} onClick={() => openFAQ("Faq-23")}>
            What happens if I wish to foreclose the loan?
          </button>
          <div id="Faq-23" className={`tabcontent ${activeTab === "Faq-23" ? "active" : ""}`}>
            <p className="faqcontent">
              The foreclosure charges in case of Personal Loan are 4% of the
              outstanding amount exclusive of GST and in case of Bullet Loan are
              Half of the Remaining interest exclusive of GST.
            </p>
          </div>
        </div>
        <div className="faqOuterBox borrowBox">
          <button className={`tablinks ${activeTab === "Faq-24" ? "active" : ""}`} onClick={() => openFAQ("Faq-24")}>
            Does Alpha Money undergo a physical verification?
          </button>
          <div id="Faq-24" className={`tabcontent ${activeTab === "Faq-24" ? "active" : ""}`}>
            <p className="faqcontent">
              To keep the process simple and easy for our borrowers, we
              generally have a completely digital verification process, however
              in case any information provided by a borrower seems suspicious,
              we may undergo a physical verification.
            </p>
          </div>
        </div>
        <div className="faqOuterBox borrowBox">
          <button className={`tablinks ${activeTab === "Faq-25" ? "active" : ""}`} onClick={() => openFAQ("Faq-25")}>
            What are the charges on late payments?
          </button>
          <div id="Faq-25" className={`tabcontent ${activeTab === "Faq-25" ? "active" : ""}`}>
            <p className="faqcontent">
              Penal Interest on Late Payment : 36% p.a. accrued daily.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqB;