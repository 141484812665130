// import React from "react";
// function Constant() {
//     return (
//         <>
//         Constant
//         </>
//     )
// }

// export default Constant;

export const URL = {
    API_URL: 'https://api.alphamoney.in/api/',
    BASE_URL: "https://d3kgk0blw9vqbg.cloudfront.net/",
}