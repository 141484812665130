import React from 'react';

function Demonstration(props) {

  const [valueTest, setValue] = React.useState('');

  const handleChange = (event) => {
 
    setValue(event.target.value);

 
  };
  window.open(valueTest,"_self");

  
    return (
        <div style={{marginLeft:"6rem"}}>
            <div style={{textAlign:"center"}}>
            <p className='h2productdemo1'>ALPHAMONEY From A To Z</p>
            <p className='h2productdemo2'>Product Demonstrations</p>
            </div>
           
            <div className='row main_section_buttons'>
                      <span className='col-4 col-sm-3 col-xl-3 main_section_button'>
                      {/* <select className="btn btn-light btn-demo"  >
                        <option ><a onClick={() => goTopath('https://app.alphamoney.in')}>Borjhgjhgjrower</a></option>
                        <option><a onClick={() => goTopath('https://app.alphamoney.in')} >Investor</a></option> 
                        <option><a href={"#"} >Investor</a></option>

                      </select> */}
                      <select className="btn btn-light btn-demo" value={valueTest} onChange={handleChange}>
                        <option value="demoborrower" >Borrower</option>
                        <option value="demoinvestor">Investor</option>
                      </select>

                      <select className="btn btn-light btn-demo1"  >
                      <option>Hindi</option>
                      <option>English</option>
                      </select>
                      </span>
                      </div>
                      
                      <div class="row ">
                      <div class="col-sm-8 ">
                      <iframe style={{width:620,height:515,marginTop:"3rem",borderRadius:"1rem"}}
                      src="https://www.youtube.com/embed/OPGPTFR-ciA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen >
                      </iframe> 
                      <p style={{borderRadius:"1rem",marginTop:"1rem"}}>
                     <div style={{backgroundColor:"#FFECEC", height:62,width:615 ,paddingLeft:"",textAlign:"center",marginTop:"1rem"}}>
                 
                    <span style={{color:"#000000",fontStyle:"normal",fontWeight:"900",fontSize:"20px" }}>[Hindi]</span>{" "} {' '}
                    <span style={{color:"",fontStyle:"normal",fontWeight:"",fontSize:"20px" }}>Applying for a loan at Alpha Money</span>
                 
                     </div>
                      </p>
                      </div>
                      <div class="col-sm-4 demonstrationdflexgap">
                      <div class="col-sm-4">
                      <iframe style={{width:320,height:215,marginTop:"3rem"}}
                      src="https://www.youtube.com/embed/OPGPTFR-ciA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
                      >
                      </iframe>
                      <p  style={{marginTop:"8px"}}>
                      <div style={{backgroundColor:"#FFECEC", height:60,width:323,marginBottom:"-18px" ,paddingLeft:"",paddingTop:"1rem",botextAlign:"center"}}>
                      <span style={{color:"#000000",fontStyle:"normal",fontWeight:"900",fontSize:"15px",textAlign:"center",marginLeft:"7px"}}>[Punjabi]</span>{" "} {' '}
                      <span style={{color:"",fontStyle:"normal",fontWeight:"",fontSize:"15px"}}>Applying for a loan at Alpha Money</span>
                      </div>
                       </p> 
                      <iframe style={{width:320,height:215,marginTop:"1.3rem"}}
                      src="https://www.youtube.com/embed/OPGPTFR-ciA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
                      >
                      </iframe> 
                      <p  style={{marginTop:"8px"}}>
                      <div style={{backgroundColor:"#FFECEC", height:60,width:323 ,paddingLeft:"",paddingTop:"1rem",textAlign:"center"}}>
                      <span style={{color:"#000000",fontStyle:"normal",fontWeight:"900",fontSize:"15px"}}>[Marathi]</span>{" "} {' '}
                      <span style={{color:"",fontStyle:"normal",fontWeight:"",fontSize:"15px"}}>Applying for a loan at Alpha Money</span>
                      </div>
                       </p> 
                      </div>
                      
                      </div>
                    </div>
                    <div class="row kkk">
                      <div class="col-sm">
                      <iframe style={{width:320,height:215,marginTop:"3rem"}}
                      src="https://www.youtube.com/embed/OPGPTFR-ciA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
                      >
                      </iframe> 
                      <p  style={{marginTop:"8px"}}>
                      <div style={{backgroundColor:"#FFECEC", height:60,width:323,marginBottom:"-18px" ,paddingLeft:"",paddingTop:"1rem",botextAlign:"center"}}>
                      <span style={{color:"#000000",fontStyle:"normal",fontWeight:"900",fontSize:"15px",textAlign:"center",marginLeft:"7px"}}>[Telengu]</span>{" "} {' '}
                      <span style={{color:"",fontStyle:"normal",fontWeight:"",fontSize:"15px"}}>Applying for a loan at Alpha Money</span>
                      </div>
                       </p> 
                      </div>
                      <div class="col-sm demonstrationdflexgap1">
                      <iframe style={{width:320,height:215,marginTop:"3rem"}}
                      src="https://www.youtube.com/embed/OPGPTFR-ciA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
                      >
                      </iframe> 
                      <p  style={{marginTop:"8px"}}>
                      <div style={{backgroundColor:"#FFECEC", height:60,width:323,marginBottom:"-18px" ,paddingLeft:"",paddingTop:"1rem",textAlign:"center"}}>
                      <span style={{color:"#000000",fontStyle:"normal",fontWeight:"900",fontSize:"15px"}}>[English]</span>{" "} {' '}
                      <span style={{color:"",fontStyle:"normal",fontWeight:"",fontSize:"15px"}}>Applying for a loan at Alpha Money</span>
                      </div>
                       </p> 
                      </div>
                      <div class="col-sm demonstrationdflexgap1">
                      <iframe style={{width:320,height:215,marginTop:"3rem"}}
                      src="https://www.youtube.com/embed/OPGPTFR-ciA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
                      >
                      </iframe> 
                      <p  style={{marginTop:"8px"}}>
                      <div style={{backgroundColor:"#FFECEC", height:60,width:323,marginBottom:"-18px" ,paddingLeft:"",paddingTop:"1rem",textAlign:"center"}}>
                      <span style={{color:"#000000",fontStyle:"normal",fontWeight:"900",fontSize:"15px"}}>[Hindi]</span>{" "} {' '}
                      <span style={{color:"",fontStyle:"normal",fontWeight:"",fontSize:"15px"}}>Applying for a loan at Alpha Money</span>
                      </div>
                       </p> 
                      </div>
                    </div>
                    <div class="row kkk">
                    <div class="col-sm">
                    <iframe style={{width:320,height:215,marginTop:"3rem"}}
                    src="https://www.youtube.com/embed/OPGPTFR-ciA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
                    >
                    </iframe> 
                    <p  style={{marginTop:"8px"}}>
                    <div style={{backgroundColor:"#FFECEC", height:60,width:323,marginBottom:"-18px" ,paddingLeft:"",paddingTop:"1rem",botextAlign:"center"}}>
                    <span style={{color:"#000000",fontStyle:"normal",fontWeight:"900",fontSize:"15px" ,textAlign:"center",marginLeft:"7px"}}>[Hindi]</span>{" "} {' '}
                    <span style={{color:"",fontStyle:"normal",fontWeight:"",fontSize:"15px"}}>Applying for a loan at Alpha Money</span>
                    </div>
                     </p> 
                    </div>
                    <div class="col-sm demonstrationdflexgap1">
                    <iframe style={{width:320,height:215,marginTop:"3rem"}}
                    src="https://www.youtube.com/embed/OPGPTFR-ciA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
                    >
                    </iframe> 
                    <p  style={{marginTop:"8px"}}>
                    <div style={{backgroundColor:"#FFECEC", height:60,width:323,marginBottom:"-18px" ,paddingLeft:"",paddingTop:"1rem",botextAlign:"center"}}>
                    <span style={{color:"#000000",fontStyle:"normal",fontWeight:"900",fontSize:"15px",textAlign:"center",marginLeft:"7px"}}>[Hindi]</span>{" "} {' '}
                    <span style={{color:"",fontStyle:"normal",fontWeight:"",fontSize:"15px"}}>Applying for a loan at Alpha Money</span>
                    </div>
                     </p> 
                    </div>
                    <div class="col-sm demonstrationdflexgap1">
                    <iframe style={{width:320,height:215,marginTop:"3rem"}}
                    src="https://www.youtube.com/embed/OPGPTFR-ciA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
                    >
                    </iframe> 
                    <p  style={{marginTop:"8px"}}>
                    <div style={{backgroundColor:"#FFECEC", height:60,width:323,marginBottom:"-18px" ,paddingLeft:"",paddingTop:"1rem",botextAlign:"center"}}>
                    <span style={{color:"#000000",fontStyle:"normal",fontWeight:"900",fontSize:"15px",textAlign:"center",marginLeft:"7px"}}>[Hindi]</span>{" "} {' '}
                    <span style={{color:"",fontStyle:"normal",fontWeight:"",fontSize:"15px"}}>Applying for a loan at Alpha Money</span>
                    </div>
                     </p> 
                    </div>
                  </div>

        </div>
    );
}

export default Demonstration;