import React, { useEffect, useState } from 'react';
import EnquireImg from "../../src/public/assest/img/enquire.svg";
import UserIcon from "../../src/public/assest/img/user.svg";
import FlagIcon from "../../src/public/assest/img/flag.svg";
import EmailIcon from "../../src/public/assest/img/email.svg";
import QueryIcon from "../../src/public/assest/img/query.svg";
import CloseIcon from "../../src/public/assest/img/close-icon.svg";
import { Toast, ToastContainer } from 'react-bootstrap';
import {ActionCreators} from '../redux/actions'
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import Loader from './loader';

const queryData = [
    {
        id: '1',
        name: 'Product Info'
    },
    {
        id: '2',
        name: 'Feedback or Suggestions'
    },
    {
        id: '3',
        name: 'Existing User Help'
    },
    {
        id: '4',
        name: 'Partnerships'
    },
    {
        id: '5',
        name: 'Complaint'
    }
];

const EnquireButton = (props) => {
    const contactState = useSelector((state) => state.contactState)
    const [openForm, setOpenForm] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        reason: '',
        message: ''
    });
    const [status, setStatus] = useState(null)
    const [message, setMessage] = useState(null)

    useEffect(() => {
        if (contactState?.contactEnquiry?.data?.status === true) {
            setStatus('success')
            setMessage('Your Details Sent Successfully')
            setShowAlert(true);
            setFormData({})
        } else {
            setStatus('danger')
            setMessage('Your Details Submission Failed')
        }
      }, [contactState?.contactEnquiry])

    const handleOpen = () => {
        setOpenForm(!openForm);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'mobile') {
            const truncatedValue = value.slice(0, 10);
            setFormData({ ...formData, [name]: truncatedValue });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleSubmit = (e) => {
        if (!formData.name || !formData.email || !formData.mobile || !formData.reason || !formData.message) {
            setStatus('danger')
            setMessage('Please fill in all required fields')
            setShowAlert(true);
            return;
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.email)) {
            setStatus('danger')
            setMessage('Please enter a valid email address');
            setShowAlert(true);
            return;
        }
        let contactData = {
            name: formData.name,
            email: formData.email,
            mobile: formData.mobile,
            reason: formData.reason,
            message: formData.message,
        }
        e.preventDefault();
        props.actions.postEnquiryAction(contactData)
    };

    return (
        <>
            {(contactState?.contactEnquiry?.loading) &&
            <Loader />
            }
            <div className='enquire_button_container'>
                <button className='enquire_button' onClick={handleOpen}>
                    <img src={EnquireImg} alt='' />
                    Enquire
                </button>
            </div>
            {openForm ?
                <div className='enquire_form_container'>
                    <div className='enquire_form'>
                        <img src={CloseIcon} className='close' onClick={handleOpen} alt='close' />
                        <h4>Enquire Now</h4>
                        <div className='input_box'>
                            <img src={UserIcon} alt='user' />
                            <input
                                name="name"
                                value={formData.name ?? ''}
                                type="text"
                                placeholder="Name"
                                onChange={handleChange}
                            />
                        </div>
                        <div className='input_box flag_icon'>
                            <img src={FlagIcon} alt='flag' />
                            <input
                                name="mobile"
                                value={formData.mobile ?? ''}
                                type="number"
                                placeholder="Mobile"
                                onChange={handleChange}
                            />
                        </div>
                        <div className='input_box mail_icon'>
                            <img src={EmailIcon} alt='email' />
                            <input
                                name="email"
                                value={formData.email ?? ''}
                                type="email"
                                placeholder="Email"
                                onChange={handleChange}
                            />
                        </div>
                        <div className='input_box flag_icon'>
                            <img src={QueryIcon} alt='query' />
                            <select name="reason" value={formData.reason ?? ''} onChange={handleChange}>
                                <option value="" disabled>Select your Query</option>
                                {queryData?.map((item) => (
                                    <option key={item.id} value={item.name}>{item.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className='input_box mail_icon'>
                            <img src={EmailIcon} alt='email' />
                            <input
                                name="message"
                                value={formData.message ?? ''}
                                type="text"
                                placeholder="Message"
                                onChange={handleChange}
                            />
                        </div>
                        <div className='form_actions'>
                            <button className="btn_black" onClick={handleSubmit}>Submit </button>
                        </div>
                    </div>
                </div> :
                null
            }
            <ToastContainer className='p-3' position={'top-end'} >
                <Toast onClose={() => setShowAlert(false)} show={showAlert} delay={3000} autohide bg={status} className="toast_slide">
                    <Toast.Body>{message}</Toast.Body>
                </Toast>
            </ToastContainer>
        </>
    );
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(ActionCreators, dispatch),
  })
  
export default connect(null, mapDispatchToProps)(EnquireButton)

  