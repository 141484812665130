import React from "react";
function GrievancePolicy(props) {
  return (
    <div className="policy_container">
      <div className="policy_header">
        <div className="container">
          <h2 className="policy_heading">
            <b>Grievance </b> Redressal Policy
          </h2>
        </div>
      </div>
      <div className="container">
        <div className="policy_breadcrumb">
          <h3>
            <span><a href="/">Home</a> </span>
            <span> /</span>
            <span> Grievance Redressal Policy</span>
          </h3>
        </div>
        <div className="policy_body">
          <div className="policy_points">
            <h5>1. Introduction</h5>
            <p>
              The grievance redressal policy is designed by Creditech
              Solutions Private Limited (&ldquo;Alphamoney&rdquo;) in order to
              address the issues, concerns, complaints or grievances
              (&ldquo;Grievances&rdquo; or &ldquo;Complaint/s&rdquo;) of the
              lenders and borrowers (&ldquo;Participants&rdquo;) on the
              Alphamoney website (
              <a
                data-fr-linked="true"
                className="footercontentlinks"
                href="//www.alphamoney.in"
              >
                www.alphamoney.in
              </a>{" "}
              and all associated mobile applications, if any)
              (&ldquo;Platform&rdquo;) . In order to address the Grievances,
              Alphamoney has set-up a structured grievance redressal
              framework. Alphamoney will ensure that the redressal of
              Grievances is fair, just and within the given framework of rules
              and regulation.
            </p>
            <p>
              It the one of the primary source of credit worthiness and capacity of
              prospective borrower to repay the loan. We can understand the
              prospective borrower&rsquo;s capacity by assessing the bank account
              statement of the salary account, credit card statements, credit report
              and payment track record as per credit information bureau, minimum and
              average monthly bank balance, bounced cheques, probability of
              successful repayment of loans and cash outflow to specific accounts to
              determine current monthly liability.
            </p>
          </div>
          <div className="policy_points">
            <h5>2. Grievance Redressal Mechanism</h5>
            <p>
              <span className="sub_points"> 2.1 </span> Participants can send us their Grievances by email or contact us
                on our customer care number as mentioned below:
            </p>
            <p>
              <span className="sub_points">Email ID:</span> <a
                  className="footercontentlinks"
                  data-fr-linked="true"
                  href="mailto:support@alphamoney.in"
                >
                  support@alphamoney.in
                </a>
            </p>
            <p>
              <span className="sub_points"> Customer care number:</span> -- (Between
              10:00 am and 6:00 pm, from Monday to Friday except business
              holidays) Complaints registered through above means are
              addressed within 5 (five) business days. Grievances should be
              sent through the email which is registered with Alphamoney
            </p>
            <p>
              <span className="sub_points"> 2.2 </span> If the Complaint is not resolved within 5 (five) business days,
                the Participant can address its Complaint to the grievance
                redressal officer. The grievance redressal officer,<b> Mr. Sunil Pal</b>
               {" "} is available at{" "}
                <a
                  data-fr-linked="true"
                  className="footercontentlinks"
                  href="mailto:support@alphamoney.in"
                >
                  support@alphamoney.in
                </a>{" "}
               <b> +91-9319689801.</b> 
            </p>
            <p>
              <span className="sub_points"> 2.3 </span> If the Complaint is still not addressed within 10 (ten) business
                days, the Participan can address its Complaint to the nodal
                officer. The nodal officer, <b>Mr. Anil Bansal</b>  is available at
               {" "}
                <a
                  data-fr-linked="true"
                  className="footercontentlinks"
                  href="mailto:support@alphamoney.in"
                >
                  support@alphamoney.in
                </a>{" "} {" "}
                <b>+91- 9319689802.</b>
            </p>
            <p>
              <span className="sub_points"> 2.4 </span>  If the Complaint remain unresolved within a period of 1 (one)
                month, the Participant can appeal to the Chief General Manager,
                Customer Education and Protection Department, Reserve Bank of
                India, 1st Floor, Amar Building, Sir P.M. Road, Mumbai- 400001,
                contact number {" "}<b>+91-9319689801.</b> 
            </p>
          </div>
       </div>
      </div>
    </div>
  );
}


export default GrievancePolicy;
