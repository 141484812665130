import React from 'react'
import { URL } from '../utility/Constant'
import RBILOGO from '../public/assest/img/rbi-logo-footer.svg'
import RupeeIcon from '../public/assest/img/rupee.svg'
import SmileyIcon from '../public/assest/img/smiley.svg'
import NPAIcon from '../public/assest/img/npa.svg'

export default function DownloadApp() {
  return (
    <section className="app_section common_space">
        <div className="container">
            <div className="app_box">
                <div className="row">
                    <div className="col-md-12 col-lg-8">
                        <div className="app_content">
                            <h5>Download ALPHAMONEY app: Empower Your Finances on the Go!</h5>
                            <p>Your financial freedom awaits! Download ALPHAMONEY's app and take control of your money from anywhere. Available on Google Play Store and the Apple App Store</p>
                            <div className="flex_items">
                                <div>
                                    <img alt=''
                                    src={RBILOGO}
                                    className="rbi_logo" />
                                    <img alt=''
                                    src={`${URL.BASE_URL}Home+Page/rbi_logo.gif`}
                                    className="rbi_logo" />
                                </div>
                                
                                {/* <img alt='' src={`${URL.BASE_URL}Home+Page/app_store.png`} />
                                <img alt='' src={`${URL.BASE_URL}Home+Page/play_store.png`} /> */}
                                <a href="https://apps.apple.com/in/app/alphamoney/id6462969874" target="_blank">
                                    <img src={`${URL.BASE_URL}Home+Page/app_store.png`} alt="" />
                                </a>
                                <a href="https://play.google.com/store/apps/details?id=com.creditTech.alphamoney&pli=1" target="_blank">
                                    <img src={`${URL.BASE_URL}Home+Page/play_store.png`} alt="" />
                                </a>
                            </div>
                            <p className='mt-4'>An RBI registered (CoR 14.03566) fintech, we are pioneering in providing financial solutions for the next generation of borrowers and investors across the credit spectrum.</p>
                            <div className='app_main_footer_card'>
                                <div className='app_footer_card'>
                                    <img src={RupeeIcon} />
                                    <div className='app_card_content count_block'>
                                        <h2>1,55,60,109</h2>
                                        <h6>Total AUM</h6>
                                    </div>
                                </div>
                                <div className='app_footer_card'>
                                    <img src={SmileyIcon} />
                                    <div className='app_card_content customer_block'>
                                        <h2>355+</h2>
                                        <h6>Trusted Customers</h6>
                                    </div>
                                </div>
                                <div className='app_footer_card'>
                                    <img src={NPAIcon} />
                                    <div className='npa_box'>
                                        <div className='app_card_content border_npa'>
                                            <h2>1.35%</h2>
                                            <h6>NPA (90 Days to 1 Year)</h6>
                                        </div>
                                        <div className='app_card_content customer_block'>
                                            <h2>0%</h2>
                                            <h6>NPA (>1 Year)</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="app_image">
                    <img alt='' src={`${URL.BASE_URL}Home+Page/mob3.svg`} />
                </div>
            </div>
        </div>
    </section>
  )
}
