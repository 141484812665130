import React from "react";

function FairPractice(props) {
  return (
    <div className="policy_container">
      <div className="policy_header">
        <div className="container">
          <h2 className="policy_heading">
            <b>Fair </b> Practices Code
          </h2>
        </div>
      </div>
      <div className="container">
        <div className="policy_breadcrumb">
          <h3>
            <span><a href="/">Home</a> </span>
            <span> /</span>
            <span> Fair Practices Code</span>
          </h3>
        </div>
        <div className="policy_body">
          <div className="policy_points">
            <h5>1. Introduction</h5>
            <p>
              Creditech Solutions Private Limited (
              <span className="sub_points">
                &ldquo;Alphamoney&rdquo;
              </span>
              ) is an online peer to peer lending platform which enables the
              Participants (defined hereinafter) on the Alphamoney platform (
              <a className="footercontentlinks" data-fr-linked="true" href="//www.alphamoney.in">
                www.alphamoney.in
              </a>
              ) and all associated mobile applications, if any (
              <span className="contentofheading">
                &ldquo;Platform&rdquo;
              </span>
              ) intended to bring together Participants who intend to lend (
              <span className="contentofheading">
                &ldquo;Lender&rdquo;
              </span>
              ) and Participants who intend to borrow (
              <span className="contentofheading">
                &ldquo;Borrower&rdquo;
              </span>
              ) across the country. The fair practices code (
              <span className="contentofheading">
                &ldquo;Code&rdquo;
              </span>
              ) is formulated in accordance with the Master Directions, Non-Banking
              Financial Company, Peer to Peer Lending Platform (Reserve Bank)
              Directions, 2017, amended from time to time.
            </p>
          </div>
          <div className="policy_points">
            <h5>2. Objective of the Code</h5>
            <p>The Code is framed with the aim to achieve minimum fair practice standards and best practices while dealing undertakes between Lender and Borrower (collectively referred to as “Participants”) on the Platform. The Code is set-up to:</p>
            <p>
              <span className='sub_points'>a.</span> Provide an overview of financial services offered by Alphamoney to its Participants.
            </p>
            <p>
              <span className="sub_points">b.</span> Provide greater transparency in all transactions on the Platform and
                help them to make informed decisions about the financial facilities
                offered by Alphamoney.
            </p>
            <p>
              <span className="sub_points">c.</span> Enable the Participants to have better and clear understanding of various products and services offered by Alphamoney.
            </p>
            <p>
              <span className="sub_points">d. </span>
              Foster fair and cordial relationship between the Participants and Alphamoney.
            </p>
            <p>
              <span className="sub_points">e. </span> 
              Ensure compliances relating to Indian laws and regulations including those prescribed by the Reserve Bank of India (RBI).
            </p>
          </div>
          <div className="policy_points">
            <h5>3. Application for loans and their processing</h5>
            <p>
              <span className='sub_points'>a.</span> All communications to the Participants shall be in English.
            </p>
            <p>
              <span className="sub_points">b.</span> All documents and terms and conditions, including details on the website such as the privacy policy, terms and conditions of use, etc. shall be maintained in English.
            </p>
            <p>
              <span className="sub_points">c.</span> Loan application forms issued by Alphamoney shall include necessary information which affects the interests of the Borrower, to enable the Borrower to compare similar terms and conditions offered by other financial institutions and make an informed decision. It shall also indicate the documents required to be executed/ submitted by the Borrower.
            </p>
            <p>
              <span className="sub_points">d. </span>
              A detailed terms and conditions will be provided by Alphamoney which is to be accepted by the Participants during and post the on-boarding process on the Platform. Alphamoney strongly urges Participants to obtain appropriate independent professional advice before proceeding to borrow any money using the Platform.
            </p>
            <p>
              <span className="sub_points">e. </span> 
              An email confirmation shall be provided to the Participants acknowledging the receipt of the loan application/registration on the Platform. The Borrower would be informed of the procedure involved in servicing and closure of the loan taken on the Platform.
            </p>
            <p>
              <span className="sub_points">f. </span> 
              Alphamoney will provide a time frame for processing the loan application at the time of registration.
            </p>
            <p>
              <span className="sub_points">g. </span> 
              The Platform does not provide any assurance regarding the recovery of loans.
            </p>
            <p>
              <span className="sub_points">h. </span> 
              There is no discrimination based on gender, cast, religion, etc. while registration of Participants on the Platform. The credit scores arrived at by the Platform is based on the financial behaviour of the Borrower.
            </p>
          </div>
          <div className="policy_points">
            <h5>4. Loan Appraisal and Terms & Conditions</h5>
            <p>
              <span className='sub_points'>a. </span> The Borrower shall be given a loan sanction letter and copy of the loan document together with annexures/enclosures by Alphamoney, which shall include the details regarding loan such as loan amount sanctioned, interest rate, method of application and any other terms and conditions.
            </p>
            <p>
              <span className="sub_points">b. </span> 
              The penal interest that will be charged for late payment shall be mentioned in bold to the Borrower in the sanction letter/loan document.
            </p>
            <p>
              <span className="sub_points">c. </span> 
              An acknowledged copy of the sanction letter shall be kept as part of the document.
            </p>
            <p>
              <span className="sub_points">d. </span>
              Loan applications shall be disposed of on the same day or within 2 (two) days of receipt of the complete application form. However, there can be delay in sanctioning of the loan beyond the period of 2 (two) days due to reasons like field verification etc. Borrower shall be kept informed of the status of his loan application.
            </p>
            <p>
              <span className="sub_points">e. </span> 
              Changes in the terms and conditions of loan will be notified to the Participants through registered e-mail address. The changes will be applicable for all the loans sanctioned subsequently.
            </p>
          </div>
          <div className="policy_points">
            <h5>5. Disbursement of loan</h5>
            <p>
              <span className='sub_points'>a. </span> 
              All the fund transfer shall take place according to the fund transfer mechanism as per the guidelines laid down by RBI.
            </p>
            <p>
              <span className="sub_points">b. </span> 
              All fund transfers will happen through bank channel. Cash transaction is strictly prohibited.
            </p>
            <p>
              <span className="sub_points">c. </span> 
              Alphamoney shall issue a no-objection certificate to the Borrower upon closure of loan within 15 (fifteen) business days from the completion of all the formalities.
            </p>
            <p>
              <span className="sub_points">d. </span>
              In the case of recovery of loans, Alphamoney shall resort to the usual measures, which are legally and legitimately available to it and as per guidelines laid down by the RBI.
            </p>
          </div>
          <div className="policy_points">
            <h5>6. Interest rate and other charges</h5>
            <p>Alphamoney shall frame appropriate internal policies and procedures for determining the interest rates, processing and other charges, if any and the approach for gradation of risks and these will be conveyed to the Participants from time to time. </p>
          </div>
          <div className="policy_points">
            <h5>7. General</h5>
            <p>
              <span className='sub_points'>a. </span>
              Alphamoney shall merely facilitate the transaction contemplated on its Platform as per the terms and conditions of the loan agreement executed between Alphamoney, the Participants.
            </p>
            <p>
              <span className="sub_points">b. </span> 
              Alphamoney shall not interfere in the affairs of the Borrower except for the purposes provided in the terms and conditions of the loan agreement.
            </p>
            <p>
              <span className="sub_points">c. </span> 
              Alphamoney shall explain the requirements of KYC guidelines to its Participants and inform them about the documents required for establishing the identity before loan sanctioning and operation. Alphamoney undertakes such measure in order to comply with the applicable laws.
            </p>
            <p>
              <span className="sub_points">d. </span>
              In the event of any new information regarding the Borrower is made available to the Lender, the Lender shall disclose the same to Alphamoney and Alphamoney will undertake action in such a manner that it does not hamper the functioning of the Borrower.
            </p>
            <p>
              <span className="sub_points">e. </span>
              In case, Borrower sends a written request to Alphamoney for transfer of Borrower’s Bank account/Bank, Alphamoney will act as per the transparent contractual terms in consonance with all the applicable laws.
            </p>
            <p>
              <span className="sub_points">f. </span>
              Alphamoney obtains explicit affirmation from the Lender stating that he/ she has understood the risks associated with the proposed transaction and that there is no guarantee of return and that there exists a likelihood of loss of entire principal in case of default by a Borrower.
            </p>
            <p>
              <span className="sub_points">g. </span>
              In case of outsourcing of activity, the outsourced organisation is responsible for the actions of its service providers including recovery agents and the confidentiality of information pertaining to the Participants. The outsourced organisation including its employees and representatives need to follow the terms and conditions of this Code.
            </p>
            <p>
              <span className="sub_points">h. </span>
              Alphamoney does not encourage, support or allow to borrow money from the Platform or use the same money to lend on another platform in any manner.
            </p>
            <p>
              <span className="sub_points">i. </span>
              Alphamoney encourages and insists that the Participants should read and understand the Alphamoney policy and terms and conditions before registering
              and entering into transaction.
            </p>
            <p>
              <span className="sub_points">j. </span>
              Alphamoney encourages and insists that the Participants will adhere to all laws relating to income tax and pay taxes on the income so generated.
            </p>
            <p>
              <span className="sub_points">k. </span>
              The Lenders are responsible for all and any decision that they take to lend through Alphamoney and Alphamoney does not take any responsibility for any Borrower’s default.
            </p>
            <p>
              <span className="sub_points">l. </span>
              In case of any Borrower’s default, Alphamoney encourages and insist that Lender will only take services of approved legal advisors.
            </p>
          </div>
          <div className="policy_points">
            <h5>8. Collection of Dues</h5>
            <p>If the Borrower does not adhere to the repayment schedule, a defined process in accordance with the laws of the land will be followed for recovery of dues. The process will involve reminding the Borrower by sending the notice or by making personal visits or any other possible means. In case of default, Alphamoney may refer the case to recovery agents and will inform the customer of the recovery proceedings being initiated. Alphamoney shall ensure that its process of recoveries will not involve harassment of the Borrower. Appropriate instructions will be provided by Alphamoney to its staffs for handling customer queries and grievances cordially.</p>
          </div>
          <div className="policy_points">
            <h5>9. Confidentiality</h5>
            <p>Alphamoney will ensure the confidentiality and secrecy of non-public information of the Participants and will not disseminate to the third party, except when required by statutory, public interest and business obligation. Alphamoney is committed to securing any data entrusted to it and follows generally accepted industry standards in its endeavour to protect the same during transmission, reception, etc.</p>
          </div>
          <div className="policy_points">
            <h5>10. Grievance Redressal Mechanism</h5>
            <p>Alphamoney has laid down appropriate grievance redressal mechanism which can be accessed at [https://www.alphamoney.in/grievance-redressal-policy]. The board of directors of Alphamoney shall periodically review the compliance of this Code and the functioning of the grievances redressal mechanism at various levels of management.</p>
          </div>
          <div className="policy_points">
            <h5>11. Amendment</h5>
            <p>Any modifications or amendment to the Code shall be updated on the Platform from time to time.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FairPractice;
