import React from "react";
import brIcon from "../../public/assest/img/borrowerIcon.svg";
import inIcon from "../../public/assest/img/investorIcon.svg";
function RecoveryCollectionPolicy(props) {
  const gotoPage = (path) => {
    window.location.href = path;
  };
  return (
    <div className="policy_container">
      <div className="policy_header">
        <div className="container">
          <h2 className="policy_heading">
            <b>Recovery </b> And Collection Policy
          </h2>
        </div>
      </div>
      <div className="container">
        <div className="policy_breadcrumb">
          <h3>
            <span><a href="/">Home</a> </span>
            <span> /</span>
            <span> Recovery And Collection Policy</span>
          </h3>
        </div>
        <div className="policy_body">
          <div className="policy_points">
            <h5>Preamble:</h5>
            <p>The Collections & Recovery policy of Creditech Solutions Private Limited (hereinafter referred to as the <span className="sub_points">“Company”</span> ) shall aim at making the recovery process faster to avoid any Non-Performing Assets ( <span className="sub_points">“NPA”</span> ). The policy is aimed at strengthening the management and recovery of NPAs and seeks to undertake proactive initiatives to monitor existing accounts closely</p>
          </div>
          <div className="policy_points">
            <h5>Recovery and Resolution Mechanism:</h5>
            <p className="mb-0">The guiding principles for this policy are as specified below:</p>
            <ol>
              <li>The recovery process/procedure will be based on legal and acceptable practices.</li>
              <li>The Company will follow only ethical practices and will not resort to unduly coercive tactics in the process of recovery of NPAs.</li>
              <li>The defaulters will be treated with respect and dignity while being focussed on recovery.</li>
            </ol>
          </div>
          <div className="policy_points">
            <h5>Tools of recovery:</h5>
            <p>The repayment record of borrowers shall be monitored both with regard to payment of interest and repayment of the principal. Whenever a borrower defaults or is likely to default, rigorous follow-up shall be made for the collection of dues or arrears. When default occurs, oral and written communications shall be sent to the borrower to regularize their accounts within a specified period.</p>
            <p className="mb-0">Company shall on a case-by-case basis use any of the following broad methods for management of problem accounts:</p>
            <ol>
              <li>Restructuring, rephasement, and rehabilitation</li>
              <li>Settlements / compromise</li>
              <li>Write off</li>
            </ol>
          </div>
          <div className="policy_points">
            <h5>Following procedure is to be followed by the company:</h5>
            <h5 className="pt-2 mb-2 px-3">1. Prior to default:</h5>
            <ul>
              <li>Automated SMS/emails to be regularly sent to the borrowers prior to the EMI due date (“Due Date”) to serve as a reminder of their obligation towards repayment.</li>
              <li>Phone call from the Company to the borrower to be made 5 days before the Due Date as a reminder to pay the EMI.</li>
            </ul>
            <h5 className="pt-2 mb-3 px-3">2. Post default: The Company shall adopt the following procedure upon occurrence of default:</h5>
            <table className="table table-bordered mx-3">
            <thead>
              <tr>
              <th style={{width: '5%'}}>S. No.</th>
              <th style={{width: '25%'}}>Stage of Default (Post EMI Stage)</th>
              <th>Recovery Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>30 days past Due Date</td>
                <td>
                  <ul>
                    <li>
                    Borrowers who are in default past 30 days of the Due Date, their case shall move to the collection team (<span className="contentofheading">“Collection Team”</span>) which shall carry out regular follow-ups and monitor the status of the outstanding payment.
                    </li>
                    <li>
                    If the borrower doesn’t cooperate over phone calls, then the Collection Team shall physically visit the borrower at the communication address to seek repayment of the outstanding loan.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td>30 to 60 days past Due Date</td>
                <td>
                  <ul>
                    <li>
                    The Collection Team to visit the borrower’s location again to remind him/her of the obligation to make repayment. 
                    </li>
                    <li>
                    Dunning letter will be issued to the defaulting borrower by the Company.
                    </li>
                    <li>
                    If within 60 days from the Due Date, the borrower does not respond, then a <u> preliminary legal notice </u> shall be sent to the borrower warning him/her on initiation of legal proceedings by the Company post 90 days from the Due Date.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>3</td>
                <td>90 days past Due Date</td>
                <td>
                  <ul>
                    <li>
                    The Company shall provide regular updates on recovery to all lenders and also appoint a dedicated portfolio manager for the same.
                    </li>
                  </ul>
                </td>
              </tr>

            </tbody>
            </table>
          </div>
          <div className="policy_points">
            <h5>Other clauses:</h5>
          </div>
          <div className="policy_points">
            <p><b>Prepayment of loan:</b>The lender has no right ask the borrower to prepay the loan amount, however, the borrower has the right to prepay the loan subject to conditions laid down in the loan agreement executed with the lender.</p>
          </div>
          <div className="policy_points">
            <p><b>Late fee:</b>Late fee is applicable on the payments not made by the borrowers on the due date as per the repayment schedule and the loan agreement.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecoveryCollectionPolicy;
