import React from "react";

function CancellationPolicy(props) {
  return (
    <div className="policy_container">
      <div className="policy_header">
        <div className="container">
          <h2 className="policy_heading">
            <b>Cancellation </b> and Refund Policy
          </h2>
        </div>
      </div>
      <div className="container">
        <div className="policy_breadcrumb">
          <h3>
            <span><a href="/">Home</a> </span>
            <span> /</span>
            <span> Cancellation and Refund Policy</span>
          </h3>
        </div>
        <div className="policy_body">
          <p>
            Alphamoney strongly recommends that all their users, lenders and
            borrowers, read, understand and familiarize themselves with our
            business model and cancellation and refund policy prior to using any
            services of <a className="footercontentlinks" href="www.alphamoney.in">www.alphamoney.in</a>(
            <span className="sub_points">
              “Website”
            </span>)
        </p>
        <p>
          No overseas payment can be made through wire transfer or any other
            means to our bank account. In the event of cancellation of services
            due to any avoidable/unavoidable reasons, we must be notified of the
            same in writing at admin@alphamoney.in. Cancellation request should
            be placed within 24 (twenty-four) hours of the execution of the
            agreement. Cancellation charges will be effective from the date we
            receive your request in writing, and cancellation charges would be
            as per the terms provided under the Terms of Use. Alphamoney
            reserves the right, at its sole discretion, to refuse or cancel any
            order for any reason whatsoever. We may also require additional
            verifications or information before accepting any instruction or
            registering on our Website as a lender or a borrower. The loan
            processing may get rejected at any stage due to lack of information
            or verifiable information.
        </p>
        <p>
          Any refunds, if applicable within the purview of our refund policy,
          will be processed over a period of 2-4 weeks due to the internal
          clearances and banking procedures. The refund policy is subject to
          change by Alphamoney. The changes will apply after due legal
          diligence. Suitable announcements will be made on the Website and it
          is the responsibility of every lender and borrower to remain updated
          by visiting the Website regularly. If lenders or borrowers do not
          wish to accept the revised policy, lenders or borrowers should not
          continue to use the services and inform us about the same on{" "}
          <a className="footercontentlinks" href="support@alphamoney.in">
            support@alphamoney.in
          </a>{" "}
        </p>
        <p>
          If lenders or borrowers continue to use the services after the date
          on which the change comes into effect, lenders or borrowers use of
          the services indicates your agreement to be bound by the new refund
          policy.
        </p>
       </div>
      </div>
    </div>
  );
}

export default CancellationPolicy;
