/* eslint-disable import/namespace */
import * as actionTypes from '../actions/actionsTypes'

const INITIAL_STATE = {
  contactEnquiry: {
    data: null,
    loading: false,
    error: null,
  },
}

const contactReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.CONTACT_ENQUIRY_REQUEST:
      return {
        ...state,
        contactEnquiry: {
          ...state.contactEnquiry,
          data: null,
          loading: true,
          error: null,
        },
      }
    case actionTypes.CONTACT_ENQUIRY_SUCCESS:
      return {
        ...state,
        contactEnquiry: {
          ...state.contactEnquiry,
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    case actionTypes.CONTACT_ENQUIRY_FAILURE:
      return {
        ...state,
        contactEnquiry: {
          ...state.contactEnquiry,
          data: null,
          loading: false,
          error: action.payload,
        },
      }
    default:
      return state
  }
}

export default contactReducers
