import React from "react";
import "./App.css";
// import "./Aminate.css";
import "../src/public/assest/css/global.css";
import "../src/public/assest/css/fonts.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/public/assest/css/responsive.css";
import Routes from "./AppRoutes";
import Header from "./elements/Header";
import Footer from "./elements/Footer";

function App() {
  let location = window.location;
  setTimeout(() => {
    const box = document.getElementById("show-1");
    if (box) {
      box.style.display = "none";
    }
  }, 1999);

  setTimeout(() => {
    const box = document.getElementById("show-2");
    if (box) {
      box.style.display = "block";
    }
  }, 2000);

  setTimeout(() => {
    const box = document.getElementById("show-3");
    box.style.display = "block";
  }, 2000);
  return (
    <React.Fragment>
      {
      <div className="preloader" id="show-1">
        <div className="lds-circle"><div /></div>
      </div>
      }
      <div className="" id="show-2" style={{ display: (location.pathname) ? "none" : "block" }}>
        <div className="appHeader">
        {(location.pathname != '/FAQ_Mobile' && location.pathname != '/terms-of-use-mobile' && location.pathname != '/privacy-policy-mobile' && location.pathname != '/about-us-mobile' && location.pathname != '/contactus_mobile') &&  <Header /> }
        </div>
      </div>
      <div className="" id="show-3" style={{ display: (location.pathname) ? "none" : "block" }}>
        <div className="routes">
          <Routes />
        </div>
        { (location.pathname != '/FAQ_Mobile' && location.pathname != '/terms-of-use-mobile' && location.pathname != '/privacy-policy-mobile' && location.pathname != '/about-us-mobile' && location.pathname != '/contactus_mobile') &&  <Footer /> }
      </div>
    </React.Fragment>
  );
}
export default App;
