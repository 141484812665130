import React, { useState } from "react";
import logo from ".././../public/assest/img/alphamoney_logo.png";
import icon from ".././../public/assest/img/alphalogowhitebgc.svg";
import bgalpha from "../../public/assest/img/alphabgb.png";
import { Link } from "react-router-dom";

let data = [];
function ContactUsMobile() {
  let [contactData, setcontactData] = useState({});
  let [storeData, setstoreData] = useState([]);
  let handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setcontactData({ ...contactData, [name]: value });
    // console.log(contactData);
  };
  let clicked = () => {
    data.push(contactData);
    setstoreData(data);
  };

  return (
    <div>
      <div className="bulletContainer33 mobileContent">
        <div className="topContactTitle">
          <h2 className="contactushaeding">Contact Us</h2>
          <p className="contactusparagraph">
            If you have any queries or suggestions, drop us a
           
              <br />
              message. We love to chat!
       
          </p>
		  </div>
          <div className="container containercontactus">
            <div className="row kl">
              
			  
			  <div className="col-lg-8">
                <div className="contatccolumnfirst">
				<div className="row">
                  <div className="col-lg-6 prelative">
				   <div className="firstcolcontactus ">
                    <h4 className="contactformfield1 ">Contact Us</h4>
                    <p className="contactusparagraph deskNone">If you have any queries or suggestions, drop us amessage. We love to chat!</p>
					<p className="contactformfield2 mobNone">
                      Feel free to contact us{" "}
                    </p>
                    <div className="mobileCotactinfo deskNone"> 
				   <p className="topara">To,</p>
                    <a
                      className="linksdatainfooter contactuslink"
                      href="info@alphamoney.in"
                    >
                      info@alphamoney.in
                    </a>
                    {/* <p className="contactuslink">+91 96257 82073</p> */}
                    <p className="fontWeight400">
                      CreditTech Solutions Pvt Ltd.
                      <br />
                      <span>J-602,6th Floor, J Tower, World Trade Centre,</span>
                      <br /> Ring Rd, Nauroji Nagar, New Delhi, Delhi 110029
                    </p>
					</div>
                    <div className="form-group contactformfield3 ">
                      <input
                        name="Name"
                        value={storeData.Name}
                        type="text"
                        className="form-control inputclass"
                        id="exampleFormControlInput1"
                        placeholder="Name*"
                        onChange={handleChange}
                      />
                      <br />
                      <input
                        name="Email"
                        value={storeData.Email}
                        type="email"
                        className="form-control inputclass"
                        id="exampleFormControlInput1"
                        placeholder="Email*"
                        onChange={handleChange}
                      />
                      <br />
                      <input
                        name="ContactNo"
                        value={storeData.ContactNo}
                        type="number"
                        className="form-control inputclass"
                        id="exampleFormControlInput1"
                        placeholder="Contact Number*"
                        onChange={handleChange}
                      />
                      <br />
                      <select
                        className="custom-select custom-select1 inputclass optionreason "
                        name="Options"
                        value={storeData.Options}
                        id="inputGroupSelect01"
                        placeholder=""
                        onChange={handleChange}
                      >
                        <option className="optioncssstyle">
                          Reason for Contacting*
                        </option>
                        <option className="optioncssstyle">Product Info</option>
                        <option className="optioncssstyle">
                          Feedaback or Suggestions
                        </option>
                        <option className="optioncssstyle">
                          Existing User Help
                        </option>
                        <option className="optioncssstyle">
                          {" "}
                          Partnerships
                        </option>
                        <option className="optioncssstyle">Complaint</option>
                      </select>
                      <br />
                      <br />
                      <input
                        name="Message"
                        value={storeData.Message}
                        type="text"
                        className="form-control inputclass"
                        id="exampleFormControlInput1"
                        placeholder="Message*"
                        onChange={handleChange}
                      />
                    </div>
					<div className="vl"></div>
                  </div> 
				  </div>
                
                  
                  <div className="col-lg-6">
				  <div className="alignaddress">
                    <img src={icon} alt="Images" />
                  
				   <p className="topara">To,</p>
                    <a
                      className="linksdatainfooter contactuslink"
                      href="info@alphamoney.in"
                    >
                      info@alphamoney.in
                    </a>
                    {/* <p className="contactuslink">+91 96257 82073</p> */}
                    <p className="fontWeight400">
                      CreditTech Solutions Pvt Ltd.
                      <br />
                      J-602,6th Floor, J Tower, World Trade Centre,
                      <br /> Ring Rd, Nauroji Nagar, New Delhi, Delhi 110029
                    </p>
					
                    <button className="btnreglandinghome4" onClick={clicked}>
                      Send
                    </button>
                  </div></div>
                </div>
              </div>
			   </div>
			  
			    <div className="col-lg-4">
                    <div className="col contactparagraph">
						<div style={{}}>
						  <p className="becomediv">
							Become an
							<br />
							<span className="alphaheading">
							  ALPHA
							  <span
								style={{ color: "#000000", }}
								className="moneyheading"
							  >
								MONEY
							  </span>
							</span>
							<span className="becomediv3"> Partner</span>
						  </p>
						  <p className="becomediv2">
							Explore various collaboration opportunities!
						  </p>
						  <ul className="becomediv1">
							<li>Channel Partner</li>
							<li>Direct Sales Associate</li>
							<li>Wealth Manager</li>
							<li>NBFC/Bank</li>
						  </ul>

						  <button className=" btnreglandinghome3">
							Become A Partner{" "}
						  </button>
						  <div style={{ marginTop: "-8rem", marginBottom: "0rem" }}>
							<img src={bgalpha} alt="p2p_landing" className="alphalogocs" />
						  </div>
						</div>
                    </div>
					</div>
            </div>
          </div>
        </div>
      
     
	  <div className="container">
	  <div className="row">
        <div className="mapstyle mt-5">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14031.062525470255!2d77.0980395!3d28.4564806!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x578a3c6f0c41db7!2sAlpha%20Money!5e0!3m2!1sen!2sin!4v1667814984962!5m2!1sen!2sin"
            max-width="1300"
			width="100%"
            height="450"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            className="mapstyle1"
          ></iframe>
        </div>
      </div>
	  </div>
	 
    </div>
  );
}

export default ContactUsMobile;
