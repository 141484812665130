import React, { useEffect, useState } from "react";
import icon from ".././../public/assest/img/alphalogowhitebgc.svg";
import { Toast, ToastContainer } from "react-bootstrap";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";
import {ActionCreators} from '../../redux/actions'
import Loader from "../loader";
import DownloadApp from "../DownloadApp";
import { Helmet } from "react-helmet"; 

const queryData = [
  {
      id: '1',
      name: 'Product Info'
  },
  {
      id: '2',
      name: 'Feedback or Suggestions'
  },
  {
      id: '3',
      name: 'Existing User Help'
  },
  {
      id: '4',
      name: 'Partnerships'
  },
  {
      id: '5',
      name: 'Complaint'
  }
];

const ContactUs = (props) => {

  const contactState = useSelector((state) => state.contactState)
  const [showAlert, setShowAlert] = useState(false);
  const [formData, setFormData] = useState({
      name: '',
      email: '',
      mobile: '',
      reason: '',
      message: ''
  });
  const [status, setStatus] = useState(null)
  const [message, setMessage] = useState(null)

  useEffect(() => {
    if (contactState?.contactEnquiry?.data?.status === true) {
        setStatus('success')
        setMessage('Your Details Sent Successfully')
        setShowAlert(true);
        setFormData({})
    } else {
        setStatus('danger')
        setMessage('Your Details Submission Failed')
    }
  }, [contactState?.contactEnquiry])

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'mobile') {
        const truncatedValue = value.slice(0, 10);
        setFormData({ ...formData, [name]: truncatedValue });
    } else {
        setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = (e) => {
    if (!formData.name || !formData.email || !formData.mobile || !formData.reason || !formData.message) {
        setStatus('danger')
        setMessage('Please fill in all required fields')
        setShowAlert(true);
        return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
        setStatus('danger')
        setMessage('Please enter a valid email address');
        setShowAlert(true);
        return;
    }
    let contactData = {
        name: formData.name,
        email: formData.email,
        mobile: formData.mobile,
        reason: formData.reason,
        message: formData.message,
    }
    e.preventDefault();
    props.actions.postEnquiryAction(contactData)
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Contact Us | AlphaMoney</title>
        <meta name="description" content="If you have any queries or suggestions, drop us a message. We love to chat!" />
        <meta name="keywords" content="P2P Lending India, Invest in P2P Lending, Online Investment Platform, Fintech Lending Solutions, RBI Registered NBFC, Secure Investment Options, Lending and Borrowing Online" />
      </Helmet>
      <div>
        {(contactState?.contactEnquiry?.loading) &&
        <Loader />
        }
        <div className="container">
          <div className="topContactTitle">
            <h2 className="contactushaeding">Contact Us</h2>
            <p className="contactusparagraph">
              If you have any queries or suggestions, drop us a
            
                <br />
                message. We love to chat!
        
            </p>
        </div>
        <div className="">
          <div className="row contact_row">
            <div className="col-lg-8">
              <div className="contatccolumnfirst">
                <div className="row">
                  <div className="col-lg-6 prelative">
                    <div className="firstcolcontactus ">
                      <h4 className="contactformfield1 ">Contact Us</h4>
                      <p className="contactusparagraph deskNone">
                        If you have any queries or suggestions, drop us amessage. We
                        love to chat!
                      </p>
                      <p className="contactformfield2 mobNone">
                        Feel free to contact us{" "}
                      </p>
                      <div className="mobileCotactinfo deskNone">
                        <p className="topara">To,</p>
                        <div className="links_contact">
                          <a href="mailto:support@alphamoney.in" >
                            support@alphamoney.in
                          </a>
                          <a href="tel:+91 9319689808">+91 9319689808</a>
                        </div>
                        <p className="address">
                          CreditTech Solutions Pvt Ltd.
                          <br />
                          <span>J-602,6th Floor, J Tower, World Trade Centre,</span>
                          <br /> Ring Rd, Nauroji Nagar, New Delhi, Delhi 110029
                        </p>
                      </div>
                      <div className="form-group contactformfield3 ">
                        <input
                          name="name"
                          value={formData.name ?? ''}
                          type="text"
                          className="form-control inputclass"
                          id="exampleFormControlInput1"
                          placeholder="Name*"
                          onChange={handleChange}
                        />
                        <br />
                        <input
                          name="email"
                          value={formData.email ?? ''}
                          type="email"
                          className="form-control inputclass"
                          id="exampleFormControlInput1"
                          placeholder="Email*"
                          onChange={handleChange}
                        />
                        <br />
                        <input
                          name="mobile"
                          value={formData.mobile ?? ''}
                          type="number"
                          className="form-control inputclass"
                          id="exampleFormControlInput1"
                          placeholder="Contact Number*"
                          onChange={handleChange}
                        />
                        <br />
                        <select
                          className="custom-select custom-select1 inputclass optionreason "
                          name="reason"
                          value={formData.reason ?? ''}
                          id="inputGroupSelect01"
                          placeholder=""
                          onChange={handleChange}
                        >
                          <option value="" className="optioncssstyle" disabled>Reason for Contacting*</option>
                          {queryData?.map((item) => (
                                <option className="optioncssstyle" key={item.id} value={item.name}>{item.name}</option>
                            ))}
                        </select>
                        <br />
                        <br />
                        <input
                          name="message"
                          value={formData.message ?? ''}
                          type="text"
                          className="form-control inputclass"
                          id="exampleFormControlInput1"
                          placeholder="Message*"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="vl"></div>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="alignaddress">
                      <img src={icon} alt="Images" />

                      <p className="topara">To,</p>
                      <div className="links_contact">
                        <a href="mailto:support@alphamoney.in" >
                          support@alphamoney.in
                        </a>
                        <a href="tel:+91 9319689808">+91 9319689808</a>
                      </div>
                      
                      <p className="address">
                        CreditTech Solutions Pvt Ltd.
                        <br />
                        J-602,6th Floor, J Tower, World Trade Centre,
                        <br /> Ring Rd, Nauroji Nagar, New Delhi, Delhi 110029
                      </p>

                      <button className="btn_green mt-3" onClick={handleSubmit}>
                        Send
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="contactparagraph">
                <div style={{}}>
                  <p className="becomediv">
                    Become an
                    <br />
                    <span className="alphaheading">
                      ALPHA
                      <span style={{ color: "#000000" }} className="moneyheading">
                        MONEY
                      </span>
                    </span>
                    <span className="becomediv3"> Partner</span>
                  </p>
                  <p className="becomediv2">
                    Explore various collaboration opportunities!
                  </p>
                  <ul className="becomediv1">
                    <li>Channel Partner</li>
                    <li>Direct Sales Associate</li>
                    <li>Wealth Manager</li>
                    <li>NBFC/Bank</li>
                  </ul>

                  <button className=" btnreglandinghome3">Become A Partner </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        </div>
        
      
        <div className="container">
        <div className="row">
            <div className="mapstyle mt-5">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14031.062525470255!2d77.0980395!3d28.4564806!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x578a3c6f0c41db7!2sAlpha%20Money!5e0!3m2!1sen!2sin!4v1667814984962!5m2!1sen!2sin"
                max-width="1300"
          width="100%"
                height="450"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                className="mapstyle1"
              ></iframe>
            </div>
          </div>
        </div>
    
        <div className="container">
          <div className="help_section">
            <h2 className="help_heading">Need Help?</h2>
            <p className="contactusparagraph">
              Checkout some of our readily available resources.
            </p>
            <div className="col-sm-12 col-lg-12">
              <div className="btnmaps text-center">
              <a href="/FAQ" style={{textDecoration: 'none'}}><button className="btnreglandinghome1">FAQs</button> </a>
                <button className="btnloginlanding2"> Demos </button>
              </div>
            </div>
          </div>
        </div>
        <DownloadApp />
        <ToastContainer className='p-3 position_fixed' position={'top-end'} >
            <Toast onClose={() => setShowAlert(false)} show={showAlert} delay={3000} autohide bg={status} className="toast_slide">
                <Toast.Body>{message}</Toast.Body>
            </Toast>
        </ToastContainer>
      </div>
    </React.Fragment>
  );
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
})

export default connect(null, mapDispatchToProps)(ContactUs)
