import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Investor from '../src/pages/investor/Investor'
import Borrower from '../src/pages/borrower/Borrower'
import Header from "./elements/Header";
import Landing from './pages/landing_page/landing_Index'
import FairPractice from "./elements/footercontent/fair_practice";
import GrievancePolicy from "./elements/footercontent/grievance_policy";
import CreditAssesmentPolicy from "./elements/footercontent/credit_assesment_policy";
import CancellationPolicy from "./elements/footercontent/cancellation_policy";
import Aggrements from "./elements/footercontent/aggrements";
import PrivacyPolicy from "./elements/footercontent/privacy_policy";
import TermsOfUse from "./elements/footercontent/terms_of_use";
import AboutUs from "./pages/AboutUs";
import LenderRegisterationAggrement from "./elements/footercontent/lender_registeration_aggrement";
import BorrowRegisterationAggrement from "./elements/footercontent/borrow_registeration_aggrement";
import LoanAggrement from "./elements/footercontent/loan_aggrement";
import Faq from "./pages/FAQ";
import ContactUs from "./elements/footercontent/contact_us";
import Blog from "./elements/footercontent/blog";
import Career from "./pages/Career";
import Demonstration from "./elements/footercontent/demonstrationborrower";
import Demostrationinvestor from "./elements/footercontent/demostrationinvestor";
import FaqI from "./pages/FAQI";
import FaqB from "./pages/FAQB";
import RecoveryCollectionPolicy from "./elements/footercontent/recovery-collection-policy";
import BusinessModel from './elements/footercontent/business-model'
import FaqMobile from "./pages/FAQ_Mobile";
import ContactUsMobile from "./elements/footercontent/contact_us_mobile";
import AboutUsMobile from "./pages/AboutUs_Mobile";
import PrivacyPolicyMobile from "./elements/footercontent/privacy_policy_mobile";
import TermsOfUseMobile from "./elements/footercontent/terms_of_use_mobile";

function AppRoutes() {
    return (
        <>
        <Router>
            <Routes>
            <Route path="demoborrower" element={<Demonstration/>} />
            <Route path="demoinvestor" element={<Demostrationinvestor/>}/>
            <Route path="/blog" element={<Blog/>}/>
            <Route path="/contactus" element={<ContactUs/>}/>
            <Route path="/" element={<Landing />} />
            <Route path="/investor" element={<Investor />} />
            <Route path="/borrower" element={<Borrower />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/about-us-mobile" element={<AboutUsMobile />} />
            <Route path="/contactus_mobile" element={<ContactUsMobile/>}/>
            <Route path="/terms-of-use-mobile" element={<TermsOfUseMobile />} />
            <Route path="/privacy-policy-mobile" element={<PrivacyPolicyMobile />} />
            <Route path="/FAQ_Mobile" element={<FaqMobile/>} />
            <Route path="/fair-practices-code" element={<FairPractice />} />
            <Route path="/FAQ" element={<Faq/>} />
            <Route path="/Faq/Borrower" element={<FaqB/>} />
            <Route path="Faq/Investor" element={<FaqI/>}/>
            <Route path="/grievance-redressal-policy" element={<GrievancePolicy />} />
            <Route path="/credit-assement-policy" element={<CreditAssesmentPolicy />} />
            <Route path="/cancellation-policy" element={<CancellationPolicy />} />
            <Route path="/sample-aggrements" element={<Aggrements />} />
            <Route path="/recovery-collection-policy" element={<RecoveryCollectionPolicy />} />
            <Route path="/business-model" element={<BusinessModel />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-use" element={<TermsOfUse />} />
            <Route path="/header" element={<Header />} />
            <Route path="/aggrements/borrowaggrement" element={<BorrowRegisterationAggrement />} />
            <Route path="/aggrements/loanaggrement" element={<LoanAggrement />} />
            <Route path="/aggrements/lenderaggrement" element={<LenderRegisterationAggrement />} />
            <Route path="/career" element={<Career />} />
            </Routes>
        </Router>
        </>
    )
}

export default AppRoutes;