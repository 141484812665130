import React, { useState } from "react";
import { useEffect } from 'react';
import ".././../public/assest/css/borrower.css";
import { PieChart } from 'react-minimal-pie-chart';
import DownloadApp from "../../elements/DownloadApp";
import EnquireButton from "../../elements/EnquireButton";
import { URL } from "../../utility/Constant";
import { Helmet } from "react-helmet"; 

let checkIn = true;
// const dataMock = [
//     { title: 'Two', value: 10, color: '#BFE4E3' },
//     { title: 'One', value: 15, color: '#004334' },
//   ];
function Borrower() {
    let [checkData , setcheckData] = useState(true)
    let [loanAmount , setloanAmount] = useState(1000);
    let [BLRepaymentAmount , setBLRepaymentAmount] = useState(1000.99);
    let [BLRepaymentInterestAmount , setBLRepaymentInterestAmount] = useState(115.07);
    let [PLRepaymentInterestAmount , setPLRepaymentInterestAmount] = useState(1953.7);
    let [EMI , setEMI] = useState(3695.37);
    let [tenure , setTenure] = useState(3);
    let [interest , setInterest] = useState(12);
   

    const goTopath = (path) => {
      window.open(path, '_blank');
    }

    useEffect(() => {
    document.body.classList.add('borrow_page')
    return () => {
      document.body.classList.remove('borrow_page')
    }
  }, []);

  // useEffect(() => {
  //   document.head.innerHTML+=`
  //   <meta name='description' content='Financial assistance when you need it'/>
  //   <meta name='keywords' content='
  //         Alphamoney Borrow
  //   '/>
  //   `
  // }, []);
  window.addEventListener('scroll', function() {
    if (window.scrollY > 50) {
        document.querySelector('.bgColorBorrower')
        .classList.add('sticky-visible');
    } else {
      document.querySelector('.bgColorBorrower')
        .classList.remove('sticky-visible');
      }
  });

	window.addEventListener('scroll', function() {
		  if (window.scrollY > 1750) {
			  document.querySelector('.powerUpeffect')?.classList?.add('square-transition');
		  } 
		  else {
			document.querySelector('.powerUpeffect')?.classList?.remove('square-transition');
			}
			
		});
	
  // const goTopath = (path) => {
  //   window.open(path, "_blank");
  // };
  const handleChange = (e) => {
    if(checkIn){
        setcheckData(false)
        checkIn = false;
        setloanAmount(30000)
        setTenure(3)
        setInterest(12)
        calculateEmi(30000, 3, 12)
    }else {
        setcheckData(true)
        checkIn = true;
        setloanAmount(35000)
        setTenure(3)
        setInterest(12)
        calculateEmiPerDay(1000, 3, 12)
    }
  }

let slideRange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if(checkIn){
        if(name === 'loanAmount'){
            setloanAmount(parseInt(value))
            calculateEmiPerDay(value, tenure, interest)
        }
        if(name === 'tenure'){
            setTenure(parseInt(value))
            calculateEmiPerDay(loanAmount, value, interest)
        }
        if(name === 'interest'){
            setInterest(parseInt(value))
            calculateEmiPerDay(loanAmount, tenure, value)
        }
    } else {
        if(name === 'loanAmount'){
            setloanAmount(parseInt(value))
            calculateEmi(value, tenure, interest)
        }
        if(name === 'tenure'){
            setTenure(parseInt(value))
            calculateEmi(loanAmount, value, interest)
        }
        if(name === 'interest'){
            setInterest(parseInt(value))
            calculateEmi(loanAmount, tenure, value)
        }
    }
   
}
const calculateEmiPerDay = (principal,tenure, interestrate) => {
    let totlaPayableInterest = (((parseInt(interestrate) / 365) * parseInt(principal))/100) * parseInt(tenure);
    let totalAmt = totlaPayableInterest + parseInt(principal)
    setBLRepaymentAmount(totalAmt.toFixed(2))
    setBLRepaymentInterestAmount(totlaPayableInterest.toFixed(2))
    return {totalInterest : totlaPayableInterest.toFixed(2), totalAmount: totalAmt.toFixed(2) };
}
const calculateEmi = (principal,tenure, interestrate) => {
    var interest = parseInt(interestrate) / 1200;
    var emi = Math.round(parseInt(principal) * interest / (1 - (Math.pow(1 / (1 + interest), parseInt(tenure)))) * 100) / 100;
    var totalPayable = Math.round((emi * parseInt(tenure)) * 100) / 100;
    var interestPayable = Math.round((totalPayable * 1 - parseInt(principal) * 1) * 100) / 100;
    setPLRepaymentInterestAmount(interestPayable)
    setEMI(emi)
    return {emi : emi, totalPayable : totalPayable, interestPayable : interestPayable, interestRate : interest };
}

  return (
    <React.Fragment>
      <Helmet>
        <title>Instant Loan Disbursal with Low Interest Rates | AlphaMoney</title>
        <meta name="description" content="Borrow with AlphaMoney at Low Interest Rates , Quick Disbursal , No Hidden Fees , Flexible Payment Options , Complete Digital Journey." />
        <meta name="keywords" content="Borrow AlphaMoney at Low Interest Rates , Quick Disbursal , No Hidden Fees , Flexible Payment Options , Complete Digital Journey" />
      </Helmet>
    <section className="bgColorBorrower main_banner">
      <div className="container">
        <div className="row">
          <div className="col-lg-7 col-md-7">
            <div className="main_banner_head">
              <h1 className="banner_heading color_borrower">
                Get Quick Loans Starting <br /> At
                <span className="font-bold "> 12% Per Annum</span>
              </h1>
              <p className="banner_sub_heading mt-3 mb-4">
                Get collateral-free, quick and flexible loans at attractive rates
                for all your financial needs. Getting quality credit has never
                been this easy.
              </p>
              <div className="row">
                <div className="col-sm-7 checkbox_with_text_div">
                  <div className="checkbox_with_text_shadow banner_text">
                    <img
                      alt="Borrower"
                      src={`${URL.BASE_URL}Borrower/borrow_square-tick.png`}
                    />
                    <span className="checkboxTxt">
                       100% Digital Process
                    </span>
                  </div>
                  <div className="checkbox_with_text banner_text">
                    <img
                      alt="Borrower"
                      src={`${URL.BASE_URL}Borrower/borrow_square-tick.png`}
                    />
                    <span className="checkboxTxt">
                       Fast Approval & Disbursal
                    </span>
                  </div>
                  <div className="checkbox_with_text banner_text">
                    <img
                      alt="Borrower"
                      src={`${URL.BASE_URL}Borrower/borrow_square-tick.png`}
                    />
                    <span className="checkboxTxt">
                      Flexible Tenure Options
                    </span>
                  </div>
                </div>
              </div>
  
              <button
                type="button"
                onClick={() => goTopath("https://app.alphamoney.in")}
                className="mt-3 btn_green_outline "
              >
                Apply Now
              </button>

              <div className="DesktopViewHeading">
                <p className="link_text">Looking for help to get started?</p>
                <a href="/contactus" className="link-dark getInTouch">
                  <b>Get in touch </b>
                  <img alt="rightArrow" src={`${URL.BASE_URL}Borrower/rightArrow.png`} className="img-fluid" />
                </a>
                
              </div>
            </div>

            {/* <div className="inves_first_section_head">
              <div className="mobileViewHeading">
                <div className="row mt-3 brand_logo_divinv">
                  <div className="col-2">
                    <img
                      className="img-fluid rbi_logo4Desktop rbi_logo_inv"
                      src={RBI}
                    />
                    <img
                      src={RBI4M}
                      className="rbi-logo rbi_logo4mobile float-start"
                    />
                  </div>
                  <div className="col-10 rbilogodiv">
                    <p className="link_text rbilogodivtext">
                      Looking for help to get started?
                    </p>
                    <a
                      href="/contactus"
                      className="link-dark rbilogodivgetintouch"
                    >
                      <b>Get in touch </b>
                      <img src={rightArrow} className="img-fluid" />
                    </a>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
  
          <div className="col-lg-5 col-md-5 relative">
            <div className="banner_image"> 
              <img className="boy_01 " alt="Banner App" src={`${URL.BASE_URL}Borrower/banner-chart.png`} />
              <img className="banner_mob " alt="Banner App" src={`${URL.BASE_URL}Borrower/banner-mob-borower.png`} />
            </div>
          </div>
        </div>
        <div className="row brand_logo_div">
          <div className="col-sm-12 col-lg-12">
            <div className="partners_logos">
              <img
                className="img-fluid rbi_logo_invbr "
                alt="RBI"
                src={`${URL.BASE_URL}Home+Page/rbi_logo.gif`}
              />
              <img
                className="img-fluid partners_logo EquifaxLogo"
                alt="EquifaxLogo"
                src={`${URL.BASE_URL}Home+Page/EquifaxLogo.png`}
              />
              <img
                className="img-fluid partners_logo yesBankLogo"
                alt="yesBankLogo"
                src={`${URL.BASE_URL}Home+Page/yesbank_logo.svg`}
              />
              <img
                className="img-fluid partners_logo IDBILogo mt-1"
                alt="IDBILogo"
                src={`${URL.BASE_URL}Home+Page/idbi_logo.png`}
              />
              <img
                className="img-fluid partners_logo karzaLogo mt-1"
                alt="karzaLogo"
                src={`${URL.BASE_URL}Home+Page/karza_logo.svg`}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <EnquireButton />
    {/* second section  */}
    <section className="common_space">
      <div className="container">
        <h2 className="section_heading">
          <span className="section_heading_bold_borrower">Hassle-Free</span>{" "}
          Credit
          <br /> With Next-Gen Lending
        </h2>
        <div className="row">
          <div className="col-sm-6">
            <div className="second_section_logo">
              <img className="img-fluid" alt="GreenRuppe" src={`${URL.BASE_URL}Borrower/Green-Ruppe.svg`} />
              <h4 className="second_section_sub_heading_borrow">Quick Loans</h4>
              <p className="paddingRightWithYourMoney">
                {" "}
                Quick approval and disbursal of loans with minimal documentation
                directly into your bank account.
              </p>
            </div>
          </div>
          <div className="col-sm-6 ">
            <div className="second_section_logo">
              <img className="img-fluid" alt="BlueHand" src={`${URL.BASE_URL}Borrower/Blue-Hand.svg`} />
              <h4 className="second_section_sub_heading_borrow">Attractive Rates</h4>
              <p className="paddingRightWithYourMoney">
                Whether you are borrowing for a crisis or to fund your dream project
                - Quit paying colossal interest rates.
              </p>
            </div>
          </div>
          <div className="col-sm-6 ">
            <div className="second_section_logo">
              <img className="img-fluid" alt="StopWatch" src={`${URL.BASE_URL}Borrower/Yellow-Stop-Watch.svg`} />
              <h4 className="second_section_sub_heading_borrow">Flexible Tenures</h4>
              <p className="paddingRightWithYourMoney">
                Pay at your pace with flexible repayment cycles, ranging from 20
                days to 12 months, so you don’t have to worry about missing a
                deadline.{" "}
              </p>
            </div>
          </div>
          <div className="col-sm-6 ">
            <div className="second_section_logo">
              <img className="img-fluid" alt="RedHeart" src={`${URL.BASE_URL}Borrower/Read-Heart.svg`} />
              <h4 className="second_section_sub_heading_borrow">No Collateral </h4>
              <p className="paddingRightWithYourMoney">
                Stress-free borrowing with collateral free loans! What’s more - our
                minimal charges maximise your savings.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* second section  */}
  
    {/* third section  */}
    <section className="common_space">
      <div className="container">
        <div className="row">
            <h2 className="section_heading">
              Apply & <span className="section_heading_bold_borrower">Track</span> On The
              Go
            </h2>
            <div className="col-sm-3 trackOuterBox">
              <div className="box1">
                <p className="num_1">1</p>
                <p className="text">
                  Real-time loan & <br />
                  EMI tracking
                </p>
              </div>
              <div className="box2">
                <p className="num_2">2</p>
                <p className="text">
                  100% digital <br />
                  process
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 text-center relative">
              <div className="box1 realTimeBox1">
                <p className="num_1">1</p>
                <p className="text">
                  Real-time loan & <br />
                  EMI tracking
                </p>
              </div>
              <div className="box2 digitalProcessBox2">
                <p className="num_2">3</p>
                <p className="text">
                  Attractive <br />
                  interest rates{" "}
                </p>
              </div>
              <img
                className="img-fluid"
                alt="p2p_landing"
                src={`${URL.BASE_URL}Borrower/borrower_mobile.png`}
                width="400px"
              />
              <div className="col-sm-12 processProtocol">
                <div className="box3 interestBox3">
                  <p className="num_3">2</p>
                  <p className="text">
                    100% digital <br />
                    process
                  </p>
                </div>
                <div className="box4 privacyBox4">
                  <p className="num_4">4</p>
                  <p className="text">
                    Robust safety & <br />
                    privacy protocols
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-3 interestBox3Outer">
              <div className="box3 interestBox3">
                <p className="num_3">3</p>
                <p className="text">
                Attractive  <br />
                interest rates
                </p>
              </div>
              <div className="box4 privacyBox4">
                <p className="num_4">4</p>
                <p className="text">
                  Robust safety & <br />
                  privacy protocols
                </p>
              </div>
            </div>
        </div>
      </div>
    </section>
    {/* third section  */}
  
    {/* forth section  */}
    <section className="common_space">
      <div className="container third_section_borrow">
        <div className="row">
          <div className="col-lg-8 col-md-8 col-sm-12">
            <div className="promotion_banner">
              <h2 className="promotion_banner_heading">
                Multi-Purpose Loans That <br />
                <span className="promotion_banner_heading_bold">Empower</span> You.
              </h2>
    
              <p className="promotion_banner_sub_heading">
                ALPHAMONEY allows you to borrow for a slew reasons. You can avail
                fast and quality credit to:
              </p>
              <div className="row">
                <div className="col-lg-12 postRelative">
                  <ul className="multipurposeLoanList">
                    <li>
                      Fund your dream{" "}
                      <span style={{ fontWeight: "600" }}>home</span>
                    </li>
                    <li>
                      Subsidize your{" "}
                      <span style={{ fontWeight: "600" }}>education</span>
                    </li>
                    <li>
                      Buy your next{" "}
                      <span style={{ fontWeight: "600" }}>vehicle</span>
                    </li>
                    <li>
                      Pay for a medical{" "}
                      <span style={{ fontWeight: "600" }}>emergency</span>
                    </li>
                    <li>
                      Start a <span style={{ fontWeight: "600" }}>business</span>
                    </li>
                    <li>
                      Live your{" "}
                      <span style={{ fontWeight: "600" }}>ideal life</span>
                    </li>
                  </ul>
                  <img
                    className="img-fluid girlwithCircle girlDesktop multipurposeLoanListGirlOnMobileView"
                    alt="p2p_landing"
                    src={`${URL.BASE_URL}Borrower/borrow-pink-women.svg`}
                  />
                </div>
              </div>
              <button
                type="button"
                className="btn btn-light third_section_button third_section_button_borrower third_section_button_borrowerOnMobile getStarted"
              >
                Get Started
              </button>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6 mobileDataNone">
            <img
              className="img-fluid girlwithCircle"
              alt="GirlWithCircle"
              src={`${URL.BASE_URL}Borrower/borrow-pink-women.svg`}
            />
          </div>
        </div>
      </div>
    </section>
    {/* forth section  */}
  
    {/* fifth section  */}
    <section className="common_space">
      <div className="container">
        <h2 className="section_heading">
          Our <span className="section_heading_bold_borrower">Offerings</span>
        </h2>
        <div className="row offerings_row">
          <div className="col-md-4">
            <div className="loan_card">
                <div className="loan_details">
                  <h3>Personal Loan</h3>
                  <p>from</p>
                  <h4><span>12% </span>p.a.</h4>
                </div>
                <ul className="loan_benifits">
                  <li><img src={`${URL.BASE_URL}Investor/tick.svg`} alt="icon" /> Loan from ₹30,000-₹1,00,000</li>
                  <li><img src={`${URL.BASE_URL}Investor/tick.svg`} alt="icon" /> Tenure from 3-12 Months </li>
                  <li><img src={`${URL.BASE_URL}Investor/tick.svg`} alt="icon" /> Get Loan from 12% p.a.</li>
                  <li><img src={`${URL.BASE_URL}Investor/tick.svg`} alt="icon" /> Easy Repayment by EMIs</li>
                </ul>
            </div>
          </div>
          <div className="col-md-4">
            <div className="loan_card highlight_card">
              <h6>Our special</h6>
              <div className="loan_details">
                <h3>Insta Loan</h3>
                <p>@</p>
                <h4><span>0.2% </span>per day</h4>
              </div>
              <ul className="loan_benifits">
                <li><img src={`${URL.BASE_URL}Investor/tick.svg`} alt="icon" /> Loan from ₹5,000-₹10,000</li>
                <li><img src={`${URL.BASE_URL}Investor/tick.svg`} alt="icon" /> Tenure from 7-60 Days </li>
                <li><img src={`${URL.BASE_URL}Investor/tick.svg`} alt="icon" /> Get Loan @ 0.2% per day</li>
                <li><img src={`${URL.BASE_URL}Investor/tick.svg`} alt="icon" /> One-time Repay on Maturity</li>
              </ul>
            </div>
          </div>
          <div className="col-md-4">
            <div className="loan_card">
                <div className="loan_details">
                  <h3>Bullet Loan</h3>
                  <p>from</p>
                  <h4><span>12% </span>p.a.</h4>
                </div>
                <ul className="loan_benifits">
                  <li><img src={`${URL.BASE_URL}Investor/tick.svg`} alt="icon" /> Loan from ₹10,000-₹50,000</li>
                  <li><img src={`${URL.BASE_URL}Investor/tick.svg`} alt="icon" /> Tenure from 3-365 Days </li>
                  <li><img src={`${URL.BASE_URL}Investor/tick.svg`} alt="icon" /> Get Loan from 12% p.a.</li>
                  <li><img src={`${URL.BASE_URL}Investor/tick.svg`} alt="icon" /> One-time Repay On Maturity</li>
                </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* fifth section  */}
  
    {/* six section */}
    <section className="calculator_section bg_app">
      <div className="container">
        <div className="row">
          <h2 className="section_heading">
            Light on your{" "}
            <span className="section_heading_bold_borrower">Pockets</span>{" "}
          </h2>
          <div className="col-lg-6">
            <div>
              <span className={`sliderText ${!checkIn && "activeText"}`}>
                <strong> Personal Loan &nbsp;</strong>
                <label className="switch">
                  <input
                    type="checkbox"
                    name="check"
                    value={checkIn}
                    checked={checkIn}
                    onChange={(e) => handleChange(e)}
                  />
                  <span className="slider round"></span>
                </label>
              </span>
              <span className={`sliderText ${checkIn && "activeText"}`}>
                <strong> &nbsp; Bullet Loan </strong>
              </span>
            </div>
            <div className="mt-3">
              <div className="mt-4">
                <span className="textSize">Loan Amount</span>
                <div className="mt-2">
                  <input
                    className="inputAmount inputAmount2"
                    type={"text"}
                    value={loanAmount}
                    readOnly
                    disabled
                  />
                  <span className="spanWithInput">₹</span>
                </div>
                <div className="mt-1">
                  <input
                    type="range"
                    className="rangeInput"
                    name="loanAmount"
                    value={loanAmount}
                    min={checkIn ? 1000 : 30000}
                    max={checkIn ? 50000 : 100000}
                    step={1000}
                    onChange={(e) => slideRange(e)}
                  />
                </div>
              </div>
              <div className="mt-4">
                <span className="textSize">Rate of Interest (Per Annum)</span>
                <div className="mt-2">
                  <input
                    className="inputAmount mt-2"
                    type={"text"}
                    value={interest}
                    readOnly
                    disabled
                  />
                  <span className="spanWithInput">%</span>
                </div>
                <div className="mt-1">
                  <input
                    type="range"
                    className="rangeInput"
                    name="interest"
                    value={interest}
                    min={12}
                    max={36}
                    step={1}
                    onChange={(e) => slideRange(e)}
                  />
                </div>
              </div>
              <div className="mt-4">
                <span className="textSize">Loan Tenure</span>
                <div className="mt-2">
                  <input
                    className="inputAmount mt-2"
                    type={"text"}
                    value={tenure}
                    readOnly
                    disabled
                  />
                  <span className="spanWithInput">{checkIn ? "Days" : "Months"}</span>
                </div>
                <div className="mt-1">
                  <input
                    type="range"
                    className="rangeInput"
                    name="tenure"
                    value={tenure}
                    min={checkIn ? 3 : 3}
                    max={checkIn ? 365 : 12}
                    step={1}
                    onChange={(e) => slideRange(e)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 ">
            <div className="row mobileRow">
              <div className="col-6">
                <span id="pAmount"></span>
                <span className="loan_amt"> Principle Amount </span>
              </div>
              <div className="col-6">
                <span id="iAmount"></span>
                <span className="loan_amt"> Interest Amount </span>
              </div>
            </div>
            <div className="pieChart">
              <PieChart
                data={[
                  {
                    value: checkIn
                      ? parseInt(BLRepaymentInterestAmount)
                      : parseInt(PLRepaymentInterestAmount),
                    color: "#BFE4E3",
                  },
                  {
                    value: checkIn
                      ? parseInt(BLRepaymentAmount)
                      : parseInt(loanAmount) + parseInt(PLRepaymentInterestAmount),
                    color: "#004334",
                  },
                ]}
                labelStyle={(index) => ({
                  fontSize: "5px",
                  fontFamily: "sans-serif",
                })}
                viewBoxSize={["150", "100"]}
                lineWidth={"16"}
                radius={42}
                animate={true}
                animationDuration={500}
                animationEasing="ease-out"
                //  labelPosition={112}
              />
            </div>
            <div className="row mt-4">
              <div className="col-md-6 col-8">
                <span className="fontSize16 colorGreen">
                  <strong> {checkIn ? "Bullet Repayment " : "EMI"} Amount </strong>
                </span>
              </div>
              <div className="col-md-6 col-4">
                <span className="fontSize16">
                  <strong> ₹ {checkIn ? BLRepaymentAmount : EMI} </strong>
                </span>
              </div>
              <div className="col-md-6 col-8">
                <span className="fontSize16"> Principle Amount </span>
              </div>
              <div className="col-md-6 col-4">
                <span className="fontSize16">
                  {" "}
                  <strong> ₹ {loanAmount.toFixed(2)} </strong>
                </span>
              </div>
              <div className="col-md-6 col-8">
                <span className="fontSize16"> Interest Amount </span>
              </div>
              <div className="col-md-6 col-4">
                <span className="fontSize16">
                  <strong>
                    {" "}
                    ₹{" "}
                    {checkIn
                      ? (BLRepaymentAmount - loanAmount).toFixed(2)
                      : PLRepaymentInterestAmount}{" "}
                  </strong>
                </span>
              </div>
              {!checkIn && (
                <>
                  <div className="col-md-6 col-8">
                    <span className="fontSize18">
                      {" "}
                      Total Payment <br />
                      <span className="fontSize12">
                        (Principal + Interest + Fees & Charges)
                      </span>{" "}
                    </span>
                  </div>
                  <div className="col-md-6 col-4">
                    <span className="fontSize18">
                      <strong>
                        {" "}
                        ₹ {(loanAmount + PLRepaymentInterestAmount).toFixed(2)}{" "}
                      </strong>
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* six section */}
  
    {/* seven section  */}
    <section className="common_space">
      <div className="container">
        <div className="row">
            <h2 className="section_heading">
              As Easy As A{" "}
              <span className="section_heading_bold_borrower">
                {" "}
                <strong> Breeze </strong>{" "}
              </span>
            </h2>
            <div className="eight_sec_upLine">
              <img className="img-fluid " alt="down_line" src={`${URL.BASE_URL}Investor/dash_line_down.svg`} />
            </div>
            <div className="col-sm-4 col-md-4 col-lg-4 ">
              <div className="eight_sec_upLine eight_sec_upLineDeskn">
                <img className="img-fluid " alt="down_line" src={`${URL.BASE_URL}Investor/dash_line_down.svg`} />
              </div>
              <div className="eight_sec_div bgLightGreen registerBox1">
                <div className="row">
                  <div className="col">
                    <p className="eight_sec_01">01</p>
                  </div>
                  <div className="col eight_sec_img_div">
                    <img
                      className="img-fluid eight_sec_img"
                      alt="register_green"
                      src={`${URL.BASE_URL}Borrower/register-green.svg`}
                    />
                  </div>
                </div>
                <div className="col eight_sec_div_text">
                  <p className="eight_sec_div_head">Register</p>
                  <p className="fontSize14">
                    Sign-up. complete KYC process and verify your bank account.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-4 col-md-4 col-lg-4">
              <div className="eight_sec_div bgLightGreen registerBox1">
                <div className="row">
                  <div className="col">
                    <p className="eight_sec_01">02</p>
                  </div>
                  <div className="col eight_sec_img_div">
                    <img
                      className="img-fluid eight_sec_img"
                      alt="borrow_green"
                      src={`${URL.BASE_URL}Borrower/borrow-green.svg`}
                    />
                  </div>
                </div>
                <div className="col eight_sec_div_text">
                  <p className="eight_sec_div_head">Borrow</p>
                  <p className="fontSize14">
                    Submit loan requirements, accept terms and get funds.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-4 col-md-4 col-lg-4 relative">
              <div className="eight_sec_downLine eight_sec_downLineMobile">
                <img className="img-fluid " alt="up_line" src={`${URL.BASE_URL}Investor/dash_line_up.svg`} />
              </div>
              <div className="eight_sec_div bgLightGreen registerBox1">
                <div className="row">
                  <div className="col">
                    <p className="eight_sec_01">03</p>
                  </div>
                  <div className="col eight_sec_img_div">
                    <img
                      className="img-fluid eight_sec_img"
                      alt="re_pay"
                      src={`${URL.BASE_URL}Borrower/re-pay.svg`}
                    />
                  </div>
                </div>
                <div className="col eight_sec_div_text">
                  <p className="eight_sec_div_head">Re-pay</p>
                  <p className="fontSize14">
                    Repay via monthly EMIs or a one-time bullet payment.
                  </p>
                </div>
              </div>
            </div>
            <div className="eight_sec_downLine ">
              <img className="img-fluid " alt="up_line" src={`${URL.BASE_URL}Investor/dash_line_up.svg`} />
            </div>
          </div>
      </div>
    </section>
    {/* seven section  */}
    <DownloadApp />
  </React.Fragment>
  
  );
}

export default Borrower;
