import React from "react";
import { useEffect } from 'react';
import ".././../public/assest/css/landing.css";
import RBI from "../../public/assest/img/rbi_logo.gif";
import DownloadApp from "../../elements/DownloadApp";
import Ratings from "../../public/assest/img/ratings.svg";
import EnquireButton from "../../elements/EnquireButton";
import { URL } from "../../utility/Constant";
import RBILOGO from '../../public/assest/img/rbi-logo-footer.svg'
import { Helmet } from "react-helmet"; 

const Landing_Index = () => {

  const goTopath = (path) => {
    window.location.href = path;
  };
  useEffect(() => {
      document.body.classList?.add('landing_page')
    return () => {
      document.body.classList?.remove('landing_page')
    }
    
  }, []);  
 
 	window.addEventListener('scroll', function() {
      if (window.scrollY > 1700) {
          document.querySelector('.landingTakeCharges')
          ?.classList.add('landingTakeCharges-transition');
      } 
	  else {
        document.querySelector('.landingTakeCharges')
          ?.classList.remove('landingTakeCharges-transition');
        }
		
    });
		setTimeout(() => {
		const boxs = document.getElementById('mobileMenu');
		 boxs.classList.add('landing_PageOnly')
		  return () => {
		  boxs.classList.remove('landing_PageOnly')
		  }
	}, 3000);

  window.addEventListener('scroll', function() {
    if (window.scrollY > 50) {
        document.querySelector('.bgColorHome')
        ?.classList.add('sticky-visible');
    } else {
      document.querySelector('.bgColorHome')
        ?.classList.remove('sticky-visible');
      }
  });
  

  // useEffect(() => {
  //     document.head.innerHTML+=`
  //     <meta name='description' content='Diversify your portfolio for better returns with ALPHAMONEY'/>
  //     <meta name='keywords' content='
  //           Alphamoney Invest
  //     '/>
  //     `
  // }, []);
  return (
    <React.Fragment>
      <Helmet>
        <title>India's Next-Gen P2P Lending Platform | ALPHAMONEY</title>
        <meta name="description" content="RBI Regulated Financial Platform for collateral-free instant Loans and Smart Investments. Explore India's leading peer-to-peer lending and investment platform, connecting borrowers and investors. Start earning competitive returns or applying for Hassle-free personal loans or business loans with ALPHAMONEY today!" />
        <meta name="keywords" content=" RBI-regulated P2P NBC , Collateral-free:, Personal Loans , Business Loans, low interest rates Early Salary loans." />
      </Helmet>
      <div className="">
        <section className="homeBg main_banner">
          <div className="container">
            <div className="row">
              <div className="col-sm-7 col-md-7 col-lg-7 col-xl-7 half_circle">
                <div className="main_banner_head main_home_banner">
                  <h1 className="banner_heading color_banner_home">
                    <span className="font-bold gradient_text">Bringing Lenders </span> and <br/>
                    Borrowers Together
                  </h1>
                  <p className="banner_sub_heading mt-3 mb-4">
                    We provide non market link safe investment & loan opportunities that <br/> help you secure your future
                  </p>
                </div>
                <div className="flex_items hide-xs">
                  <a href="https://apps.apple.com/in/app/alphamoney/id6462969874" target="_blank">
                    <img src={`${URL.BASE_URL}Home+Page/app_store.png`} alt="" />
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=com.creditTech.alphamoney&pli=1" target="_blank">
                    <img src={`${URL.BASE_URL}Home+Page/play_store.png`} alt="" />
                  </a>
                </div>
              </div>
              <div className="col-sm-5 col-md-5 col-lg-5 col-xl-5 relative">
                <div className="banner_image mobile_image">
                  <img className="boy_01 " alt="Banner App" src={`${URL.BASE_URL}Home+Page/homebanner-chart.png`} />
                  <img className="banner_mob " alt="Banner App" src={`${URL.BASE_URL}Home+Page/homebanner-mob.png`} />
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="hide-md visible-xs">
              <div className="flex_logos">
                <a href="https://apps.apple.com/in/app/alphamoney/id6462969874" target="_blank">
                    <img src={`${URL.BASE_URL}Home+Page/app_store.png`} alt="" />
                  </a>
                <a href="https://play.google.com/store/apps/details?id=com.creditTech.alphamoney&pli=1" target="_blank">
                  <img src={`${URL.BASE_URL}Home+Page/play_store.png`} alt="" />
                </a>
              </div>
            </div>
          </div>
        </section>
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <div className="banner_footer">
                <div className="row">
                  <div className="col-md-3 hide-xs">
                    <div className="rbi_logo_image">
                      <div>
                        <img src={RBILOGO} />
                        <img alt="p2p_landing" src={`${URL.BASE_URL}Home+Page/rbi_logo.gif`} className="rbi-logo-home" />
                      </div>
                      <div className="circle_shape">
                        <img alt="p2p_landing" src={`${URL.BASE_URL}Home+Page/shape-circle.png`} />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-9">
                    <div className="footer_banner_content">
                      <div className="flex_banner">
                        {/* <div className="flex_col">
                          <div className="amt_rupee">
                            <img src={`${URL.BASE_URL}Home+Page/rupee.svg`} alt="" />
                          </div>
                          <div>
                            <h6>1,80,00,000</h6>
                            <p>Loan Disbursed</p>
                          </div>
                        </div> */}
                        <div className="customers">
                          <h6>355+</h6>
                          <p>Trusted Customers</p>
                        </div>
                        <div className="ratings">
                          <img src={Ratings} alt="" />
                          <p>On Google</p>
                        </div>
                      </div>
                      <div className="footer_banner_desc hide-xs">
                        <p>ALPHAMONEY is a Reserve Bank of India (RBI) registered Non-Banking Financial Company - Peer to Peer Lending Platform (NBFC-P2P)</p>
                      </div>
                      <div className="footer_banner_desc_mobile hide-md visible-xs">
                        <img alt="p2p_landing" src={`${URL.BASE_URL}Home+Page/rbi_logo.gif`} className="rbi-logo" />
                        <p>ALPHAMONEY is a Reserve Bank of India (RBI) registered Non-Banking Financial Company - Peer to Peer Lending Platform (NBFC-P2P)</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div> 
            </div>
          </div>
        </div>
      </div>
      <EnquireButton />
      <section className="common_space">
        <div className="container">
          <div className="text-center">
            <p className="our_partner ">Our Partners</p>
            <div className="partners_logos_home">
              <img
                alt="yesBankLogo" className="img-fluid partners_logo yesBankLogo"
                src={`${URL.BASE_URL}Home+Page/yesbank_logo.svg`}
              />
              <img alt="EquifaxLogo"
                className="img-fluid partners_logo EquifaxLogo"
                src={`${URL.BASE_URL}Home+Page/EquifaxLogo.png`}
              />
              <img alt="IDBILogo"
                className="img-fluid partners_logo mt-1 IDBILogo"
                src={`${URL.BASE_URL}Home+Page/idbi_logo.png`}
              />
              <img alt="karzaLogo"
                className="img-fluid partners_logo mt-1 karzaLogo"
                src={`${URL.BASE_URL}Home+Page/karza_logo.svg`}
              />
            </div>
          </div>
        </div>
      </section>
      {/* second section  */}
      <section className="common_space">
        <div className="container">
          <h2 className="section_heading_home">
            <span className="section_heading_home_bold_invest">Invest</span> In
            The Leading Alternate-Asset Class
          </h2>
          <p className="section_subheading_home">when we say "leading alternate-asset class," it's like saying that this special kind of asset is the best one to pick.</p>
          <p className="section_subheading_home">It's the one that can make your money grow the most.</p>
          <div className="row invest_row align-center">
            <div className="col-md-6">
              <div className="image_box_lender">
                <img src={`${URL.BASE_URL}Home+Page/invest_home.png`} alt="" />
                <a href="/investor"> Learn More </a>
              </div>
            </div>
            <div className="col-md-6">
              <div className="invest_card_box">
                <div className="invest_card">
                  <img src={`${URL.BASE_URL}Home+Page/item1.svg`} alt="" />
                  <div className="invest_info_card">
                    <h4>Attractive Rates</h4>
                    <p>Say goodbye to traditional asset-classes like FDs and Mutual Funds. Beat the inflation and get interest rates of upto 31% per annum. </p>
                  </div>
                </div>
                <div className="invest_card">
                  <img src={`${URL.BASE_URL}Home+Page/item2.svg`} alt="" />
                  <div className="invest_info_card">
                    <h4>Regular Returns</h4>
                    <p>Get consistent returns based on your preferences. Take advantage of the power of compounding by reinvesting your returns.</p>
                  </div>
                </div>
                <div className="invest_card">
                  <img src={`${URL.BASE_URL}Home+Page/item3.svg`} alt="" />
                  <div className="invest_info_card">
                    <h4>Low Volatility</h4>
                    <p>Digital lending is a non-market linked investment option. Quit relying on volatile markets for your next earnings. </p>
                  </div>
                </div>
                <div className="invest_card">
                  <img src={`${URL.BASE_URL}Home+Page/item4.svg`} alt="" />
                  <div className="invest_info_card">
                    <h4>Risk Mapping</h4>
                    <p>Choose borrowers based on your risk appetite with options for high-risk and high-returns category.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* second section  */}
      {/* third section  */}
      <section className="common_space">
        <div className="container">
          <h2 className="section_heading_home">
            <span className="section_heading_home_bold_borrow">Hassle-Free</span> Loan With Next-Gen Lending
          </h2>
          <div className="row borrow_row align-center">
            <div className="col-md-6 mobile-order">
              <div className="borrow_card_box">
                <div className="invest_card">
                  <img src={`${URL.BASE_URL}Home+Page/item5.svg`} alt="" />
                  <div className="invest_info_card">
                    <h4>Quick Loans</h4>
                    <p>Quick approval and disbursal of loans with minimal documentation directly into your bank account. </p>
                  </div>
                </div>
                <div className="invest_card">
                  <img src={`${URL.BASE_URL}Home+Page/item6.svg`} alt="" />
                  <div className="invest_info_card">
                    <h4>Attractive Rates</h4>
                    <p>Whether you are borrowing for a crisis or to fund your dream project - Quit paying colossal interest rates.</p>
                  </div>
                </div>
                <div className="invest_card">
                  <img src={`${URL.BASE_URL}Home+Page/item7.svg`} alt="" />
                  <div className="invest_info_card">
                    <h4>Flexible Tenures</h4>
                    <p>Pay at your pace with flexible repayment cycles, ranging from 3 days to 12 months.</p>
                  </div>
                </div>
                <div className="invest_card">
                  <img src={`${URL.BASE_URL}Home+Page/item8.svg`} alt="" />
                  <div className="invest_info_card">
                    <h4>No Collateral Security</h4>
                    <p>Stress-free borrowing with collateral free loans! What’s more? - there are No Hidden Charges.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="image_box_lender image_box_borrow">
                <img src={`${URL.BASE_URL}Home+Page/borrow_home.png`} alt="" />
                <a href="/borrower"> Learn More </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* third section  */}
      {/* fourth section  */}
      <section className="common_space">
        <div className="container">
          <div className="bgInvestHome">
            <div className="row">
                <div className="col-md-12 col-lg-6">
                  <div className="promotion_home">
                    <h2><span>03</span> easy steps to start</h2>
                    <div className="stepper">
                      <div className="steps">
                        <h6>1</h6>
                        <h5>Register using mobile number</h5>
                      </div>
                      <div className="steps">
                        <h6>2</h6>
                        <h5>Complete Your KYC</h5>
                      </div>
                      <div className="steps">
                        <h6>3</h6>
                        <h5>Start Invest & Borrow</h5>
                      </div>
                    </div>
                    <button type="button" className="btn_brown">Register Now</button>
                  </div>
                </div>
                <div className="col-md-12 col-lg-6 relative">
                  <div className="invest_image_box">
                    <img src={`${URL.BASE_URL}Home+Page/MobileHome.png`} alt="" />
                  </div>
                </div>
            </div>
          </div>
        </div>
      </section>
      {/* fourth section  */}
      {/* fifth section  */}
      
      {/* fifth section  */}
      <DownloadApp />
    </React.Fragment>
  );
};

export default Landing_Index;
