import React from 'react';

function LoanAggrement(props) {
    return (
        <div className='policy_container'>
          <div className="policy_header">
            <div className="container">
              <h2 className="policy_heading">
                <b>Loan </b> and Agreement
              </h2>
            </div>
          </div>
          <div className='container'>
            <div className="policy_breadcrumb">
              <h3>
                <span><a href="/">Home</a> </span>
                <span> /</span>
                <span><a href='/sample-aggrements'> {" "} Agreement </a></span>
                <span> /</span>
                <span> Loan Agreement</span>
              </h3>
            </div>
            <div className="policy_body">
              <p>THIS LOAN AGREEMENT (“Agreement”) is made at [place] and on [day_date] day of [month_date],[year_date] (“Execution Date”) (“Execution Date”)
                <br />
                BY AND BETWEEN:
                <br />
                Person listed in Schedule I (A), hereinafter referred to as (“the Borrower”), (which expression shall, unless repugnant to the context or meaning thereof, be deemed to mean and include his / her / its / their respective heirs, administrators, executors, legal representatives) of the
                <br />
                FIRST PART.
                <br />
                AND
                <br />
                Person listed in Schedule I (B), hereinafter referred to as (“the Lender”), (which expression shall, unless repugnant to the context or meaning thereof, be deemed to mean and include his / her / its / their respective heirs, administrators, executors, legal representatives) of the
                <br />
                SECOND PART.
                <br />
                The Borrower and the Lender are hereinafter collectively referred to as the “Parties” and individually as the “Party”.
                <br />
                WHEREAS:
                <br />
                Creditech Solutions Private Limited is a private limited company involved in the business of peer to peer lending as non-banking financial company operating through a P2P Platform Creditech which is duly recognized by Reserve Bank of India (RBI).
                <br />
                The Lender seeks to lend an amount not exceeding the aggregate amount specified in Schedule II (“Loan”), through the online peer to peer digital marketplace/ lending and/or borrowing platform of Creditech Solutions Private Limited (“Creditech”) for the purpose of lending the same to the Borrowers through the active facilitation by Creditech.
                <br />
                The Borrower seeks to collaborate with Creditech with an intention of borrowing the Loan, duly specified in Schedule II, from the Lender vide active facilitation from Creditech through its peer to peer lending platform (“P2P Platform”).
                <br />
                The Parties being desirous of recording the terms and conditions under which Lender shall provide the Loan to the Borrower and the mutual rights and obligations of the Parties in respect thereof, have decided to execute this Agreement. NOW THEREFORE, in consideration of the premises and other good and valuable consideration, the receipt and sufficiency of which are hereby acknowledged by the Parties, it is agreed as follows:
              </p>
              <div className="policy_points">
                <h5>THE LOANS</h5>
                <p>
                The Borrower had approached the Lender for a Loan amount as stated in Schedule II on the terms, conditions and the purpose as stated or contained in this Agreement. The Borrower hereby confirms that the funds are for stated purpose herein.
                <br />
                The Lender hereby grants to the Borrower and the Borrower agrees to avail from the Lender, Loan on the terms and conditions contained in this Agreement. The term of Loan (“Tenure”), interest rate (“Interest”) and the schedule of repayment in respect of the Loan shall be as specified in Schedule II or as may be amended in accordance with this Agreement from time to time.
                <br />
                In case the Borrower does not draw down the entire Loan within the period in which the Borrower shall be required to drawdown the Loan in full (“Availability Period”), as more particularly set out in Schedule II, the Borrower shall not be entitled to drawdown of any part of the undisbursed Loan, except with the prior written consent of the Lender, and the Lender may in its discretion consider such part of the undrawn Loan as cancelled.
                <br />
                Subject to the Borrower complying with, and in accordance with the terms and conditions of this Agreement, the Lender shall on the Execution Date make available such Loan to the Borrower by wire transfer.
                </p>
              </div>
              <div className="policy_points">
                <h5>FEES AND CHARGES</h5>
                <p>The Borrower agrees to pay to the Lender the fees and charges as set out in Schedule III. The Borrower hereby authorises the Lender to deduct these amounts together with applicable taxes from the Loan and to pay to the Borrower only the balance amount. The Borrower hereby confirms that irrespective of deduction of such fees, the obligation of the Borrower to repay to the Lender shall be of the entire Loan along with Interest, and other charges together with applicable taxes in terms of this Agreement. The fees paid / deducted by the Lender is to meet the out of pocket expenses and the same is non- refundable / non-adjustable. Alternatively, the Borrower agrees to pay any fees or charges such as Bank charges, pre-payment charge etc., as per Schedule III together with Goods and Services tax and all other statutory taxes and levies as per the terms of this Agreement, as applicable from time to time.</p>
              </div>
              <div className="policy_points">
                <h5>ESCROW ACCOUNT</h5>
                <p>Both the Parties agree to the following related to loan disbursement and repayments:
                  <br />
                  The borrower will receive disbursement from the lender through an escrow account named ‘Creditech – Lenders funding escrow account&#39; as mentioned in
                  Schedule I (C) which is solely used to facilitate the disbursement process to the Borrower. This account is managed by a third party custodian and the company doesn’t have any transaction rights for the account, or earn any interest on balances held in this account. The company is not obliged to pay interest to the lender on any unused amount in the account;
                  <br />
                  The borrower repayments to the lenders will be routed through an escrow account named ‘Creditech – Borrowers repayment escrow account’ as mentioned in Schedule I (C) which is solely used to receive repayments from the borrower. This account is managed by a third party custodian and the company doesn’t have any transaction rights for the account, or earn any interest on balances held in this account. The company is not responsible for any delay in receiving payments from the borrowers or for any delay in distributing these repayments to the lenders;
                  <br />
                  The lender and the borrower authorizes the company to use these account
                  <br />
                  The lender and the borrower shall not close their bank accounts as mentioned in Schedule I (C) without prior intimation to the company;
                  <br />
                  The lender shall transfer loan amount within 3 (three) days of execution of this agreement to the ‘Creditech – Lenders funding escrow account’.
                </p>
              </div>
              <div className="policy_points">
                <p>PRINCIPAL REPAYMENT AND INTEREST PAYMENT SCHEDULE The Borrower shall pay interest on the Loan as per the Interest specified in the Schedule II as amended from time to time and shall be computed and debited to the Loan account as following:
                  <br />
                  On the day of each calendar month/quarter/half year/year as stated in the Schedule;
                  <br />
                  Taking the basis of 365 days a year;
                  <br />
                  On the actual amount outstanding on the last day of the preceding period of one month/quarter/half year/year as stated in the Annexure.
                  <br />
                  Without prejudice to the other obligations of the Borrower under this Agreement, if payment of any amount due to the Lender has not been made in accordance with the terms and conditions contained herein, such defaulted amounts shall carry a default interest (“Default Interest Rate”) as specified in Schedule II. Such Default Interest shall be computed on a daily basis for the period from (and including) the repayment date, until the date of actual payment of such amount.
                  The Borrower acknowledges that the Interest and Default Interest is reasonable. The Borrower further acknowledges that the Default Interest represents a genuine pre- estimates of the loss likely to be incurred by the Lender in the event of non-payment by the Borrower of the aforesaid sums when due as per the Repayment Schedule.
                  <br />
                  In consideration of the Lender extending the Loan, the Borrower shall repay the Loan along with Interest in accordance with the repayment schedule set out in Schedule II (“Repayment Schedule”) of this Agreement. The Borrower hereby confirms having perused, understood that the Interest is applied on the principal set out herein and agreed to the method of computation of instalments and the effective Interest as stipulated in Schedule II.
                  <br />
                  If a payment / repayment under this Agreement is due on a day which is not a Business Day, the due date for such payment shall instead be the immediately succeeding Business Day. Notwithstanding the above, the calculation of Interest for such payment shall be made as if such payment was made on the due date. “Business Day” means a day (other than a Saturday or a Sunday) on which banks are open for general business in Gurgaon and New Delhi.
                  <br />
                  The Borrower shall unconditionally and irrevocably authorize the bank through standing instructions or Electronic Clearing System (ECS) instructions / ACH to debit the Borrower account towards the dues payable to the Lender. The Borrower further agrees to maintain sufficient balance in the account to enable borrower’s bankers to facilitate the debit of the account, and remittance of the amount so debited to the credit of the Lender. Alternatively, where the ECS / ACH facility is not available for the Borrower, the Borrower may issue to the Lender post-dated cheques (PDC) towards repayment of the instalments / dues arising under this Agreement and undertakes that sufficient balance will be made available for honouring the cheques on the due date as and when presented for payment by the Lender on or after the due date.
                </p>
              </div>
              <div className="policy_points">
                <h5>PREPAYMENT</h5>
                <p> The Borrower shall not prepay / foreclose any portion of the outstanding loan amount either in part or full during the Lock-in period (“Lock-in Period”) as set out in Schedule II of this Agreement.
                  <br />
                  The Borrower may exercise the option of pre-payment post Lock-in-period, where applicable, subject to the Borrower complying with the following conditions and at the absolute discretion of the Lender. After the expiry of Lock-in Period as provided in Schedule II hereunder, the Borrower shall give the Lender a notice of [fc_noticeperiod] days intimating his desire to pre-pay the loan and the Lender may accept pre-payment together with pre-
                  payment charges / foreclosure charges as specified in Schedule III, hereto and such charges is payable by the Borrower together with applicable taxes.
                  <br />
                  If the Borrower requests for pre-payment during the Lock-in period, then the Lender is at liberty to refuse the pre-payment or in the alternative lay down conditions for acceptance at its discretion which the Borrower is at liberty to accept or otherwise. Notwithstanding anything contained the Borrower shall remit the monthly instalments without any default in terms of the Agreement. The Option to accept pre-payment or decline the request for pre-payment during the Lock-in period is at the sole discretion of the Lender.
                </p>
              </div>
              <div className="policy_points">
                <h5>REPRESENTATION AND WARRANTY</h5>
                <p> The representations and warranties set out in this Clause 5 , shall be made on the date of\ this Agreement, on the date of each disbursement of the Loan or part of it, and shall be\ deemed to have been repeated by the Borrower on every day from the date of this\ Agreement until all obligations of the Lender are discharged in full, to the satisfaction of\ the Lender:
                  <br />
                  Power and Authority
                  <br />
                  The Borrower is a major and is of sound mind;
                  <br />
                  The Borrower has the power to enter into, perform, deliver and comply
                  <br />
                  with the provisions of this Agreement, and has taken all necessary actions
                  <br />
                  to authorise its entry into, performance and delivery by it of this Agreement.
                  <br />
                  No Conflict
                  <br />
                  The Borrower represents and warrants that the entry into, delivery and performance by the Borrower of this Agreement and the transactions contemplated by this Agreement do not and will not conflict (a) with, any applicable law; (b); or (c) with any document which is binding upon the Borrower.
                  <br />
                  Litigation
                  <br />
                  There is no action, or proceedings against the Borrower pending before any governmental authority including (i) no proceedings have been initiated or are pending against the Borrower or in respect of any of its income or properties under the Income Tax Act, 1961; (ii) no notice or demand whether under rule 2 of the second schedule of the Income Tax Act, 1961 or otherwise has been received
                  <br />
                  by the Borrower under the Income Tax Act, 1961, and (iii) there are no arrears of tax or other sum outstanding from the Borrower under the Income Tax Act, 1961 nor is there any claim raised by any authority in respect of any tax or any other sum payable by the Borrower as an ‘assessee’ in terms of the Income Tax Act, 1961 or otherwise which might affect the Borrower’s ability to perform its obligation under this Agreement.
                  <br />
                  Solvency The Borrower has not taken any action nor have any other steps been taken or legal proceedings been started or threatened against Borrower for his bankruptcy (or proceedings analogous to it)
                  <br />
                  No misleading information The information furnished by the Borrower for availing of the Loan is, and shall be, true, complete and correct and shall not be misleading.
                </p>
              </div>
              <div className="policy_points">
                <h5>EVENT OF DEFAULT</h5>
                <p>The happening of the following events shall constitute an event of default (“Event of Default”):
                  <br />
                  Any non-compliance by the Borrower of the terms and conditions of this Agreement or any other agreement entered into in respect of this Loan from the
                  <br />
                  Lender;
                  <br />
                  Any breach of representations and warranties in Clause 5 or any other terms of this Agreement by the Borrower;
                  <br />
                  Non adherence to the Repayment Schedule;
                  <br />
                  Insolvency, winding up, dissolution of the Borrower and inability of the Borrower to repay their debts;
                  <br />
                  Any concealment of any material document or event by the Borrower;
                  <br />
                  Submission of any forged document by the Borrower; and
                  <br />
                  Any other event which in the sole opinion of the Lender would endanger the repayment of the Loan.
                </p>
              </div>
              <div className="policy_points">
                <h5>CONSEQUENCES OF EVENT OF DEFAULT</h5>
                <p>In the Event of Default mentioned in Clause 6 above, the following shall be the consequences:
                  <br />
                  The entire Loan along with Interest for the entire period shall immediately become due and payable, and the Lender shall have the right to recall the entire loan together with Interest for the entire period, the overdue interest arising on account of default, bank charges and other charges, as mentioned in Schedule III hereunder; All charges are payable by the Borrower together with applicable taxes.
                  <br />
                  Lender shall be entitled to enforce the PDC, if any available;
                  <br />
                  Lender shall be entitled to proceed against and take any action against the Borrower in order to realize the Loan along with Interest, charges and expenses; In addition to the rights specified in this Agreement, the Lender shall be entitled to take all or any action with or without intervention of the Courts to recover the monies due and payable by the Borrower under this Agreement.
                  <br />
                  Lender shall be entitled to initiate criminal proceeding or any other appropriate actions against the Borrower if at any time the Lender at its sole discretion has sufficient grounds to believe that the Borrower has made any misrepresentations and / or submitted any forged documents or fabricated data to the Lender.
                  <br />
                  All rights and powers conferred on the Lender under this Agreement shall be in addition and supplemental to any rights the Lender has as a creditor against the Borrower under any law for the time
                </p>
              </div>
              <div className="policy_points">
                <h5>GOVERNING LAW AND JURISDICTION</h5>
                <p>This Agreement shall be governed by and construed in accordance with the laws of India and, courts of [Delhi], India shall have exclusive jurisdiction over all matters arising pursuant to this Agreement.</p>
              </div>
              <div className="policy_points">
                <h5>NOTICES</h5>
                <p>All notices under this Agreement shall be written in English and shall be sent by hand or by courier or by facsimile or by electronic mail to the applicable Party at the address / contact details indicated as in Schedule I or to such other address as any Party shall designate by written notice to the other Party and shall be deemed to be given only when delivered by personal delivery or by courier or by electronic mail at the abovementioned address.</p>
              </div>
              <div className="policy_points">
                <p>AMENDMENT AND ASSIGNMENT Any amendments to this Agreement shall be legally valid and binding on each of the Parties, if made in writing and signed by each of the Parties. No Party shall be entitled to transfer or assign any rights or obligations under this Agreement to third parties without informing the other Party and obtaining the prior written approval of such other Party.</p>
              </div>
              <div className="policy_points">
                <h5>WAIVER</h5>
                <p>The failure of any Party to insist, in one or more instances, upon strict performance of the obligations of this Agreement, or to exercise any rights contained herein, shall not be construed as waiver, or relinquishment for the future, of such obligation or right, which shall remain and continue in full force and effect. No waiver of any breach of any provision of this Agreement shall be effective or binding unless made in writing and signed by the Party purporting to give the same and, unless otherwise provided in the written waiver, shall be limited to the specific breach waived.</p>
              </div>
              <div className="policy_points">
                <h5>SEVERABILITY</h5>
                <p>A provision contained in this Agreement is enforceable independently of each of the others and its validity will not in any way be affected by the invalidity or unenforceability of any other provision hereof. If any provision of this Agreement is held to be illegal, invalid or unenforceable under any applicable law from time to time: (a) such provision will be fully severable from this Agreement; (b) this Agreement will be construed and enforced as if such illegal, invalid or unenforceable provision had never comprised a part hereof; and (c) the remaining provisions of this Agreement will remain in full force and effect and will not be affected by the illegal, invalid or unenforceable provision or by its severance here from.</p>
              </div>
              <div className="policy_points">
                <h5>ENTIRE AGREEMENT</h5>
                <p>This Agreement contains the whole agreement and understanding between the Parties with regard to the matters dealt with in this Agreement and unless provided otherwise supersedes any prior agreement, understanding, arrangement or promises, whether
                  <br />
                  written or oral or whether subsisting or expired, relating to the subject matter of this Agreement The Parties expressly acknowledge that, in relation to the subject matter of this Agreement, each of them assumes no obligations of any kind whatsoever other than as expressly set forth in this Agreement.
                </p>
              </div>
              <div className="policy_points">
                <h5>COUNTERPARTS</h5>
                <p>This Agreement may be executed simultaneously in more than one counterpart each of which shall be deemed to be an original but all of which shall constitute the same instrument.</p>
              </div>
              <div className="policy_points">
                <p>IN WITNESS WHEREOF, the Parties hereto executed these presents on the day, month and year first above mentioned.</p>
              </div>
              <div className="policy_points">
                <h5>SCHEDULE I (A)</h5>
                <p>Description of Borrower</p>
              </div>
              <div className="policy_points">
                <h5>SCHEDULE I (B)</h5>
                <p>Description of Lender (s)</p>
              </div>
              <div className="policy_points">
                <h5>SCHEDULE I (II)</h5>
                <p>Loan Details and Repayment Terms</p>
              </div>
              <div className="policy_points">
                <h5>SCHEDULE I (III)</h5>
                <p>Fees and Charges</p>
              </div>
              <div className="policy_points">
                <p>All fees shall be paid together with all taxes including Goods and Service Tax (GST) as may be applicable from time to time</p>
              </div>
            </div>
          </div>
        </div>
    );
}

export default LoanAggrement;