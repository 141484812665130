import React, { useEffect } from "react";
import { useState } from "react";
import logo from './../public/assest/img/alphamoney_logo.png'
import Cross from './../public/assest/img/close_cross.png'
import {isAndroid, isIOS} from 'react-device-detect'
import IconCross from './../public/assest/img/icon.png'
import { URL } from "../utility/Constant";

const menuData = [ 
	{
		id: 1,
		name: 'Home',
		link: '/'
	},
	{
		id: 2,
		name: 'Invest',
		link: '/investor'
	},
	{
		id: 3,
		name: 'Borrow',
		link: '/borrower'
	},
	{
		id: 4,
		name: 'About Us',
		link: '/about-us'
	},
	{
		id: 5,
		name: 'Contact',
		link: '/contactus'
	}
]
      
function Header() {
	let [bgColor, setbgColor] = useState('')
	const [activeItem, setActiveItem] = useState('');

	useEffect( () => {
		let checkUrl = window.location;
		if(checkUrl.pathname === '/'){
			setbgColor('bgColorHome')
		}
		if(checkUrl.pathname === '/borrower'){
			setbgColor('bgColorBorrower')
		}
		if(checkUrl.pathname === '/investor'){
			setbgColor('bgColorInvestor')
		}
		if(checkUrl.pathname === '/career'){
			setbgColor('sliderBgCareer')
		}
	},[])

	useEffect(() => {
        let checkUrl = window.location.pathname;
        setActiveItem(checkUrl);
    }, [])

    const handleClick = (link) => {
        setActiveItem(link);
    };
	
	const mobileToggle=()=> {
		var elements = document.getElementById("mobileMenuIcon");
      elements.classList.toggle("mobileIconChange");
	  var element = document.getElementById("mobileMenu");
      element.classList.toggle("mobileMenuShowHide");
	}
	const goTopath = (path) => {
		window.open(path, '_blank');
	}

    return (
		  <div className={`header ${bgColor}`} >
		    <div className='container'>
				<div className='row'>
					<div className='col-sm-12 col-md-12 col-lg-3 relative'>
					<a href="/">
                      <img className="headerLogo img-fluid" 
					  src={`${URL.BASE_URL}Home+Page/alphamoney_logo.png`}
					  alt="Logo" />
					</a>
					 <small id="mobileMenuIcon" onClick={mobileToggle}   className="mobileIcon">
					  <img className="threeLinesImg" src={IconCross} alt="Logo" />
					  <img className="hideIcon" src={Cross} alt="Logo" />
					 </small>
		           </div>
				   <div className='col-sm-12 col-md-12 col-lg-9'  id="mobileMenu">
				   <div className='row align-center'>
						<div className='col-sm-12 col-md-12 col-lg-8'>
							<ul className="navItem">
								{menuData?.map((item) => (
									<li key={item.id}>
										<a className={activeItem === item.link ? 'activeMenu' : ''} 
										onClick={() => handleClick(item.link)} href={item.link}>{item.name}</a>
									</li>
								))}
							</ul>
							
						</div>
				   <div className='col-sm-12 col-md-12 col-lg-4'>
		              <div className="auth_btns">
						{isAndroid ?
						<>
							<button  className="btn_black_outline" onClick={() => goTopath('https://play.google.com/store/apps/details?id=com.creditTech.alphamoney&pli=1')}>Login </button>
						  	<button className="btn_black" onClick={() => goTopath('https://play.google.com/store/apps/details?id=com.creditTech.alphamoney&pli=1')}>Register </button>
						</>
						: isIOS ? 
						<>
							<button  className="btn_black_outline" onClick={() => alert('iOS App is Coming Soon')}>Login </button>
						  	<button className="btn_black" onClick={() => alert('iOS App is Coming Soon')}>Register </button>
						</>
						:
						<>
							<button  className="btn_black_outline" onClick={() => goTopath('https://app.alphamoney.in')}>Login </button>
						  	<button className="btn_black" onClick={() => goTopath('https://app.alphamoney.in')}>Register </button>
						</>
						}
		             </div>
		             </div>
				   </div>
				   </div>
              </div>
		  </div>
		</div>
    )
}

export default Header;