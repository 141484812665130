import React from "react";

function BusinessModel(props) {
  const gotoPage = (path) => {
    window.location.href = path;
  };
  return (
    <div className="policy_container">
      <div className="policy_header">
        <div className="container">
          <h2 className="policy_heading">
            <b>Business </b> Model
          </h2>
        </div>
      </div>
      <div className="container">
        <div className="policy_breadcrumb">
          <h3>
            <span><a href="/">Home</a> </span>
            <span> /</span>
            <span> Business Model</span>
          </h3>
        </div>
        <div className="policy_body">
          <div className="policy_points">
            <h5>BACKGROUND</h5>
            <p>P2P digital loan platforms aim to digitize and standardize existing informal process of money lending and borrowing among individuals. P2P digital loan platforms also aim to bring ease of process and connect a wider network of lenders and borrowers by leveraging internet technology. </p>  
            <p>P2P digital loan platforms can provide better returns to lenders over banking deposits by lowering processing cost while it can also help provide coverage to those borrowers who are not covered by traditional banking system.</p>
          </div>
          <div className="policy_points">
            <h5>ALPHAMONEY (CREDITECH SOLUTIONS PRIVATE LIMITED) BUSINESS MODEL</h5>
            <p>AlphaMoney (Creditech solutions private Limited) provides a safe and robust digital platform to connect lenders and borrowers enabling loan transactions between them. </p>
            <p>All borrower applications are put through stringent credit and quality checks before being made available on the platform to ensure lenders are exposed to a high-quality basket of borrowers. Borrowers may be sourced via multiple channels including channel partners.</p>
            <p>AlphaMoney (Creditech solutions private Limited) focuses heavily on identity verifications, credit checks and diversification of lenders funds to reduce risks to parties involved in the transactions. All borrowers and lenders are mandatorily required to enter into an agreement, which captures the terms and conditions of the loan, repayment period, rate of interest etc.</p>
            <p>Lenders lend funds through a lender dashboard, which allows them to monitor their lending and keep track of their portfolio. To reduce the time involved in selecting/curating borrower applications, lenders are free to define their lending criteria, such that only borrowers meeting the criteria are allocated to them via AlphaMoney (Creditech solutions private Limited) proprietary algorithm.</p>
            <p>In line with RBI master directions, all transactions on the platform flow via an escrow account, monitored by a bank - sponsored trustee. The funds brought into the escrow account are subsequently disbursed to the borrowers only on completion of loan agreement signing between transacting parties.</p>
            <p>The platform earns income by charging processing and other types of fees on loans disbursed through the platform.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BusinessModel;
