import apiInstance from "../config/api/axios"

export const postEnquiry = async(payload) => {
    const response = await apiInstance.post('contact_details', payload)
    return response
}
  
export const leadCapture = async(payload) => {
  const response = await apiInstance.post('contact_details', payload)
  return response
}