import {combineReducers} from 'redux'
import {routerReducer} from 'react-router-redux'
import contactReducers from './contactReducers'

const appReducer = combineReducers({
  routerState: routerReducer,
  contactState: contactReducers,
})

const rootReducer = (state, action) => {
//   if (action.type === 'AUTH_LOGOUT_USER') {
//     return appReducer(undefined, action)
//   }
  return appReducer(state, action)
}

export default rootReducer
