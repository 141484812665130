import React from 'react';

function TermsOfUseMobile() {
    return (
        <div style={{marginBottom:"-8rem"}}>
        <div className="pb-0" style={{ textAlign: "justify" }}>
        <br />
      <div className="third_section_about-1">
        <div className="row">
          <div className="col-lg-9 third_section_about_text-1">
            <p className="textHeading-2">
              <b> Terms </b> of Use
            </p>
          </div>
        </div>
      </div>
      <br />
      <br />
      <h3 className="commonheadingfooter1">
      {/* <span><a href='/' className="commonheadingfooter2">Home</a></span><span> /</span><span> Terms of Use</span>   */}
    </h3>
       <div className='contentmarginfooter' style={{marginTop:"2rem"}}>
       <p>
       Creditech Solutions Private Limited (<span className="contentofheading">&ldquo;Alphamoney&rdquo;</span> or
       <span className="contentofheading">&ldquo;we&rdquo;</span>) is an online peer to peer
     
   
       lending platform at{" "}
       <a className="linksdatainfooter" data-fr-linked="true" href="//www.alphamoney.in">
         www.alphamoney.in
       </a>{" "}
       with any sub domain thereof, mobile applications or
     
   
       other present or future electronic modes
       (<span className="contentofheading">&ldquo;Platform&rdquo;)</span> for connecting its users/visitors
     
   
       (<span className="contentofheading">&ldquo;User/s&rdquo; </span>or <span className="contentofheading">&ldquo;you&rdquo; </span>or <span className="contentofheading">&ldquo;your&rdquo;
</span>                or <span className="contentofheading">&ldquo;yourself&rdquo;)</span> among themselves.
     
   
       These terms of use (<span className="contentofheading">&ldquo;Terms of Use&rdquo;)</span> along with the
       privacy policy set out at  {" "}
       <a className="footercontentlinks" data-fr-linked="true" href="">
       https://alphamoney.in/privacy_policy
       </a>{" "} {" "}
     
       together constitute the agreement between the Users herein and
       Alphamoney, governing
     
   
       usage of the Platform by the former (<span className="contentofheading">&ldquo;Agreement&rdquo;)</span>
       and are legally binding. The Users may
     
   
       freely browse through the Platform, however in order to avail
       the services, Users would be
     
   
       required to register on the Platform. By accessing the Platform,
       registering or any other
     
   
       means of usage herein, the User shall be deemed to have read and
       agreed the terms of the
     
   
       Agreement without requiring any other express, implied or other
       consent requirement in
     
   writing or otherwise.</p>
     <p>
       Alphamoney reserves the right to add, modify, amend, cancel,
       limit, vary or change, either
     
       wholly, or in part, at any point in time, the Agreement
       including these Terms of Use, without
     
       prior notice. Further, Alphamoney reserves the right to
       withdraw, suspend, alter, modify,
     
       change or vary the Platform, the Platform features, the Platform
       content or parts thereof, at its
     
       sole discretion, without prior notice. All
       modifications/amendments to the Terms of Use will
     
       be posted on the Platform and will become effective immediately
       upon such posting on the
     
       Platform. Your continued use of the Platform after such change
       or modification shall be
     
       deemed to be your agreement to the revised/modified Terms of
       Use. Please review these
     
       Terms of Use regularly to remain informed of any change. If you
       do not agree to these Terms
     of Use, please discontinue using this Platform immediately.</p>
     <p className="contentofheading1">1. Eligibility</p>
     <p className="headingparagraph">The User hereby represents and warrants that he/ she:</p>  
     <p>
     <span > a. </span> is at least 18 (eighteen) years of age or above and are fully
       able and competent to
    
       understand and agree the terms, conditions, obligations,
       affirmations,
     representations, and warranties set forth in the Agreement;</p>
     <p>
     <span> b. </span> have the right, authority, and capacity to enter into and to
       fully abide by all of the
    
       terms and conditions of this Agreement, as far as age,
       jurisdiction, laws of land,
    etc. are concerned; and</p>
     <p>
     <span> c. </span> agree to comply with all applicable domestic and
       international laws, statutes,
    
       ordinances and regulations regarding the use of the Platform
       including services.
     </p>
     <p className="contentofheading1">2. Definitions</p>
     <p className="headingparagraph">
       In these Terms of Use, the following words shall have the
       following meanings:
     
       <span>&ldquo;Applicable Laws&rdquo; </span>means all the existing laws, rules
       and regulations, modified or
     amended from time to time.</p>
     <p>
       <span>&ldquo;Borrower/s&rdquo; </span>means an individual, who enlist himself
       /herself for borrowing with
     
       Alphamoney or those who borrow or avail Alphamoney services in
       any manner.

       <span style={{marginTop:"-17px"}}>&ldquo;Lender/s&rdquo; </span>means those who is/are willing to invest or lend money to the Borrower listed with Alphamoney.</p>
     <p style={{marginTop:"-17px"}}>
       <span >&ldquo;Privacy Policy&rdquo; </span>mean the privacy related policy of
       the Platform and which can be
     accessed at [insert link of privacy policy].</p>
     <p style={{marginTop:"-17px"}}><span>&ldquo;RBI&rdquo; </span>shall denote Reserve Bank of India.
       <span>&ldquo;User/s&rdquo; </span>means a borrower or lender approved by and
       registered with Alphamoney.
     </p>
     <p className="contentofheading1">3. Term and Termination</p>
     <p className="headingparagraph">
       This Agreement shall remain in full force and effect while you
       use the services or are
    
       a User on the Platform. Alphamoney may terminate your use
       of/access to the Platform
    
       or the services (either case, in whole or part), in its sole
       discretion, at any time,
    without warning or notice to you.</p>
     <p className="contentofheading1">4. Acceptance of Terms of Use</p>
     <p className="headingparagraph">
       By using this Platform, you agree to the Terms of Use that
       Alphamoney has provided.
    
       If you do not wish to agree to these Terms of Use, please
       refrain from using the
    
       Platform. In the Platform, Alphamoney provides end users with
       access to database of
    
       Lenders, Borrowers and service providers in relation to debt
       services such as
    
       verification agencies and collection agencies, etc. The
       Alphamoney is only a platform
    
       that brings together Borrowers and Lenders, it matches the need
       of Borrowers and
    
       Lenders, and provides link to service providers that are
       associated with the business of
    
       borrowing and lending such as verification agency and collection
       agency.
    
       Alphamoney in no manner collects deposits from the Lenders or
       person who would
    
       like to earn interest on their money by lending. Alphamoney only
       supports the Lender
    
       in verifying the whereabouts of the Borrower on best effort
       basis. If this Terms of Use
    
       conflicts with any other document, the Terms of Use will prevail
       for the purposes of
    usage of the Platform.</p>
     <p className="contentofheading1">5. Registration and User Profile</p>
     <p className="headingparagraph">
       <span>5.1</span> Access to certain services on the Platform, require
       completion of a simple registration
    
       process to create User Profile. The registration and creation of
       a User Profile requires
    
       you to reveal your identity and/or other information about
       yourself, which will be
    governed by the Platform&rsquo;s Privacy Policy.</p>
     <p>
     <span>5.2</span> Users will have an option to register with us and create a
       personal profile on the
    
       Platform (<span>&ldquo;User Profile&rdquo;)</span>, which may enable them to
       access additional sections on
    
       our Platform to and avail other services. By accessing and/or
       completing the online
    
       registration process on the Platform and by creating a User
       Profile, User confirms its
    
       acceptance of Agreement. Alphamoney may, at its sole and
       absolute discretion, refuse
    
       to accept registration applications for the registration and
       creation of a User Profile.
     </p>
     <p>
     <span>5.3</span> To create a User Profile, you have to acknowledge and agree
       that you may be required
    
       to give information about yourself for your User Profile
       verification. In order to
    
       complete your User Profile, you will be required to inter alia
       provide your full name,
    
       email address, gender, mobile number(s), date of birth, current
       city/place of residence,
    
       etc. Any information provided by you to us should be complete
       and accurate to the
    
       best of your knowledge. We are not obliged to cross check or
       verify information
    
       provided by you and we will not take any responsibility for any
       outcome or
    
       consequence as a result of you providing incorrect information
       or concealing any
    relevant information from us.</p>
     <p>
     <span>5.4</span> You understand that it is your responsibility to protect
       certain information relating to
     
       your usage of the Platform including, but not limited to, your
       username, password,
     
       email address, contact details, mobile number and one-time
       password, if any. You are
     
       responsible for maintaining the confidentiality of the password
       and User Profile, and
     
       are fully responsible for all activities that occur under your
       password or User Profile.
     
       &nbsp;You agree to notify Alphamoney immediately of any
       unauthorized use of your
     account or any other breach of security.</p>
     
     <p>
     <span>5.5</span> User acknowledges and agrees that if Alphamoney disables
       access to User Profile,
    
       User may be prevented from accessing the Platform or services
       and that such User
    
       Profile details or any files or other content, which may be
       contained in such User
     </p>
     <p style={{marginTop:"-22px"}}>Profile.</p>
     <p>
     <span>5.6</span> In the event, Alphamoney finds that the information shared
       by the User of the
     
       Platform is incorrect or that the User is in breach of Terms of
       Use of the Platform or
     
       User is in default of the lenders and borrowers agreement then
       in such case
     
       Alphamoney may add the name and details of such person in the
       list of persons who
     are blacklisted from receiving funds or lending.</p>
     <p className="contentofheading1">6. Services</p>
     <p className="headingparagraph">
       Alphamoney, through the Platform, provides a platform for
       connecting the Borrowers
     
       and Lenders to facilitate lending and borrowing and such
       services which are
     
       incidental, ancillary or connected herewith. Alphamoney does not
       undertake any
     
       lending or borrowing activities, which are regulated by RBI or
       any other regulatory
     
       body. Alphamoney or the Platform does not take responsibility or
       liability to match
     the Borrower with the Lender or vice versa.</p>
     <p className="contentofheading1">7. License and Platform access</p>
     <p className="headingparagraph">
       Alphamoney grants you a limited license to access and make
       personal use of the
    
       Platform. This license does not include any downloading or
       copying of account
    
       information for the benefit of another vendor or any other third
       party; caching,
    
       unauthorized hypertext links to the Platform and the framing of
       any content available
    
       through the Platform, uploading, posting, or transmitting any
       content that you do not
    
       have a right to make available (such as the intellectual
       property of another party). You
     </p>
     <p>
       may not bypass any measures used by Alphamoney to prevent or
       restrict access to the
     
       Platform. Any unauthorized use by you shall terminate the
       permission or license
     
       granted to you by Alphamoney. By using this Platform and by
       accepting to agree to
     
       these Terms of Use, you also agree that you shall not hack or
       otherwise hire some
     
       third party to hack the site or use any other unauthorized
       means. By using this
     
       Platform you agree that you will hold all and any information
       received by you during
     
       your interaction with this Platform or any other Users of this
       Platform (including
     
       other Lenders and Borrowers on this Platform) in the strictest
       confidence and shall not
     
       disclose or use the information obtained by you by accessing
       this Platform. You shall
     
       in such an event be liable of a criminal offence and Alphamoney
       shall institute
     appropriate legal proceedings to claim damages.</p>
     <p className="contentofheading1">8. Compliance with RBI</p>
     <p className="headingparagraph">
       By using this Platform and registering with us on the Platform,
       you agree that you are
    
       aware of and have read the Master Directions, Non-Banking
       Financial Company, Peer
    
       to Peer Lending Platform (Reserve Bank) Directions, 2017,
       amended from time to
    
       time. The Users who are registering on the Platform need to
       strictly follow the
    
       guidelines and directions issued by RBI for peer to peer lending
       along with
    Applicable Laws.</p>
     <p className="contentofheading1">9. Impermissible use of Platform</p>
     <p className="headingparagraph">
       Users of this Platform shall not host, post, comment, modify,
       publish, transmit, update
         
       or share any content that is inappropriate. Without limiting the
       generality of the
         
       foregoing, the following is a partial list of the types of
       content that Alphamoney
     deems to be inappropriate:</p>
     <p>
       <span>a.</span> Content belonging to another person and to which a User does
       not have any right
     to;</p>
     <p>
     <span>b.</span> Content that infringes intellectual property of any party;
     </p>
     <p>
     <span>c.</span> Content that contains nudity, violence, or offensive subject
       matter or contains a
     
       link to an adult websites, blasphemous, obscene, pornographic,
       paedophilic,
     
       invasive of another&rsquo;s rights including but not limited to
       rights of celebrity, privacy
     
       and intellectual property, hateful, or racially, ethnically
       objectionable,
     
       disparaging, relating or encouraging money laundering or
       gambling, or otherwise
     unlawful in any manner whatsoever;</p>
     <p>
     <span>d.</span> Content that includes racially, ethically, or otherwise
       objectionable language;
     </p>
     <p>
     <span>e.</span> Content that is libelous, defamatory, or otherwise tortious
       language;
     </p>
     <p>
     <span>f.</span> Content shall not contain any viruses, Trojan horses, worms,
       time bombs,
     
       cancelbots, easter eggs or other computer programming routines
       or executable
     
       files that may damage, detrimentally interfere with,
       surreptitiously intercept or
     
       expropriate any system, data or personal information of any
       person whatsoever;
     </p>
     <p>
     <span>g.</span> Content that violates any law for the time being in force;
     </p>
     <p>
     <span>h.</span> Content that offer unauthorized downloads of any copyrighted
       or private
     information.</p>
     <p>
     <span>i.</span> Content that impersonates another person or entity or falsely
       states or otherwise
       misrepresents your affiliation with any individual or entity.
    
     </p>
       <br />
     <p className="contentofheading1">10. Opt-out</p>
     <p className="headingparagraph">
     <span>10.1</span> If you are no longer interested in receiving e-mail
       announcements and other
    
       marketing information from us, or you want us to remove any
       personal information
    
       that we have collected about you, you can opt-out anytime by
       sending an e-mail about
     
       your request to{" "}
       <a className='footercontentlinks' data-fr-linked="true" href="mailto:support@alphamoney.in">
         support@alphamoney.in
       </a>
     </p>
     <p>
     <span>10.2. </span> In case Existing Lender / Borrower wish to get deleted
       his/her/its account with
     
       Alphamoney, it may be made specific request and the account
       shall be deleted only
     
       after satisfying the conditions, as may be determined by
       Alphamoney, including the
     
       criteria for the Borrower that no amount is due or outstanding
       qua Lender or the
     
       Alphamoney. Alphamoney, at its sole discretion, can preserve the
       Borrower&rsquo;s
     
       information for such longer period, as it may be desire or as
       required under
     Applicable Laws.</p>
     <p>&nbsp;</p>
     <p className="contentofheading1">11. Third party interaction and links to third party sites</p>
     <p className="headingparagraph">
     <span>11.1 </span> The Platform may contain links to other third party
       Platforms which are not owned,
     
       operated or endorsed by Alphamoney and you hereby release and
       discharge the
     
       Alphamoney Indemnified Parties from any and all claims, losses
       and damages arising
     
       out of the use of, or inability to use, or access such third
       party websites or links.
     
       Alphamoney hereby disclaims all liability or responsibility for
       the content hosted or
     
       information provided by you/collected from you by such third
       party websites. You are
     
       advised to review the terms and conditions of such third party
       websites carefully
     
       before visiting, accessing, using or availing of the services of
       such third party
     websites.</p>
     <p>
     <span>11.2 </span> Correspondence, advertisement, purchase or promotion,
       including the delivery and the
    
       payment for goods and/or services, and any other term,
       condition, warranty or
    
       representation associated with such correspondence, purchase or
       promotion,
    
       pertaining to such third party websites is solely between you
       and the applicable third
    
       party and is at your sole risk. Alphamoney shall not be
       responsible or liable in any
    
       manner whatsoever for transactions, information provided by /
       collected from you and
    
       interaction with such third party websites and/ or any losses
       sustained in that regard.
    
     </p>
       <br />
     <p className="contentofheading1">12. Confidentiality</p>
     <p className="headingparagraph">
       Alphamoney and you hereby agree to keep any data
       (<span className="contentofheading">&ldquo;Confidential Information&rdquo;)</span>
    
       confidential and shall not disclose such data without the
       consent of the other party
    
       except pursuant to an order of any court or government
       authority, in performance of
    
       this Agreement by Alphamoney, or when the parties independently
       obtain such data
    from a third party.</p>
     <p className="contentofheading1">13. Intellectual Property and Ownership</p>
     <p className="headingparagraph">
       Except for the content submitted by the Users, all content on
       this Platform and/ or
     
       services or other incidental services thereto, are protected by
       copyright, trademark,
     
       patent, trade secret and other intellectual property laws of
       India. You acknowledge
     
       that the Platform and any underlying technology or software on
       the Platform or used
     
       in connection with rendering the services are proprietary
       information owned or duly
     
       licensed to Alphamoney, except where it is indicated otherwise.
       You are prohibited to
     
       post, modify, distribute, create derivative works or reproduce
       any of the content,
     
       software, marks, logos, and/or materials available on the
       Platform in whole or in part
     except as expressly allowed under the Terms of Use.</p>
     <p>&nbsp;</p>
     <p className="contentofheading1">14. Disclaimer of Warranties</p>
     <p className="headingparagraph">
     <span>14.1 </span> User acknowledges and agrees that the services are provided
       on <span className="contentofheading">&ldquo;as is&rdquo; </span>and <span className="contentofheading">&ldquo;as
    
       available&rdquo; </span>basis without warranty of any kind, express,
       implied, statutory or otherwise
    
       and that use of services shall be at sole risk of user.
       Alphamoney, any subsidiary,
    
       affiliate, director, officer, employee and agent expressly
       disclaims any and all implied
    
       or express warranties to the maximum extent permitted by
       Applicable Laws in
    
       connection with the services. No advice or information, whether
       oral or written,
    
       obtained by you from the Platform or services shall create any
       warranty not expressly
    stated in the Terms of Use.</p>
     <p>
     <span>14.2 </span> This Platform is controlled and operated from India and
       Alphamoney makes no
     
       representation that the content, information or materials made
       available herein are
     
       appropriate or will be available for use in other locations.
       Access and use of this
     
       Platform from outside India is entirely at your sole risk and
       you agree and undertake
     
       to be responsible for compliance with all applicable local laws
       and agree to release,
     
       discharge and absolve the Alphamoney parties from any liability
       or loss in this
     respect.</p>
     <p>
     <span>14.3 </span> Alphamoney reserves the right to introduce and initiate new
       features, functionalities
     
       and components to the Platform and/or change, alter, modify,
       suspend, discontinue or
     
       remove the existing ones without any prior notice to you.
       Alphamoney may also
     
       prescribe certain limits on the use of the Platform and/or
       services or storage of User
     
       content or Alphamoney content at its sole discretion without any
       prior notice to you
     
       while at all times complying with the Privacy Policy. In
       consideration for
     
       Alphamoney granting you access to and use of the Platform and
       services, you agree
     
       that Alphamoney and its third party providers and partners may
       place such advertising
     
       on the services or in connection with the display of User
       content or Alphamoney
     
       content, or information from the services, whether submitted by
       them or others.
     </p>
     <p>
     <span>14.4</span> Your use of this Platform is solely at your risk. You agree
       that Alphamoney shall have
     
       no responsibility for any damages suffered by you in connection
       with this Platform or
     
       any content contained therein or any damage suffered in the
       event of default or
     misrepresentation by any other user of this Platform.</p>
     <p>
     <span>14.5</span> By uploading any content, representation or statement of
       your financial status or your
    
       proof of residence you represent and warrant that you have the
       lawful right to
    
       reproduce and distribute such content and that the content
       complies with all laws and
    
       is true and authentic and is not in any event placed on the
       Platform to defraud other
    
       Users or create a false impression in their minds that you have
       a sound financial status
    
       or are reasonably committed to deliver the repayments on time.
    
     <span>14.6</span> You also represent that you have never been accused of any
       fraud, misrepresentation
    
       or default in repayment of any claims or monies to any third
       party whether a financial
    
       institution or not. You also represent that there are no
       outstanding disputes in
    connection with your moral turpitude or financial status.</p>
     <p><span className="contentofheading1">15. Limitation of Liability</span></p>
     <p className="headingparagraph"> 
     <span>15.1</span> In no event shall Alphamoney Indemnified Parties involved
       in creating, producing,
     
       transmitting or distributing products or services on this
       Platform, granting or receiving
     
       loan from a User associated with Platform shall be liable to you
       or any third party for
     
       any direct, indirect, consequential, exemplary, incidental,
       special or punitive damages,
     
       including damages for loss of use, data or profits (whether
       incurred directly or
     
       indirectly), any loss of goodwill or business reputation, or any
       other intangible losses,
     
       even if you or any third party have been advised of the
       possibility of such damages.
     
     <span >15.2</span> You specifically acknowledge that Alphamoney shall not be
       liable for user content or
     
       for any defamatory, offensive, or illegal conduct of any third
       party and that the risk of
     
       harm or damage from the foregoing rests entirely with you. You
       also acknowledge
     
       that Alphamoney shall not be liable for any shortage or
       non-fulfillment of services on
     
       the Platform and unavailability of services due to any
       inability, delay or failure on
     
       party of Lender and/or the Borrower to accept the terms and
       conditions of loan
     agreement.</p>
     <p><span className="contentofheading1">16. Indemnity</span> </p>
     <p className="headingparagraph">
     <span >16.1</span> You agree to indemnify and hold Alphamoney and its
       officers, directors, agents,
    
       subsidiaries and employees (<span className="contentofheading">&ldquo;Alphamoney Indemnified
       Parties&rdquo;)</span> harmless from any
    
       claim or demand, including reasonable attorneys&rsquo; fees, or
       arising out of or related to
    
       your breach of this Terms of Use, or arising out of your use or
       misuse or access to
    
       Platform, service, content or otherwise , or your violation of
       any law or the rights of a
    
       third party including but not limited to breach of any
       warranties, representations or
    
       undertakings or in relation to the non-fulfilment of any of your
       obligations under this
    
       Terms of Use or arising out of violation of any applicable laws,
       regulations including
    
       but not limited to intellectual property rights, payment of
       statutory dues and taxes,
    
     </p>
       <br />
     <p>
       claim of libel, defamation, violation of rights of privacy or
       publicity, loss of service
       by other subscribers and infringement of intellectual property
       or other rights. This
       clause shall survive the expiry or termination of this Terms of
       Use.
       <br />
     </p>
     <p>
     <span>16.2</span> The Borrower and the Lender acknowledge that Alphamoney is
       not obligated to
    
       perform any act or obligation for them and that it is only a
       service provider to them
    
       that facilitates the transaction between them or act an agent
       for the authority provided.
    
       In case of any loss or damage to Alphamoney due to their action
       or inaction, the
    
       Lender and the Borrower shall, jointly and severally, indemnify
       Alphamoney.
     </p>
     <br/>
     <p>
     <span>16.3 </span>In the event any excess amount is credited into your
       account by reason of any system
     
       error or human error (<span>&ldquo;Wrongfully Credited Amounts&rdquo;)</span>
       to which you are not
     
       entitled, you shall immediately notify us in writing of the same
       and shall not
     
       withdraw, use, transfer or deal with such Wrongfully Credited
       Amount in any manner
     
       whatsoever. You hereby undertake to indemnify us for any losses,
       costs, damages,
     
       charges and expenses that we may suffer as a result of a breach
       of this obligation.
     </p>
     <p><span className="contentofheading1">17. Governing law and Jurisdiction</span></p>
     <p className="headingparagraph">
       These Terms of Use shall be governed by and constructed in
       accordance with the laws
     
       of India subject to the exclusive jurisdiction of the courts of
       Delhi, India.
     </p>
     <p><span className="contentofheading1">18. Force Majeure</span></p>
     <p className="headingparagraph">
       Alphamoney is not liable for failure to perform any of its
       obligations if such failure is
     
       as a result of Acts of God (including fire, flood, earthquake,
       storm, hurricane,
     
       epidemic or other natural disaster), war, invasion, act of
       foreign enemies, civil war,
     
       rebellion, revolution, insurrection, military or usurped power
       or confiscation, terrorist
     
       activities, nationalisation, government sanction, blockage,
       embargo, labour dispute,
     
       strike, lockout, robbery, theft of data or any interruption or
       any failure of electricity or
     
       server, system, computer, internet or telephone service or any
       other act beyond the
     control of Alphamoney.</p>
     <p><span className="contentofheading1">19. Information Gathered and Tracked</span></p>
     <p className="headingparagraph">
       By accessing this Platform, you hereby agree and understand that
       your internet
    
       protocol (<span className="contentofheading">&ldquo;IP&rdquo;)</span> address, device used to access the
       Platform and other demographics
    
       will be logged and recorded. If you choose to register and
       create a User profile on our
    
       Platform, we will collect and store such information (including
       personal information)
    
       as specified above. All information collected from you,
       including information
    
       submitted for the registration of a User profile, is subject to
       our Privacy Policy. We
    
       may collect further information from you if you wish to receive
       certain services
    
       available on our Platform, which information will be requested
       for at the time you
    
       choose to avail of such services. We guarantee that personal
       information about Users
    
       will not be provided to any third party without User consent.
     </p>
     <p><span className="contentofheading1">20. Waiver and Severability</span></p>
     <p className="headingparagraph">
       The failure of Alphamoney to exercise or enforce any right or
       provision of this
    
       Agreement shall not constitute a waiver of such right or
       provision. If any provision of
    
       this Agreement is found by a court of competent jurisdiction to
       be invalid, the parties
    
       nevertheless agree that the court should endeavour to give
       effect to the parties&rsquo;
    
       intentions as reflected in the provision, and the other
       provisions of this Agreement
    remain in full force and effect.</p>
     <p><span className="contentofheading1">21. Amendment</span></p>
     <p className="headingparagraph">
       Alphamoney reserves the right to make changes or alter the
       contents of Platform or
    
       services provided by Alphamoney at its sole discretion, without
       any prior notice.
     </p>
     <p><span className="contentofheading1">22. Assignment</span></p>
     <p className="headingparagraph">
       You shall not assign your rights and obligations under these
       Terms of Use without the
    
       prior written consent of Alphamoney. Alphamoney may assign its
       rights and
    
       obligations to a third party upon issuing written notification
       via an announcement on
    the Platform.</p>
     <p><span className="contentofheading1">23. Infringement Notification</span></p>
     <p className="headingparagraph">
     <span className="contentofheading">23.1 </span>If you believe in good faith that any content or material
       posted or otherwise made
     
       accessible on this Platform, violates these Terms of Use, or is
       prohibited or restricted
     
       by applicable laws, rules and regulations, or violates your
       proprietary rights, or is
     
       abusive or threatens and harms other users of this Platform,
       please compile the
    
       following information and email it to:{" "}
       <a className='footercontentlinks' data-fr-linked="true" href="mailto:support@alphamoney.in">
         support@alphamoney.in
       </a>
       ; and which email shall
     contain the following information:</p>
     <p>
     <span>a.</span> a clear identification of the allegedly
       prohibited/unlawful/abusive material on the
    Platform (with specific URL reference);</p>
     <p>
     <span>b.</span> your contact details: name, address, e-mail address and phone
       number;
     </p>
     <p>
     <span>c.</span> in case of an alleged violation of proprietary rights
       (including copyright, trade-
    
       mark or privacy rights), a statement that you believe, in good
       faith, that use of
    
       proprietary material allegedly infringed on Platform is not
       authorized by you or
     your agent or law;</p>
     <p>
     <span>d.</span> a statement that you believe, in good faith, that such
       material is
     prohibited/unlawful/abusive; and</p>
     <p>
     <span>e.</span> a statement that the information provided in the notice is
       accurate, and under
   
       penalty of perjury and that the signatory is authorized to act
       on your behalf.
     </p>
     <p>
     <span>23.2 </span>The email notification provided by you under this clause
       shall be executed by your

       electronic signature or the electronic signature of the person
       directly affected by the
       violation of these Terms of Use by the content.</p>
     <p>
     <span>23.3</span> In such an event, Alphamoney may contact you for any
       further information and
    
       reserves the right to determine, at its sole discretion, whether
       such content violates
    
       these Terms of Use or is otherwise abusive/prohibited/unlawful,
       and may take such
    
       action it deems appropriate. If you resort to any mala fide use
       of the notification
    
       process set out above, Alphamoney reserves the right to restrict
       your access to the
    Platform.</p>
     <p><span className="contentofheading1">24. Grievance Redressal</span></p>
     <p className="headingparagraph">
       If User has any grievances related to the Terms of Use, User can
       register a complaint
     
       with the grievance redressal officer at{" "}
       <a className='footercontentlinks' data-fr-linked="true" href="mailto:support@alphamoney.in">
         support@alphamoney.in
       </a>
       . In order to understand
     </p>
     <p>
       about the grievance redressal mechanism of Alphamoney on the
       Platform, User can
    
       access grievance redressal policy at {" "}
       <a className='footercontentlinks' data-fr-linked="true" href="mailto:support@alphamoney.in">
        [https://alphamoney.in/grievance_redressal_policy].

       </a>
     </p>
     <p><span className="contentofheading1">25. Report Abuse</span></p>
     <p className="headingparagraph">
       Please report any abuse or violations of these Terms of Use to
       us by emailing at{" "}
       <a className='footercontentlinks' data-fr-linked="true" href="mailto:support@alphamoney.in">
         support@alphamoney.in
       </a>
     </p>
     <br />
     <br />
     <br />
       </div>
      </div>
     <br />
     <br />
     <br />
     <br />
        </div>
    );
}

export default TermsOfUseMobile;