/* eslint-disable import/namespace */
/* eslint-disable no-unused-vars */
import * as contactServices from '../../services/contactService'
import * as actionTypes from './actionsTypes'

// actions for submit enquiry form
const postEnquiryRequest = () => {
  return {
    type: actionTypes.CONTACT_ENQUIRY_REQUEST,
  }
}

const postEnquirySuccess = (data) => {
  return {
    type: actionTypes.CONTACT_ENQUIRY_SUCCESS,
    payload: data,
  }
}

const postEnquiryFailure = (error) => {
  return {
    type: actionTypes.CONTACT_ENQUIRY_FAILURE,
    payload: error,
  }
}

//postEnquiry action
export const postEnquiryAction = (payload) =>
  async(dispatch) => {
    dispatch(postEnquiryRequest())
    try {
      const responseData = await contactServices.postEnquiry(payload)
      if (responseData?.status === 200) {
        dispatch(postEnquirySuccess(responseData?.data))
      } else {
        dispatch(postEnquiryFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(postEnquiryFailure(error?.response?.data))
    }
  }