import React, { useState } from "react";

const FaqI = () => {
  const [activeTab, setActiveTab] = useState("");

  const openFAQ = (faqName) => {
    setActiveTab(faqName === activeTab ? null : faqName);
  };

  return (
    <div className="container">
      <div className="tab">
        <div className="faqOuterBox">
          <button className={`tablinks ${activeTab === "Faq-1" ? "active" : ""}`} onClick={() => openFAQ("Faq-1")}>
            Who can invest in loans at ALPHAMONEY ?
          </button>
          <div id="Faq-1" className={`tabcontent ${activeTab === "Faq-1" ? "active" : ""}`}>
            <p className="faqcontent">
              Any individual with a valid bank account in India and PAN card can lend on Alpha Money. Investment opportunities for
              institutional investors are under development and will be released soon.
            </p>
          </div>
        </div>
        <div className="faqOuterBox">
          <button className={`tablinks ${activeTab === "Faq-2" ? "active" : ""}`} onClick={() => openFAQ("Faq-2")}>
            How am I helping others achieve financial success? How will this benefit me?
          </button>
          <div id="Faq-2" className={`tabcontent ${activeTab === "Faq-2" ? "active" : ""}`}>
            <p className="faqcontent">
              Alpha Money provides a unique opportunity to help borrowers get loans at a competitive rate of interest and in the process help investors earn a higher interest rate than the money kept idle in a savings account or deposits with banks. The process is mutually beneficial and is a win-win situation for both parties. However, please read the Terms and Conditions to understand the risk involved.
            </p>
          </div>
        </div>
        <div className="faqOuterBox">
          <button className={`tablinks ${activeTab === "Faq-3" ? "active" : ""}`} onClick={() => openFAQ("Faq-3")}>
            What are the investment opportunities at ALPHAMONEY?
          </button>
          <div id="Faq-3" className={`tabcontent ${activeTab === "Faq-3" ? "active" : ""}`}>
            <p className="faqcontent">
              <b>Bullet Loans: </b>
              Very short-term small ticket-size investment opportunities (INR 10,000 to INR 50,000) to salaried individuals for a tenure of 20 to 60 days with very high returns. The returns will be accrued and paid in the form of a one-time payment at the end of the tenure.
            </p>
            <p className="faqcontent">
              <b>Personal Loans: </b>
              Short-term investment opportunities of INR 30,000 to INR 1,00,000 to salaried individuals for a tenure of up to 12 months. The returns are competitive as per industry standards and will be paid in the form of monthly payouts that include both principal and interest components.
            </p>
          </div>
        </div>
        <div className="faqOuterBox">
          <button className={`tablinks ${activeTab === "Faq-4" ? "active" : ""}`} onClick={() => openFAQ("Faq-4")}>
            How do I become an investor on ALPHAMONEY ?
          </button>
          <div id="Faq-4" className={`tabcontent ${activeTab === "Faq-4" ? "active" : ""}`}>
            <p className="faqcontent">
              The process is simple. Please click on Sign Up and fill in some basic details about yourself. Our support team will verify your details and get in touch with you within 24 hours. Once your registration is verified and approved, you can log on to your Alpha Money account and start lending.
            </p>
          </div>
        </div>
        <div className="faqOuterBox">
          <button className={`tablinks ${activeTab === "Faq-5" ? "active" : ""}`} onClick={() => openFAQ("Faq-5")}>
            Why does Alpha Money need to verify my details?
          </button>
          <div id="Faq-5" className={`tabcontent ${activeTab === "Faq-5" ? "active" : ""}`}>
            <p className="faqcontent">
              Alpha Money conducts a verification process of the data and documents provided by investors. In addition, we appraise each request to ensure that the concerned investor can afford to give out loans within the boundaries of the statutory laws of India. We also make sure that investors do not use our platform to fund any prohibited actions or purposes. We may also use the details to ensure that the platform is not used as a tool for money laundering.
            </p>
          </div>
        </div>
        <div className="faqOuterBox">
          <button className={`tablinks ${activeTab === "Faq-6" ? "active" : ""}`} onClick={() => openFAQ("Faq-6")}>
            Can I choose the date to get my payouts?
          </button>
          <div id="Faq-6" className={`tabcontent ${activeTab === "Faq-6" ? "active" : ""}`}>
            <p className="faqcontent">
              Repayment dates are fixed by the platform and are generally the 3rd of a month for Personal Loans. THe borrower gets to choose the tenure for very short term Bullet Loans.
            </p>
          </div>
        </div>
        <div className="faqOuterBox">
          <button className={`tablinks ${activeTab === "Faq-7" ? "active" : ""}`} onClick={() => openFAQ("Faq-7")}>
            What kind of returns can I expect?
          </button>
          <div id="Faq-7" className={`tabcontent ${activeTab === "Faq-7" ? "active" : ""}`}>
            <p className="faqcontent">
              The borrowers are assessed based on their creditworthiness and multiple other parameters. The risk and return matrix for each investment opportunity gets highlighted. The investor gets to choose between the available opportunities. The returns are directly related to the risk matrix of the individual.
            </p>
          </div>
        </div>
        <div className="faqOuterBox">
          <button className={`tablinks ${activeTab === "Faq-8" ? "active" : ""}`} onClick={() => openFAQ("Faq-8")}>
            What is the maximum amount I can invest in one borrower?
          </button>
          <div id="Faq-8" className={`tabcontent ${activeTab === "Faq-8" ? "active" : ""}`}>
            <p className="faqcontent">
              According to the RBI guidelines, an individual investor can invest up to INR 50,000 in one borrower’s loan application.
            </p>
          </div>
        </div>
        <div className="faqOuterBox">
          <button className={`tablinks ${activeTab === "Faq-9" ? "active" : ""}`} onClick={() => openFAQ("Faq-9")}>
            How safe is my money?
          </button>
          <div id="Faq-9" className={`tabcontent ${activeTab === "Faq-9" ? "active" : ""}`}>
            <p className="faqcontent">
            All borrowers listed on Alpha Money are curated through various data points. Infact, Alpha Money has a strict borrower selection criterion.
            Additionally, Alpha Money also has a soft recovery and collections process to ensure the safety of the monies invested through the platform. Please read and understand <a href="/terms-of-use">Terms and Conditions</a>  before investing. <a href="/contactus">Contact Us</a> for any clarifications.
            </p>
          </div>
        </div>
        <div className="faqOuterBox">
          <button className={`tablinks ${activeTab === "Faq-10" ? "active" : ""}`} onClick={() => openFAQ("Faq-10")}>
            How are repayments calculated?
          </button>
          <div id="Faq-10" className={`tabcontent ${activeTab === "Faq-10" ? "active" : ""}`}>
            <p className="faqcontent">
            In personal loans, repayments are equated monthly instalments (EMIs) calculated using the amortisation method. Your instalment per month will remain the same throughout the tenure of your loan.<br/>
            In case of a bullet loan, there is a one time repayment at the end of the tenure. The interest rates of both the loans are calculated based on the creditworthiness of a borrower.
            </p>
          </div>
        </div>
        <div className="faqOuterBox">
          <button className={`tablinks ${activeTab === "Faq-11" ? "active" : ""}`} onClick={() => openFAQ("Faq-11")}>
            Do I have to put money upfront into my ALPHAMONEY account?
          </button>
          <div id="Faq-11" className={`tabcontent ${activeTab === "Faq-11" ? "active" : ""}`}>
            <p className="faqcontent">
             Yes. To ensure secure, fast and smooth transfer of funds with every transaction on the platform, Alpha Money has Investor’s Escrow account with Yes Bank under the trusteeship of IDBI Bank. You need to transfer the amount you wish to invest through Alpha Money into your wallet which is linked to this escrow account.
            </p>
          </div>
        </div>
        <div className="faqOuterBox">
          <button className={`tablinks ${activeTab === "Faq-12" ? "active" : ""}`} onClick={() => openFAQ("Faq-12")}>
          How can I fund or recharge my wallet?
          </button>
          <div id="Faq-12" className={`tabcontent ${activeTab === "Faq-12" ? "active" : ""}`}>
            <p className="faqcontent">
            As of now, you can fund or recharge your wallet using the
                  payment methods:
            </p>
            <ul className="" style={{color:"#757575",marginTop:"9px"}}>
              <li><b>NEFT</b></li>
              <li><b>RTGS</b></li>
              <li><b>IMPS</b></li>
            </ul>
          </div>
        </div>
        <div className="faqOuterBox">
          <button className={`tablinks ${activeTab === "Faq-13" ? "active" : ""}`} onClick={() => openFAQ("Faq-13")}>
          Is my interest income through ALPHAMONEY taxable?
          </button>
          <div id="Faq-13" className={`tabcontent ${activeTab === "Faq-13" ? "active" : ""}`}>
            <p className="faqcontent">
            All repayments you receive from Borrowers on Alpha Money will be without any tax deduction. We will provide an annual income statement to you to download from your dashboard. Earnings will be taxable as per the income tax rules that apply to you. Paying the applicable tax on income earned is your responsibility and Alpha Money is not liable for any default on the same.
            </p>
          </div>
        </div>
        <div className="faqOuterBox">
          <button className={`tablinks ${activeTab === "Faq-14" ? "active" : ""}`} onClick={() => openFAQ("Faq-14")}>
          How long does it take to find Borrowers?
          </button>
          <div id="Faq-14" className={`tabcontent ${activeTab === "Faq-14" ? "active" : ""}`}>
            <p className="faqcontent">
            You may find active borrowers on the Alpha Money platform at all times. All the investment opportunities stay live for 3 days and you may choose to invest in any opportunity based on your risk appetite. The investor gets to choose the borrower based on the risk and return matrix.
            </p>
          </div>
        </div>
        <div className="faqOuterBox">
          <button className={`tablinks ${activeTab === "Faq-15" ? "active" : ""}`} onClick={() => openFAQ("Faq-15")}>
            Is Alpha Money.com regulated by the RBI?
          </button>
          <div id="Faq-15" className={`tabcontent ${activeTab === "Faq-15" ? "active" : ""}`}>
            <p className="faqcontent">
            Yes, Alpha Money (Creditech Solutions Pvt Ltd) in one of India’s leading P2P lending platform registered with Reserve Bank of India (RBI) as NBFC-P2P.
            </p>
          </div>
        </div>
        <div className="faqOuterBox">
          <button className={`tablinks ${activeTab === "Faq-16" ? "active" : ""}`} onClick={() => openFAQ("Faq-16")}>
            What is the maximum investment limit for an investor as per regulations?
          </button>
          <div id="Faq-16" className={`tabcontent ${activeTab === "Faq-16" ? "active" : ""}`}>
            <p className="faqcontent">
            As per regulatory guidelines, the aggregate exposure of a investor to all borrowers at any point of time, across all P2P platforms, shall be subject to a cap of ₹ 50,00,000/- provided that such investments of the investors on P2P platforms are consistent with their net-worth.
            </p>
          </div>
        </div>
        <div className="faqOuterBox">
          <button className={`tablinks ${activeTab === "Faq-17" ? "active" : ""}`} onClick={() => openFAQ("Faq-17")}>
            Can I withdraw my proposal to fund a borrower’s loan?
          </button>
          <div id="Faq-17" className={`tabcontent ${activeTab === "Faq-17" ? "active" : ""}`}>
            <p className="faqcontent">
            Once the money has been pledged by you, the money will be locked for the duration for which the application is live. The option to withdraw is not available to the investor once he pledges his money to any investment.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqI;