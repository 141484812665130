import React, { Component } from "react";
import FaqB from "./FAQB";
import FaqI from "./FAQI";
class FaqMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      faqDetails: 0,
      fontWeightShow: 400,
      fontWeightInvestor: 700
    };
  }
  componentDidMount() {}

  componentDidUpdate(prevProps, prevState, snapshot) {}

  gotoPage(page){
    if(page == 0) this.setState({faqDetails: page ,fontWeightInvestor: 700, fontWeightShow: 400})
    else this.setState({faqDetails: page, fontWeightInvestor: 400, fontWeightShow: 700})
  }
  render() {
    let {faqDetails, fontWeightShow, fontWeightInvestor}=this.state
    return (
      <div>
        <div className="bulletContainer32 mobileContent">
          <div className="container text-center">
            <h2 className="faq-heading">Frequently Asked Questions</h2>
          
            <button
              onClick={() => this.gotoPage(0)}
              className="btninvestorfaq"
              style={{fontWeight: fontWeightInvestor}}
            >
              Investor FAQs{" "}
            </button>
            <button
              onClick={() => this.gotoPage(1)}
              className="btnborrowerfaq"
              style={{fontWeight: fontWeightShow}}
            >
              Borrower FAQS{" "}
            </button>
          </div>
          {faqDetails===0 ?<FaqI/> :<FaqB/>}
        </div>
      </div>
    );
  }
}

export default FaqMobile;
