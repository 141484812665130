import React from "react";
import logo from "../../src/public/assest/img/alphamoney_logo.png";
import linkedn from "../../src/public/assest/img/linkedn.svg";
import { URL } from "../utility/Constant";

function Footer() {
    return (
       <React.Fragment>
	        <div className="container">
                <div className="footers">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="info_col">
                                <img src={logo} className="footer_logo" alt="Alphamoney" />
                                <p>Follow us!</p>
                                <div className="footer-grid mb-3">
                                    <a rel="noopener" href="https://instagram.com/alphamoney.hq?igshid=YmMyMTA2M2Y=">
                                        <img src={`${URL.BASE_URL}Home+Page/instagram.svg`} alt="instagram" />
                                    </a>
                                    <a rel="noopener" href="https://twitter.com/AlphaMoneyHQ">
                                        <img src={`${URL.BASE_URL}Home+Page/youtube.svg`} height="19" width={50} alt="twitter" />
                                    </a>
                                    <a rel="noopener" href="https://www.linkedin.com/company/alphamoneyhq/">
                                        <img src={`${URL.BASE_URL}Home+Page/linkedin.svg`} alt=" linkedin"  />
                                    </a>
                                    <a rel="noopener" href="https://youtube.com/channel/UCD2LxuGgXPVGQEsMhJkepSg">
                                        <img alt="youtube" src={linkedn} height="20"  width={50} />
                                    </a>
                                </div>
                                <p>Made With ♥️ In India</p>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="mobile_flex">
                                <div className="links_col">
                                    <h5>PRODUCT</h5>
                                    <ul className="links_list">
                                        <li>
                                            <a className="linksdatainfooter"  href="/investor" >Invest</a>
                                        </li>
                                        <li>
                                            <a className="linksdatainfooter"  href="/borrower" >Borrow</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="links_col mt-4">
                                    <h5>RESOURCES</h5>
                                    <ul className="links_list">
                                        <li>
                                            <a className="linksdatainfooter" href="/FAQ">FAQs</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-6">
                            <div className="links_col">
                                <h5>ABOUT</h5>
                                <ul className="links_list">
                                    <li>
                                        <a className="linksdatainfooter"  href="/about-us" >Company</a>
                                    </li>
                                    <li>
                                        <a className="linksdatainfooter"  href="/career" >Careers</a>
                                    </li>
                                    <li>
                                        <a className="linksdatainfooter"  href="/contactus" >Contact</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4 col-6">
                            <div className="links_col">
                                <h5>LEGAL</h5>
                                <ul className="links_list">
                                    <li>
                                        <a className="linksdatainfooter"  href="/credit-assement-policy" >Credit Assessment Policy</a>
                                    </li>
                                    <li>
                                        <a className="linksdatainfooter" href="/privacy-policy">Privacy Policy</a>
                                    </li>
                                    <li>
                                        <a className="linksdatainfooter" href="/grievance-redressal-policy">Grievance Redressal Policy</a>
                                    </li>
                                    <li>
                                        <a className="linksdatainfooter" href="/cancellation-policy">Cancellation policy</a>
                                    </li>
                                    <li>
                                        <a className="linksdatainfooter" href="/fair-practices-code">Fair Practices Code</a>
                                    </li>
                                    <li>
                                        <a className="linksdatainfooter" href="/terms-of-use">Terms of Use</a>
                                    </li>
                                    <li>
                                        <a className="linksdatainfooter" href="/sample-aggrements">Sample Agreements</a>
                                    </li>
                                    <li>
                                        <a className="linksdatainfooter" href="/recovery-collection-policy">Recovery And Collection Policy</a>
                                    </li>
                                    <li>
                                        <a className="linksdatainfooter" href="/business-model">Business Model</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="disclaimer">
                <div className="container">
                    <div className="disclaimer_content">
                        <p>Disclaimer : Reserve Bank of India does not accept any responsibility for the correctness of any of the statements or representations made or opinions expressed by Creditech Solutions India Pvt. Ltd, and does not provide any assurance for repayment of the loans lent on it. CreditTech Solutions Pvt Ltd (alphamoney.in) is having a valid certificate of registration dated December 30th 2021 issued by the Reserve Bank of India under Section 45 IA of the Reserve Bank of India Act, 1934. However, the RBI does not accept any responsibility or guarantee about the present position as to the financial soundness of the company or for the correctness of any of the statements or representations made or the opinions expressed by the company and for repayment of deposits / discharge of liabilities by the company.</p>
                        <p>The information contained herein is only to enable the Lender to make a considered decision. Any decision taken by the Lender on the basis of this information is the sole responsibility of the Lender and ALPHAMONEY is not liable. This information does not include any sensitive personal data or information of the Borrower. ALPHAMONEY only facilitates a virtual meeting place between the Borrowers and the Lenders on its online platform. The decision to lend is entirely at the discretion of the Lender and ALPHAMONEY does not guarantee that the Borrowers will receive any loans from the Lenders. ALPHAMONEY merely aids and assist the Lenders and the Borrowers listed on its website to make and receive loans and charges a service fee from the Lenders and the Borrowers for such assistance. ALPHAMONEY is only an ‘Intermediary’ under the provisions of the Information Technology Act, 1999.</p>
                    </div>
                </div>
            </div>
       </React.Fragment>
    )
}

export default Footer;