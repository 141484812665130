import React, { useState } from "react";
import ".././public/assest/css/invest.css";
import { useEffect } from 'react';
import { URL } from "../utility/Constant";

function Career() {
	const goTopath = (path) => {
		window.open(path, '_target');
	}
	  useEffect(() => {
    document.body.classList.add('career')
    return () => {
      document.body.classList.remove('career')
    }
  }, []);

  const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
  return (
  <>
	{/* second section  */}
	<div className="sliderBg">
		<div className="sliderBgImgRight">
			<div className="container">    	 
				<div className="sliderInfoBox">  
					<h2 className="career_heading"> <strong> Join  </strong> our Team</h2>
					<p>Help us re-shape the financial futures of India’s next generation</p>
					<button onClick={() => goTopath('https://www.linkedin.com/company/alphamoneyhq/')} className="btn_brown_outline">Explore Openings</button>
					<div className="team_image">
						<img className="img-fluid" src={`${URL.BASE_URL}Career/alphateam.png`} alt="Man images" />
					</div>
				</div>
			</div>  
		</div> 
	</div> 
	{/* second section  */}
	  
	{/* third section  */}
	<section className="common_space_small">
		<div className="container">    	 
			<div className="priority_growth">
				<h2> <strong> Prioritising </strong> Your Growth </h2>
			</div>
			<div className="row growth_row">
				<div className="col-md-6">
					<div className="growth_card">
						<div className="growth_image">
							<img className="img-fluid" src={`${URL.BASE_URL}Career/bestIcon.png`} alt="p2p_landing" />
						</div>
						<h4>Best-In-Class Salary & Perks</h4>
					</div>
				</div>
				<div className="col-md-6">
					<div className="growth_card">
						<div className="growth_image">
							<img className="img-fluid" src={`${URL.BASE_URL}Career/rapid.png`} alt="p2p_landing" />
						</div>
						<h4>Rapid Career Growth</h4>
					</div>
				</div>
				<div className="col-md-6">
					<div className="growth_card">
						<div className="growth_image">
							<img className="img-fluid" src={`${URL.BASE_URL}Career/learning.png`} alt="p2p_landing" />
						</div>
						<h4>Learning & Development</h4>
					</div>
				</div>
				<div className="col-md-6">
					<div className="growth_card">
						<div className="growth_image">
							<img className="img-fluid" src={`${URL.BASE_URL}Career/salary.png`} alt="p2p_landing" />
						</div>
						<h4>Curatable Salary Components</h4>
					</div>
				</div>
				<div className="col-md-6">
					<div className="growth_card">
						<div className="growth_image">
							<img className="img-fluid" src={`${URL.BASE_URL}Career/openWork.png`} alt="p2p_landing" />
						</div>
						<h4>Open Work Culture</h4>
					</div>
				</div>
				<div className="col-md-6">
					<div className="growth_card">
						<div className="growth_image">
							<img className="img-fluid" src={`${URL.BASE_URL}Career/leave.png`} alt="p2p_landing" />
						</div>
						<h4>Paid Maternity Leave</h4>
					</div>
				</div>
			</div>
		</div>
	</section>
	{/* third section  */}
	<section className="common_space">
	<div className="container">
		<div className="advantage_heading">
			<h2> The <span style={{color: '#E60000', fontWeight: '600'}}>ALPHA</span><span style={{color: '#000000', fontWeight: '600'}}>MONEY</span> <span>Advantage</span> </h2>
		</div>
		<div className="row advantage_row">
			<div className="col-md-6 col-lg-6">
				<div className="advantage_card card_green">
					<h4>Craft your dream role</h4>
					<p>At ALPHAMONEY you can choose a title of your liking in accordance with your band.</p>
				</div>
			</div>
			<div className="col-md-6 col-lg-6">
				<div className="advantage_card card_green">
					<h4>International training</h4>
					<p>Meritorious candidates will get an opportunity to train offshore.</p>
				</div>
			</div>
			<div className="col-md-6 col-lg-6">
				<div className="advantage_card card_red">
					<h4>Rewards and Recognition</h4>
					<p>We always look to reward those who stand out among their peers and demonstrate dedication.</p>
				</div>
			</div>
			<div className="col-md-6 col-lg-6">
				<div className="advantage_card card_red">
					<h4>Encouraging Innovation</h4>
					<p>Since our product is disruptive, so should be our thinking hence, we always prioritise innovation.</p>
				</div>
			</div>
		</div>
	</div> 
	</section>
	{/* third section  */}
	  
	{/* forth section  */}
	<section className="culture_section">
		<div className="culturebg">
			<div className="container">
				<div className="culture_heading">
					<h2 className=""> Our  <strong> Culture </strong> </h2>
				</div>
				<div className="row culture_row">
					<div className="col-md-6">
						<div className="culture_card">
							<h5>Freedom & Responsibility: </h5>
							<p>We place people over process. Our culture helps us attract and retain stunning colleagues, making work here more satisfying.</p>
						</div>
					</div>
					<div className="col-md-6">
						<div className="culture_card">
							<h5>Sustainability:</h5>
							<p>We’re not just shaping the future of banking. We want to help shape a better future for society and the planet too. Bit by bit. Day by day.</p>
						</div>
					</div>
					<div className="col-md-6">
						<div className="culture_card">
							<h5>Embracing Differences:</h5>
							<p>No two consumers are the same, so neither are we. We listen to our employees & implement their suggestions at an organisational level.</p>
						</div>
					</div>
					<div className="col-md-6">
						<div className="culture_card">
							<h5>Lifestyle:</h5>
							<p>There’s life at work and life outside of work. We want everyone to be healthy, travel often, get time to give back, and have the resources and support they need.</p>
						</div>
					</div>
				</div>
			</div>
		</div>  
	</section>
	{/* forth section  */}
	   
	{/* fifth section  */}
	<section className="common_space">
		<div className="container testimonials">
			<div className="testimonials_heading">
				<h2>Life <span>@ALPHAMONEY</span> </h2>
			</div>
			<div className="row testimonial_row">
				<div className="col-md-6 col-lg-4">
					<div className="testimonial_card test_card_green">
						<img src={`${URL.BASE_URL}Career/sandy.svg`} alt="p2p_landing" className="author_image" />
						<p>Life at AlphaMoney is very dynamic, fast-paced, and filled with opportunities for growth and innovation. Having a strong mission of improving financial inclusion, streamlining processes, or enhancing financial security, it provides a lot of learning opportunities on day to day basis.</p>
						<div className="designation">
							<h4>- Sandeep, Senior Product Manager</h4>
							<a href="https://www.linkedin.com/in/sandeep-soni-22937290/" target="_blank">
								<img src={`${URL.BASE_URL}Career/linkedin.svg`} alt="linkedin" /></a>
						</div>
					</div>
				</div>
				<div className="col-md-6 col-lg-4">
					<div className="testimonial_card test_card_green">
						<img src={`${URL.BASE_URL}Career/kalpana.svg`} alt="p2p_landing" className="author_image" />
						<p>Fintech is an exciting and rapidly evolving field and I have had the pleasure of gaining extensive Quality Assurance experience in AlphaMoney. My career has been dedicated to ensuring the reliability and security of financial software and systems. It allowed me to thrive in this dynamic and highly regulated sector.</p>
						<div className="designation">
							<h4>- Kalpana, Senior Quality Assurance Eng.</h4>
							<a href="https://www.linkedin.com/in/kalpana-bisht-0027b1231/" target="_blank">
								<img src={`${URL.BASE_URL}Career/linkedin.svg`} alt="linkedin" /></a>
						</div>
					</div>
				</div>
				<div className="col-md-6 col-lg-4">
					<div className="testimonial_card test_card_green">
						<img src={`${URL.BASE_URL}Career/mandeep.svg`} alt="p2p_landing" className="author_image" />
						<p>ALPHAMONEY gave me wonderful work partners and ambitious projects to work upon. I can't wait to unravel all that lies ahead of me."ALPHAMONEY's vision aligns perfectly with my own career aspirations. I'm excited to see how we'll revolutionize the industry and look forward to the journey ahead.</p>
						<div className="designation">
							<h4>- Mandeep, Tech Lead</h4>
							<a href="https://www.linkedin.com/in/mandeepkohli1996/" target="_blank">
								<img src={`${URL.BASE_URL}Career/linkedin.svg`} alt="linkedin" /></a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	{/* fifth section  */}
	   
    {/* sixth section  */}
	<section className="common_space">
		<div className="lookingFullBGColor">
	      <div className="container">
		    <h2> We’re Looking For All Kinds Of People.</h2>
		    <p>
			ALPHAMONEY is an equal opportunity employer and makes employment decisions on the basis of merit. 
			We want to have the best qualified people in every job. Our policy prohibits discrimination based on race, 
			colour, religious creed, sex, sexual orientation, gender, age, or any other consideration made unlawful by 
			applicable national, state, or local laws.</p>
		   
		   <button onClick={() => goTopath('https://www.linkedin.com/company/alphamoneyhq/')} className="btn_white mt-3">Explore Openings</button>
	      </div>
	  	</div>
	</section>
      
    {/* sixth section  */}
  
  </>);
}

export default Career;
