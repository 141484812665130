import React, { Component } from "react";
import FaqB from "./FAQB";
import FaqI from "./FAQI";
class Faq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      faqDetails: 0,
      fontWeightShow: 400,
      fontWeightInvestor: 600
    };
  }
  componentDidMount() {}

  componentDidUpdate(prevProps, prevState, snapshot) {}

  gotoPage(page){
    if(page == 0) this.setState({faqDetails: page ,fontWeightInvestor: 600, fontWeightShow: 400})
    else this.setState({faqDetails: page, fontWeightInvestor: 400, fontWeightShow: 600})
  }
  render() {
    let {faqDetails, fontWeightShow, fontWeightInvestor}=this.state
    return (
      <div className="faqs_container">
          <div className="container">
            <h2 className="faq-heading">Frequently Asked Questions</h2>
            <div className="faqs_actions">
              <button
                onClick={() => this.gotoPage(0)}
                className={`btn_brown_outline ${faqDetails === 0 ? 'active' : ''}`}
                // className="btn_brown_outline"
                // style={{fontWeight: fontWeightInvestor}}
              >
                Investor FAQs{" "}
              </button>
              <button
                onClick={() => this.gotoPage(1)}
                className={`btn_green_outline ${faqDetails === 1 ? 'active' : ''}`}
                // className="btn_green_outline"
                // style={{fontWeight: fontWeightShow}}
              >
                Borrower FAQS{" "}
              </button>
            </div>
          </div>
          {faqDetails===0 ?<FaqI/> :<FaqB/>}
        </div>
    );
  }
}

export default Faq;
