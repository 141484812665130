import React from "react";
import { useEffect } from 'react';
import ".././public/assest/css/invest.css";
import logo from "../public/assest/img/Logo Main.svg";
import desktopWithMobile from '../public/assest/img/desktopWithMobile.png'

import MobilehalfImg from '../public/assest/img/mobilehalfImg.png'

function AboutUsMobile() {
  const goTopath = (path) => {
		window.open(path, '_self');
	}
	useEffect(() => {
    document.head.innerHTML+=`
    <meta name='description' content='ALPHAMONEY is a P2P Lending NBFC reshaping banking....'/>
    <meta name='keywords' content='
          AlphamoneyAboutUs 
    '/>
    `
  }, []);
  useEffect(() => {
    document.body.classList.add('aboutus')
	setTimeout(() => {
    const box = document.getElementById("root");
     box.style.display = "block";
   }, 500);
   
   setTimeout(() => {
    const box = document.getElementById("show-3");
     box.style.display = "block";
   }, 500);
   return () => {
    document.body.classList.remove('aboutus')
  }
  }, []);
  

  
  
  
  
  return (
    <div className="w-100">
      {/* second section  */}
      <div className=" boxSadhow_about">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 p-5">
              <p className="second_section_heading_about">
                <b> About </b> Us{" "}
              </p>
              <p className="second_section_heading_mute">
                We’re reshaping financial Futures{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* second section  */}
      {/* third section  */}
      <div className="third_section_about">
        <div className="container">
		<div className="row">
            <div className="col-lg-3 mainLogo">
            <img className="img-fluid" alt="p2p_landing" src={logo} />
          </div>
          <div className="col-lg-9 third_section_about_text">
            <p className="third_section_about_heading">
            ALPHAMONEY is a P2P Lending NBFC reshaping banking and financial services for the next generation. We are propelling financial growth across India - Enabling new-age consumers to break the glass ceiling. An RBI registered (CoR 14.03566) fintech, we are pioneering in providing financial solutions for the next generation of borrowers and investors across the credit spectrum. 
            </p>
         
          </div>
          </div>
        </div>
      </div>
      {/* third section  */}
      {/* forth section  */}
      <div className="container">
        <div className="row">
          <div className="col-lg-6 pt-4 third_section_about_text">
            <h1 className="">Building <span> Extraordinary </span> <br/> Products</h1>
            <div className="halfwidthFortext">
		   <p className="about-section-4" >
                ALPHAMONEY is a product-first company that is revolutionising financial services. We're tackling challenges in banking, lending, investing, payments and financial growth—all while maintaining a beautiful experience. 
            </p>
            <p className="about-section-4">
                Our products will cater to several major segments that include healthcare, education, agriculture & real estate - to name a few. We're building technologies that bring people together in the real world which can be both daunting and inspiring!
            </p>
			</div>
			<div className="abouthalfMobilewith">
			   <img className="img-fluid" alt="p2p_landing" src={MobilehalfImg} />
			</div>
          </div>
          <div className="col-lg-6 pr-0 mainLogo">
            <div id="main_section_img" className='main_section_img'>
                <img className="img-fluid" alt="p2p_landing" src={desktopWithMobile} />
            </div>
          </div>
        </div>
      </div>
      {/* forth section  */}
      {/* fifth section  */}
    <div className='fifth_section_about'>
        <div className='container'>
        <div className='row'>
            <div className='col-12' >
                <h2 className='textHeading-1'> The <strong> Values </strong> that Define Us </h2>
                <div className="main_section_about">
                <div className='row'>
                  <div className='col-sm-6 customeDefine'>
                    <strong className='second_section_sub_heading' style={{color: '#5B1212'}}>PROFESSIONALISM</strong>
                    <p> At every step of the journey, we make sure that we conduct ourselves in a professional manner. Be it in the dealings with our partners or our relationship with our customers one can always expect the highest level of professionalism from us. </p>
                  </div>
                  <div className='col-sm-6 customeDefine2'>
                    <strong className='second_section_sub_heading' style={{color: '#5B1212'}}>SUSTAINABILITY</strong>
                    <p> It is our endeavour to transform the communities we serve into better habitats. We strive for balance between people, environment and business; keeping sustainability at the heart of all our projects</p>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-6 customeDefine'>
                    <strong className='second_section_sub_heading' style={{color: '#5B1212'}}>INTEGRITY</strong>
                    <p> As an organisation we have always stood for honest and courteous dealings. We regard integrity as a core value and demand it of ourselves and of those we work with, sincerely and consistently. </p>
                  </div>
                  <div className='col-sm-6 customeDefine2'>
                    <strong className='second_section_sub_heading' style={{color: '#5B1212'}}>RESPECT FOR PEOPLE</strong>
                    <p> Respect is the bedrock of all our relationships. We value each of our customers, shareholders, partners and employees for the role they play in helping us achieve our vision. And we work relentlessly to strengthen this bond of mutual respect.</p>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-6 customeDefine'>
                    <strong className='second_section_sub_heading' style={{color: '#5B1212'}}>MARKET DRIVEN & INNOVATIVE</strong>
                    <p> We monitor consumer and market trends to identify key challenges and strive to meet them continuously by virtue of our research tools, passion, innovation, creativity and entrepreneurship. </p>
                  </div>
                  <div className='col-sm-6 customeDefine2'>
                    <strong className='second_section_sub_heading' style={{color: '#5B1212'}}>TRANSPARENCY</strong>
                    <p> We thrive on a culture of ethics and uncompromising morals. At no point are we unclear or vague in the way we work, making it our priority to keep all concerned up to date with our plans and vision for the future.</p>
                  </div>
                </div>
                </div>
                
                
            </div>
        </div>
        </div>
    </div>
      {/* fifth section  */}
      {/* sixth section  */}
      <div className='sixth_section'>
        <div className='container mt-4 mb-4'>
        <div className='row mt-4 mb-4 '>
            <div className='col-12 ml-5 mb-4 mt-4' >
                <p className='textHeading-1 mt-4'> <b style={{color: 'black'}}>Join Us! </b> </p>
                <div className="main_section_become_about" style={{color: '#757575'}}>
                    <p> If you are looking to work in a stimulating workplace on some of the biggest challenges in the banking and finance industry, we might have the perfect opportunity for you! </p>
                </div>
                <br/>
                <div className="mainBtn">
                    <span  className="button_about" onClick={() => goTopath('https://alphamoney.in/career')}>View Opportunities</span>
                </div>
            </div>
        </div>
        </div>
    </div>
      {/* sixth section  */}
    </div>
  );
}

export default AboutUsMobile;
